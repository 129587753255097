import * as React from "react";
import {ADJACENT_TO_ANCHOR_BOTTOM_START_ALIGNED_GAP_10PX} from "../../../../hooks/useOverlay";
import {constant} from "../../../../utils/functions";
import {Button} from "../../Button";
import {FilterViewProps, isFilterView} from "../../FilterView";
import {Icon} from "../../Icon";
import {Overlay} from "../../Overlay";

import "./styles.scss";

export type ThProps = React.HTMLProps<HTMLTableHeaderCellElement> & {
    ordinal?: "first" | "last" | number;
};

export function ThDef({children, ordinal, ...props}: ThProps, ref: React.ForwardedRef<HTMLTableHeaderCellElement>) {

    const thRef = React.useRef<HTMLTableHeaderCellElement>();
    React.useImperativeHandle(ref, () => thRef.current, []);

    const filter = React.Children.map(children, (child) => {
        if (!isFilterView(child)) {
            return null;
        }
        return <ThFilter filter={child}/>;
    });

    const hasFilter = React.Children.count(filter) === 1;

    const notFilters = React.Children.map(children, (child) => {
        if (isFilterView(child)) {
            return null;
        }
        return child;
    });

    const ordinalClassName = ordinal === "first" || ordinal === 1 ? "table-header-cell-stick-left"
        : ordinal === "last" ? "table-header-cell-stick-right"
        : "";

    const filterClassName = hasFilter ? "with-filter" : "";

    if (hasFilter) {
        return (
            <th {...props} className={`table-header-cell ${ordinalClassName} ${filterClassName} ${props.className ?? ""}`}>
                <div className={"table-header-cell-body"}>
                    {notFilters}
                    {filter}
                </div>
            </th>
        );
    }

    return (
        <th
            {...props}
            className={`table-header-cell ${ordinalClassName} ${filterClassName} ${props.className ?? ""}`}
            ref={thRef}
        >
            {notFilters}
        </th>
    );
}


// todo remove

function ThFilter({filter}: Record<"filter", React.ReactElement<FilterViewProps>>) {
    const [open, setOpen] = React.useState(false);

    const clonedFilter = React.cloneElement(
        filter,
        {
            onChange(state) {
                filter.props.onChange?.(state);
                setOpen(false);
            },
        },
    );

    const isFilterSet = clonedFilter.props.value.selected.length > 0;

    const iconClassName = isFilterSet ? "bottom-green-dot" : "";

    return (
        <>
            <Button variant={"tertiary"} onClick={constant(setOpen, !open)}>
                <Icon name={"filter_list"} className={iconClassName}/>
            </Button>
            {open ? (
                <Overlay
                    asTag={"menu"}
                    tabIndex={-1}
                    positioned={ADJACENT_TO_ANCHOR_BOTTOM_START_ALIGNED_GAP_10PX}
                    className={"filter-overlay"}
                >
                    {clonedFilter}
                </Overlay>
            ) : null}
        </>
    );
}

export const Th = React.forwardRef(ThDef);
