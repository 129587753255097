import * as React from "react";
import * as ReactDOM from "react-dom";
import {
    HashRouter, Outlet,
    Route,
    Routes,
    useLocation,
    useNavigate
} from "react-router-dom";
import "regenerator-runtime";
import * as LegacyPage from "./page";
import {CreateCompany} from "./presentation/createCompany/CreateCompany";
import {CreateComponentForm} from "./presentation/createComponentModel/CreateComponentForm";
import {CreateRawData} from "./presentation/createRawData/CreateRawData";
import {CreateSettingTemplate} from "./presentation/createTemplate/CreateSettingTemplate";
import DeviceStorageManagement from "./presentation/DeviceStorageManagement";
import {ManageComponentModels} from "./presentation/Components";
import Transfer from "./presentation/movements/Transfer";
import "./styles";
import "./task";
import "./weboperations";
import {CompaniesPageView, CompanyComponentTypeSideSheetView} from "./presentation/Companies";
import {NonLegacyLayout} from "./presentation/common/NonLegacyLayout";
import {QueryParamsProvider} from "./presentation/QueryParams";
import {DatastoreProvider} from "./DataLoader";


export const LegacyInterface = {
    push: (...d: any): any => undefined,
    onLoad: (...d: any): any => undefined,
};

export const App: React.FC = () => {

    LegacyInterface.push = useNavigate();

    return (
        <Routes>
            <Route path={"/"} element={<LegacyLoadablePage url={"home"}/>}/>
            {/* All routes that contain react */}
            <Route path={"/createRawData"} element={<CreateRawData/>}/>
            <Route path={"/createSettingTemplate"} element={<CreateSettingTemplate/>}/>
            <Route path={"/transfer"} element={<Transfer/>}/>
            <Route path={"/changeDeviceStorage"} element={<DeviceStorageManagement/>}/>
            <Route path={"/createComponentModel"} element={<CreateComponentForm/>}/>
            <Route path={"/componentModels"} element={
                <NonLegacyLayout>
                    <ManageComponentModels/>
                </NonLegacyLayout>
            }/>
            <Route path={"/companies"} element={
                <DatastoreProvider> {/*todo consider creating a Context Composer*/}
                    <NonLegacyLayout>
                        <QueryParamsProvider>
                            <CompaniesPageView/>
                            <Outlet/>
                        </QueryParamsProvider>
                    </NonLegacyLayout>
                </DatastoreProvider>
            }>
                <Route path={":companyGuid/componentTypes"} element={<CompanyComponentTypeSideSheetView/>}/>
                <Route path={":companyGuid/componentModels"}/>
                <Route path={":companyGuid/alarmTypes"}/>
                {/* todo <Route path={":companyGuid/integrationFlow"}/>*/}
            </Route>
            {/* All create company route*/}
            <Route path={"/createCompany"} element={<CreateCompany/>}/>
            {/* All legacy pages */}
            <Route path={"*"} element={<LegacyLoadablePage/>}/>
        </Routes>
    );
};

const LegacyLoadablePage: React.FC<{ url?: string }> = (props) => {

    const location = useLocation();
    const [content, setContent] = React.useState("");

    React.useEffect(() => {
        $.ajax({
            url: props.url || location.pathname,
            type: "GET",
            success: (data) => {
                setContent(data);
                if (typeof LegacyInterface.onLoad === "function") {
                    LegacyInterface.onLoad();
                }
            },
        });
    }, [location.pathname]);

    return (
        <div
            style={{width: "100%"}}
            dangerouslySetInnerHTML={{__html: content}}
        />
    );
};

export function setLegacyBreadcrumb(breadcrumb: LegacyPage.Breadcrumb) {
    if (breadcrumb) {
        const rendered: string = breadcrumb.render();
        if (rendered == null) {
            $(".breadcrumb").remove();
        } else {
            $(".breadcrumb").replaceWith(rendered);
        }
    }
}


ReactDOM.render(
    (
        <HashRouter>
            <App/>
        </HashRouter>
    ),
    document.querySelector("#pageContent"),
);
export {Overlay} from "./presentation/common/Overlay";
export {inside} from "./utils/arrays";
export {Datalist} from "./presentation/common/form/Datalist";
export {DatalistRef} from "./presentation/common/form/Datalist";
export {useInputValueAsFilter} from "./hooks/useInputValueAsFilter";