import * as React from "react";
import {getAlarmsType} from "../../../../../controllers/api/alarms";
import {
    addAlarmsTypesToComponentModel,
    removeAlarmsTypesFromComponentModel,
} from "../../api";
import {AlarmType} from "../../../../../models/alarm/alarmType";
import {consumeTargetValue} from "../../../../../utils/eventsHandling";
import {constant} from "../../../../../utils/functions";
import {similar} from "../../../../../utils/strings";
import {Button} from "../../../../common/Button";
import {Table, Td, Th, Tr} from "../../../../common/DataTable";
import {Dialog} from "../../../../common/Dialog";
import {Icon} from "../../../../common/Icon";
import {Skeleton} from "../../../../common/Skeleton";
import {useComponentModel} from "../contexts/ComponentModelProvider";
import {useData} from "../../../../../DataLoader";
import {useForm_legacy} from "../../../../../Form/hooks";
import {pickGuid} from "../../../../../utils/objects";

import "./styles.scss";
import {range} from "../../../../../utils/numbers";

interface ComponentModelAlarmsTypeModalViewProps {
    alarmsTypes: AlarmType[];
    onClose(update: boolean): void;
}

export function ComponentModelAlarmsTypeModalView(
    {
        alarmsTypes,
        onClose,
    }: ComponentModelAlarmsTypeModalViewProps,
) {
    const alarmsTypesLoader = useData({
        loader: getAlarmsType,
        keys: ["alarms-types"],
        map(data) {
            return data.list;
        }
    })

    const initialAlarmTypes = alarmsTypes.map(pickGuid);

    const { register, watch, handleSubmit } = useForm_legacy({defaultValues: {alarmsTypes: initialAlarmTypes}});

    const [alarmTypeNameFilter, setAlarmTypeNameFilter] = React.useState("");

    const componentModel = useComponentModel();

    const form = watch();

    const formId = `${componentModel.guid}-alarms-types-form`;

    const tableBody = alarmsTypesLoader.state === "loading"
        ? range(20).map((i) => <Skeleton key={i} type={"table-row"} size={5}/>)
        : alarmsTypesLoader.state === "successful" ? alarmsTypesLoader.data
            .filter((a) => similar(a.name, alarmTypeNameFilter))
            .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
            .map((at) => {
                const isBeingAdded = form.alarmsTypes.includes(at.guid) && !initialAlarmTypes.includes(at.guid);
                const isBeingRemoved = !isBeingAdded && !form.alarmsTypes.includes(at.guid)
                    && initialAlarmTypes.includes(at.guid);

                return (
                    <Tr key={at.guid} state={isBeingAdded ? "added" : isBeingRemoved ? "removed" : "default"}>
                        <Td>
                            <input type={"checkbox"} value={at.guid} {...register("alarmsTypes")} form={formId}/>
                        </Td>
                        <Td>{at.name}</Td>
                        <Td>{at.description}</Td>
                        <Td>{at.category.name}</Td>
                        <Td>{at.severity.name}</Td>
                    </Tr>
                );
            }) : [];


    const edited = form.alarmsTypes.length !== alarmsTypes.length
        || (form.alarmsTypes.findIndex((guid) => !initialAlarmTypes.includes(guid)) > -1)
        || (initialAlarmTypes.findIndex((guid) => !form.alarmsTypes.includes(guid)) > -1);

    const footerButtons = edited ? (
        <>
            <Button variant={"secondary"} onClick={constant(onClose, false)}> Cancel </Button>
            <Button variant={"primary"} form={formId}> Save </Button>
        </>
    ) : (
        <Button variant={"primary"} onClick={constant(onClose, false)}> Close </Button>
    );

    async function save(data: {alarmsTypes: string[]}) {
        const toAdd = data.alarmsTypes.filter((guid) => !initialAlarmTypes.includes(guid))
            .map((guid) => ({guid}));
        const toRemove = initialAlarmTypes.filter((guid) => !data.alarmsTypes.includes(guid))
            .map((guid) => ({guid}));

        try {
            await Promise.all([
                addAlarmsTypesToComponentModel(toAdd, componentModel),
                removeAlarmsTypesFromComponentModel(toRemove, componentModel)
            ]);
            onClose(true);
        } catch (e) {
            onClose(false);
        }
    }

    return (
        <Dialog className={"component-model-connected-entities-dialog component-model-alarm-types-dialog"}>
            <form id={formId} onSubmit={handleSubmit(save)}/>
            <header>
                <hgroup>
                    <small>{componentModel.name}</small>
                    <h3>Alarms types</h3>
                </hgroup>
                <label className={"input-field"}>
                    <input
                        type={"text"}
                        value={alarmTypeNameFilter}
                        placeholder={"Filter alarms type"}
                        onChange={consumeTargetValue(setAlarmTypeNameFilter)}
                    />
                    <Button variant={"tertiary"} onClick={constant(setAlarmTypeNameFilter, "")}>
                        <Icon name={"backspace"}/>
                    </Button>
                </label>
            </header>
            <section>
                <Table>
                    <thead>
                        <tr>
                            <Th/>
                            <Th>Name</Th>
                            <Th>Description</Th>
                            <Th>Category</Th>
                            <Th>Severity</Th>
                        </tr>
                    </thead>
                    <tbody>{tableBody}</tbody>
                </Table>
            </section>
            <footer>{footerButtons}</footer>
        </Dialog>
    );
}
