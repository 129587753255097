import * as React from "react";
import {ThWithFilterView, FilterColumnOrderView, FilterMultiValueSelectView} from "../filters";
import {useQueryParams} from "../../../../../QueryParams";
import {Company} from "../../../../../../models/company/companyModel";
import {findCompanies} from "../../../../api";
import {useForm} from "../../../../../../Form";
import {pickGuid} from "../../../../../../utils/objects";
import {LOAD_ITEM_COUNT} from "../common";
import {ParentCompaniesQueryParamsType} from "./types";
import {useDatastore} from "../../../../../../DataLoader";
import {PARENT_COMPANIES_DATA_KEY} from "./const";

type FilterForm = {
    valueName: string,
    specific: boolean,
    values: Company[],
}

export function CompanyParentColumnHeaderView() {
    const datastore = useDatastore();
    const { params, update } = useQueryParams<ParentCompaniesQueryParamsType>();

    const form = useForm<FilterForm>({
        defaultValues: async () => {
            const companiesGuids = Array.isArray(params.parent) ? params.parent
                : params.parent != null ? [params.parent]
                : [];

            if (companiesGuids.length > 0) {
                const parentCompanies = await findCompanies({
                    page: 0,
                    size: companiesGuids.length,
                    criteria: {guids: companiesGuids, isParentCompany: true},
                });

                datastore.set([PARENT_COMPANIES_DATA_KEY], parentCompanies);

                return {
                    valueName: "",
                    values: parentCompanies.list,
                    specific: true,
                };
            }

            return {
                valueName: params.parentName ?? "",
                specific: params.parentName == null,
                values: [],
            };
        },
    });

    function resetAll() {
        form.reset({
            valueName: "",
            specific: true,
            values: [],
        });
        update({
            page: 0,
            parentName: null,
            parent: [],
        });
    }

    function apply(data: FilterForm) {
        datastore.set([PARENT_COMPANIES_DATA_KEY],{list: data.values});
        update({
            page: 0,
            parentName: data.specific ? null : data.valueName,
            parent: data.specific ? data.values.map(pickGuid) : [],
        });
    }

    return (
        <ThWithFilterView heading={"Parent"} onApply={form.handleSubmit(apply)} onReset={resetAll}>
            <FilterColumnOrderView columnKey={"company.name"}/>
            <FilterMultiValueSelectView legend={"Parent company"} form={form} listLoader={loadParentCompanies}/>
        </ThWithFilterView>
    )
}

function loadParentCompanies(page: number, input: string) {
    return findCompanies({
        size: LOAD_ITEM_COUNT,
        page,
        sort: ["name,asc"],
        criteria: {
            usePartialName: true,
            name: input,
            isParentCompany: true,
        },
    });
}