import * as React from "react";
import {useData} from "../../../../../../DataLoader";
import {getCountries} from "../../../../../../controllers/api/support";
import {Td} from "../../../../../common/DataTable";
import {Skeleton} from "../../../../../common/Skeleton";

type CompanyCountryCellViewProps = {
    countryISO3: string;
};

export function CompanyCountryCellView({countryISO3}: CompanyCountryCellViewProps) {

    const country = useData({
        loader: getCountries,
        map({list}){
            return list.find((c) => c.alpha3Code === countryISO3);
        },
        keys: ["countries"],
    });

    switch (country.state) {
        case "loading": {
            return (
                <Td><Skeleton type={"text"}/></Td>
            );
        }
        case "successful": {
            return (
                <Td>{country.data?.name}</Td>
            );
        }
        case "error": {
            return (
                <Td>Not able to find country</Td>
            );
        }
    }
}