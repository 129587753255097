import * as React from "react";

import "./styles.scss";

type FilterRadioChipGroupViewProps = React.PropsWithChildren<{
    legend: React.ReactNode
}>;

export function FilterRadioChipGroupView({legend, children}: FilterRadioChipGroupViewProps) {
    return (
        <fieldset className={"filter-radio-chip-group-view"}>
            <legend>{legend}</legend>
            {children}
        </fieldset>
    );
}