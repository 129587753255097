import * as React from "react";
import {useRefInit} from "../hooks/useRefInit";

export function useIntersectionObserver<E extends Element = Element>(
    callback: IntersectionObserverCallback,
    options?: IntersectionObserverInit,
) {

    // const ref = React.useRef<E>();
    const intersectionObserver = useRefInit(() => new IntersectionObserver(callback, options));

    // React.useEffect(() => {
    //     const observer = new IntersectionObserver((entries, self) => {
    //         callback(entries, self);
    //     });
    //
    //     observer.observe(ref.current);
    //     return () => {
    //         observer.disconnect();
    //     };
    // },[]);

    React.useEffect(() => {
        return () => {
            if (intersectionObserver.current != null) {
                intersectionObserver.current.disconnect();
            }
        };
    }, []);


    return intersectionObserver.current;
}