import * as React from "react";
import {identity} from "../../../../utils/functions";
import {putAll} from "../../../../utils/maps";
import {HTMLElementValue} from "../../types";
import {isNoOption} from "../NoOptions/utils";
import {isOptgroup} from "../Optgroup/utils";
import {OptionLegacy} from "./index";
import {OptionProps} from "./types";

type OptionsRegistry = Map<HTMLElementValue, React.ReactElement<OptionProps>>;

type OptionsRegistration = [ React.ReactNode, OptionsRegistry];

export function registerOptions(
    value: HTMLElementValue,
    children: React.ReactNode,
    mapper: (node: React.ReactNode, value: HTMLElementValue, isOption: boolean) => React.ReactNode = identity,
): OptionsRegistration {

    const optionRegistry: OptionsRegistry = new Map<HTMLElementValue, React.ReactElement<OptionProps>>();

    const registeredChildren = React.Children.map(children, (child) => {
        if (isOptgroup(child)) {
            const [optionGroupChildren, optgroupRegistry] = registerOptions(
                value,
                child.props.children,
                mapper,
            );
            putAll(optionRegistry, optgroupRegistry);
            return React.cloneElement(child, {}, optionGroupChildren);
        } else if (isNoOption(child)) {
            return null;
        }

        const node = mapper(child, value, isOption(child));
        if (isOption(node)) {
            optionRegistry.set(node.props.value, node);
            return React.cloneElement(node, {selected: node.props.value === value});
        }
        return node;
    });

    if (optionRegistry.size === 0) {
        const noOptions = React.Children.map(children, (child) => {
            if (!isNoOption(child)) {
                return null;
            }
            return child;
        });
        return [noOptions, optionRegistry];
    }

    return [registeredChildren, optionRegistry];
}

export function isOption(element: unknown): element is React.ReactElement<OptionProps> {
    return React.isValidElement<OptionProps>(element) && element.type === OptionLegacy;
}
