import {AlarmTypesFilter} from "./types";
import {JSONPage} from "../../../models/utils/jsonList";
import {AlarmType} from "../../../models/alarm/alarmType";
import {assertOk, toRequestParamsString} from "../../../utils/requests";
import {Pageable} from "../../../models/types";

type FindAlarmTypesParams = Pageable<{ criteria: AlarmTypesFilter }>;

export async function findAlarmTypes(params?:FindAlarmTypesParams): Promise<JSONPage<AlarmType>> {
    const {criteria, ...pagination} = params ?? {};
    const response = await fetch(`/alarmTypes?${toRequestParamsString({...criteria, ...pagination})}`);
    assertOk(response);
    return response.json();
}