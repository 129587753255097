import * as React from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Company} from "../../../../models/company/companyModel";
import {CompanyComponentType, ComponentType} from "../../../../models/component/componentType";
import {Input} from "../../../common/form/Input";
import {Dialog} from "../../../common/Dialog";
import {Button} from "../../../common/Button";
import {useForm} from "../../../../Form";
import {consumeTargetValue, preventDefault, stopPropagation} from "../../../../utils/eventsHandling";
import {useDebounceValue} from "../../../../hooks/useDebounceValue";
import {useLazyList} from "../../../../hooks/useLazyList";
import {findComponentTypes} from "../../../Components/Types/api";
import {IntersectionObserver} from "../../../../IntersectionObserver";
import {range} from "../../../../utils/numbers";
import {Skeleton} from "../../../common/Skeleton";
import {useThrottleCallback} from "../../../../hooks/useThrottleCallback";

import "./styles.scss";
import {updateCompanyComponentTypes} from "../api";


type FormValues = {
    selected: string[],
    componentTypes: Record<string, { clientName: string, color: string }>
}

type NavigateState = {
    company?: Company,
    companyComponentTypes?: CompanyComponentType[],
}

export function CompanyComponentTypeSideSheetView() {

    const location = useLocation();
    const navigate = useNavigate();

    const {list, loading, reload, loadMore, hasMore, total} = useLazyList<string, ComponentType>({
        loader({page, input}) {
            return findComponentTypes({
                page,
                size: 20,
                sort: ["name,asc"],
                criteria: {
                    name: input,
                    usePartialName: true,
                },
            });
        }
    });

    const [filter, setFilter] = React.useState<string>("");
    const debounceFilter = useDebounceValue(filter, 600);

    React.useEffect(() => {
        if (!loading) {
            reload(debounceFilter);
        }
    }, [debounceFilter]);

    const throttleLoadMore = useThrottleCallback(loadMore, 600);

    const {company, companyComponentTypes}: NavigateState = location.state ?? {
        company: {},
        companyComponentTypes: []
    };

    const initialComponentTypes = companyComponentTypes.map(({componentType}) => componentType)
    const initialComponentTypesGuids = initialComponentTypes.map(({guid}) => guid);

    const values = React.useMemo(() => {
        return {
            selected: initialComponentTypesGuids,
            componentTypes: Object.fromEntries(companyComponentTypes.map((cct) => {
                return [cct.componentType.guid, {...cct, companyGuid: company.guid}]
            })),
        }
    }, [companyComponentTypes]);

    const form = useForm<FormValues>({
        values,
    });

    const [selectedComponentTypes] = form.watch(["selected"]);

    React.useEffect(() => {
        if (selectedComponentTypes == null) {
            return;
        }
        selectedComponentTypes
            .filter((guid) => !initialComponentTypesGuids.includes(guid))
            .forEach((guid) => {
                const componentType = list.find((ct) => ct.guid === guid);
                form.setValue(`componentTypes/${guid}`, {
                    componentTypeGuid:  guid,
                    companyGuid: company.guid,
                    color: "#ffffff",
                    componentType,
                    clientName: componentType.name,
                });
            })
    }, [selectedComponentTypes]);


    console.log(selectedComponentTypes);

    function cancel() {
        navigate(-1);
    }

    const componentTypes = initialComponentTypes.concat(list.filter(({guid}) => !initialComponentTypesGuids.includes(guid)));

    async function applyChanges(data: FormValues) {
        const toBeDelete = initialComponentTypesGuids.filter((guid) => !data.selected.includes(guid));
        const toBeUpdated = Object
            .entries(data.componentTypes)
            .filter(([componentTypeGuid]) => !toBeDelete.includes(componentTypeGuid))
            .map(([, cct]) => {
                return cct
            });

        console.log(data.componentTypes, "To delete", toBeDelete, "to Update", toBeUpdated)

        const response = await updateCompanyComponentTypes(company, toBeUpdated);
        console.log(response);
        navigate(-1);
    }

    return (
        <form onSubmit={form.handleSubmit(applyChanges)}>
            <Dialog type={"right-side"} className={"company-component-type-side-sheet-view"}>
                <header>
                    <hgroup>
                        <small>{company.name}</small>
                        <h3>Component types</h3>
                    </hgroup>
                    <div>
                        <Input type={"text"} placeholder={"Name"} value={filter} onChange={consumeTargetValue(setFilter)}/>
                    </div>
                </header>
                <ul>
                    {componentTypes.map((componentType) => {
                        const editable = selectedComponentTypes?.includes(componentType.guid) ?? [];
                        return (
                            <li key={componentType.guid} className={"company-component-type-option"}>
                                <div>
                                    <Input
                                        type={"checkbox"}
                                        {...form.register("selected")}
                                        value={componentType.guid}
                                        id={componentType.guid}
                                    />
                                    <label htmlFor={componentType.guid}>
                                        <h5>{componentType.name}</h5>
                                    </label>
                                    {editable && (
                                        <div className={"company-component-type-option-values"}>
                                            <Input
                                                type={"text"}
                                                placeholder={"Client name"}
                                                {...form.register(`componentTypes/${componentType.guid}/clientName`)}
                                            />
                                            <Input
                                                type={"color"}
                                                {...form.register(`componentTypes/${componentType.guid}/color`)}
                                            />
                                        </div>
                                    )}
                                </div>
                            </li>
                        );
                    })}
                    {hasMore() && (
                        <IntersectionObserver callback={throttleLoadMore}>
                            {range(Math.min(total - list.length, 20)).map((i) => {
                                return (
                                    <li key={i}>
                                        <Skeleton type={"text"}/>
                                    </li>
                                );
                            })}
                        </IntersectionObserver>
                    )}
                </ul>
                <footer>
                    <Button onClick={cancel} type={"button"}>Cancel</Button>
                    <Button variant={"primary"} disabled={!form.state().hasChanged}>Save</Button>
                </footer>
            </Dialog>
        </form>
    );
}