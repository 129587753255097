import * as React from "react";
import {Input} from "../../../../../../common/form/Input";
import {IntersectionObserver} from "../../../../../../../IntersectionObserver";
import {range} from "../../../../../../../utils/numbers";
import {DEBOUNCE_DELAY, LOAD_ITEM_COUNT, valueFromTo} from "../../common";
import {Skeleton} from "../../../../../../common/Skeleton";
import {useForm} from "../../../../../../../Form";
import {useDebounceValue} from "../../../../../../../hooks/useDebounceValue";
import {useLazyList} from "../../../../../../../hooks/useLazyList";
import {JSONPage} from "../../../../../../../models/utils/jsonList";
import {Button} from "../../../../../../common/Button";

import "./styles.scss";
import {useThrottleCallback} from "../../../../../../../hooks/useThrottleCallback";

type Entity = {guid: string, name: string};

type FilterForm<V> = {
    valueName: string,
    specific: boolean,
    values: V[],
}

type FilterMultiselectViewProps<E> = {
    legend: React.ReactNode,
    form: ReturnType<typeof useForm<FilterForm<E>>>,
    resettable?: boolean;
    listLoader(page: number, input: string): Promise<Omit<JSONPage<E>, "links" | "count">>,
};

export function FilterMultiValueSelectView<E extends Entity>(props: FilterMultiselectViewProps<E>) {
    const {listLoader, form, legend, resettable} = props;
    const [specific] = form.watch(["specific"]);
    const [valueName] = specific ? form.watch(["valueName"]) : [""];

    const {list, loading, reload, loadMore, total, hasMore} = useLazyList<string, E>({
        loader({page, input}) {
            return listLoader(page, input);
        }
    });

    const debounceValueName = useDebounceValue(valueName, DEBOUNCE_DELAY);

    React.useEffect(() => {
        if (!loading && specific) {
            reload(debounceValueName);
        }
    }, [debounceValueName]);

    const loadIfVisible = useThrottleCallback((entries: IntersectionObserverEntry[]) => {
        if (loading) {
            return;
        }

        const intersecting = entries.reduce((anyIntersecting, e) => {
            return anyIntersecting || e.isIntersecting
        }, false);

        if (intersecting) {
            loadMore();
        }
    }, 600);

    return (
        <fieldset>
            <legend>{legend} {(resettable == null || resettable) && <Button>Reset</Button>}</legend>
            <div className={"filter-multi-value-select-view"}>
                <header>
                    <Input type={"text"} {...form.register("valueName")} placeholder={"Name"}/>
                    <label>
                        <Input type={"checkbox"} {...form.register("specific")}/>
                        Select from list
                    </label>
                </header>
                {specific && (
                    <ul className={"multiselect-options"}>
                        {list.map((o) => {
                            return (
                                <li key={o.guid}>
                                    <label>
                                        <Input
                                            type={"checkbox"}
                                            value={o.guid}
                                            {...form.register("values", valueFromTo(o))}
                                        />
                                        {o.name}
                                    </label>
                                </li>
                            );
                        })}
                        {hasMore() && (
                            <IntersectionObserver callback={loadIfVisible}>
                                {range(Math.min(total - list.length, LOAD_ITEM_COUNT)).map((i) => {
                                    return (
                                        <li key={i}>
                                            <Skeleton type={"text"}/>
                                        </li>
                                    );
                                })}
                            </IntersectionObserver>
                        )}
                    </ul>
                )}
            </div>
        </fieldset>
    );
}