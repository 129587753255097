import * as React from "react";
import {CompanyComponentModel} from "../../../../models/company/companyModel";
import {ComponentModel, ComponentModelAlarmType} from "../../../../models/component/componentModel";
import {ComponentModelDiagnosticType} from "../../../../models/diagnostic/componentModelDiagnosticType";
import {PropertyConfiguration} from "../../../../models/property/property";
import {format} from "../../../../utils/datetime";
import {consumeTargetValue, onCurrentTarget, onKey, stopPropagation} from "../../../../utils/eventsHandling";
import {constant} from "../../../../utils/functions";
import {Td, TdState} from "../../../common/DataTable";
import {ComponentModelAlarmsTypeModalView} from "./AlarmsTypes";
import {ComponentModelCompaniesModalView} from "./CompaniesV1";
import {
    ComponentModelDiagnosticsTypesModalView,
} from "./DiagnosticsTypes";
import {ComponentModelPropertiesModalView} from "./Properties";
import {ComponentModelCompaniesChangesManagerProvider} from "./contexts/ComonentModelChangesContext";
import {SelectLegacy} from "../../../common/form/SelectLegacy";
import {
    getComponentModelDiagnosticsTypes,
    getComponentModelsAlarmsTypes,
    getComponentModelsProperties,
    getManufacturers
} from "../api";
import {OptionLegacy} from "../../../common/form/OptionLegacy";
import {Icon} from "../../../common/Icon";
import {After} from "../../../common/After";
import {Button} from "../../../common/Button";
import {useData} from "../../../../DataLoader";
import {usePromise} from "../../../../hooks/usePromise";
import {useComponentModel} from "./contexts/ComponentModelProvider";
import {Skeleton} from "../../../common/Skeleton";
import {JSONList} from "../../../../models/utils/jsonList";

// fixme should be in the CellViewFolder
export function ComponentModelCompaniesCell({companies}: Record<"companies", CompanyComponentModel[]>) {
    const [open, setOpen] = React.useState(false);
    return (
        <Td onClick={onCurrentTarget(constant(setOpen, true))} className={"table-cell-text-align-right"}>
            <span className={"cell-with-icon"} onClick={constant(setOpen, true)} >
                {companies.length}
                <Icon name={"open_in_new"}/>
            </span>
            <ComponentModelCompaniesChangesManagerProvider>
                {open && <ComponentModelCompaniesModalView open={open} onClose={constant(setOpen, false)}/>}
            </ComponentModelCompaniesChangesManagerProvider>
        </Td>
    );
}

type UseComponentModelCellProps<T> = {
    loader(componentModel: ComponentModel): Promise<JSONList<T>>;
    initialData: Awaited<T[]>;
};

function useComponentModelCell<T>({loader, initialData}: UseComponentModelCellProps<T>) {
    const [open, setOpen] = React.useState(false);

    const componentModel = useComponentModel();

    const {data, state, refresh} = usePromise(loader, [componentModel], {
        callOnMount: false,
        initResult: {list:initialData},
    });

    function close(update: boolean) {
        setOpen(false);
        if (update) {
            refresh();
        }
    }

    const cellBody = state === "pending"
        ? <Skeleton type={"text"}/>
        : (
            <span className={"cell-with-icon"} onClick={constant(setOpen, true)}>
                {data.list.length}
                <Icon name={"open_in_new"}/>
            </span>
        );

    return {open, cellBody, close, data, setOpen};
}

export function ComponentModelAlarmsTypesCell({alarmsType}: Record<"alarmsType", ComponentModelAlarmType[]>) {

    const {open, cellBody, close, data, setOpen} = useComponentModelCell({
        loader: getComponentModelsAlarmsTypes,
        initialData: alarmsType.map((at) => at.alarmType),
    });

    return (
        <Td onClick={onCurrentTarget(constant(setOpen, true))} className={"table-cell-text-align-right"}>
            {cellBody}
            {open && <ComponentModelAlarmsTypeModalView alarmsTypes={data.list} onClose={close}/>}
        </Td>
    );
}

export function ComponentModelDiagnosticsTypesCell(
    {diagnosticsTypes}: Record<"diagnosticsTypes", ComponentModelDiagnosticType[]>,
) {
    const {open, cellBody, close, data, setOpen} = useComponentModelCell({
        loader: getComponentModelDiagnosticsTypes,
        initialData: diagnosticsTypes,
    });

    return (
        <Td onClick={onCurrentTarget(constant(setOpen, true))} className={"table-cell-text-align-right"}>
            {cellBody}
            {open && <ComponentModelDiagnosticsTypesModalView diagnosticsTypes={data.list} onClose={close}/>}
        </Td>
    );
}

export function ComponentModelPropertiesCell({properties}: Record<"properties", PropertyConfiguration[]>) {
    const {open, cellBody, close, data, setOpen} = useComponentModelCell({
        loader: getComponentModelsProperties,
        initialData: properties,
    });

    return (
        <Td onClick={onCurrentTarget(constant(setOpen, true))} className={"table-cell-text-align-right"}>
            {cellBody}
            {open && <ComponentModelPropertiesModalView properties={data.list} onClose={close}/>}
        </Td>
    );
}

export function ComponentModelCreationDateDataCell({value}: Record<"value", Date>) {
    return (
        <Td> {format(value)}</Td>
    );
}

interface ComponentModelCellProps {
    state: TdState;
    value: string;
    onSubmit(newValue: string): void;
    onReset(): void;
}

export function ComponentModelName({ state, value, onSubmit, onReset}: ComponentModelCellProps) {

    const [currentValue, setCurrentValue] = React.useState(value);

    React.useEffect(() => {
        setCurrentValue(value);
    }, [value]);

    return (
        <Td state={state} ordinal={"first"} className={"focusable-cell"}>
            <label className={"input-field input-text-cell"}>
                <input
                    type={"text"}
                    value={currentValue}
                    onChange={consumeTargetValue(setCurrentValue)}
                    onBlur={constant(onSubmit, currentValue)}
                    onKeyDown={onKey("Enter", constant(onSubmit, currentValue))}
                />
                { state === "edited" ? (
                    <Button variant={"tertiary"} onClick={onReset}>
                        <Icon name={"undo"}/>
                    </Button>
                ) : (
                    <Icon name={"edit"}/>
                )}
            </label>
        </Td>
    );
}

export function ComponentModelManufacturer({ state, value, onSubmit, onReset}: ComponentModelCellProps) {

    const manufactures = useData({
        loader: getManufacturers,
        keys: ["component-models-manufacturers"],
    });

    const manufacturersTranslations = manufactures.state === "successful" ? manufactures.data.translations : [];

    return (
        <Td state={state}>
            <SelectLegacy value={value} onChange={consumeTargetValue(onSubmit)}>
                {manufacturersTranslations.map((translation) => {
                    return (
                        <OptionLegacy key={translation.textKey} value={translation.textKey}>
                            {translation.englishSource}
                        </OptionLegacy>
                    );
                })}
                { state === "edited" ? (
                    <After>
                        <Button variant={"tertiary"} onClick={stopPropagation(onReset)}>
                            <Icon name={"undo"}/>
                        </Button>
                    </After>
                ) : null}
            </SelectLegacy>
        </Td>
    );
}
