import {HTMLElementValue} from "../../types";
import {useMap} from "../../../../hooks/useMap";
import {OptionData, UseOptionControllerType} from "./types";
import * as React from "react";


type UseOptionsControllerProps = {
    multiple?: boolean,
    values?: HTMLElementValue[],
    onChange?(value: string): void;
    isSelected?(value: string): boolean
};


export function useOptionController({multiple, values, onChange, isSelected}: UseOptionsControllerProps): UseOptionControllerType {

    const options = useMap<HTMLElementValue, OptionData>();
    const [selected, setSelected] = React.useState<HTMLElementValue[]>([]);

    React.useEffect(() => {
        if (values) {
            setSelected(values);
        } else {
            const [first] = Array.from(options.ref(), (([k]) => k));
            setSelected([first]);
        }
    }, []);

    return {
        options: options.ref(),
        multiple: multiple ?? false,
        selected,
        register(value, optionData) {
            options.ref().set(value, optionData);
        },
        change(value) {
            onChange(value);
        },
        isSelected
    };
}

