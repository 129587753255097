import * as React from "react";
import {ThWithFilterView, FilterColumnOrderView} from "../../filters";

import "./styles.scss";

export function CreatedAtColumnHeadView() {
    return (
        <ThWithFilterView heading={"Created"} ordinal={"last"}>
            <FilterColumnOrderView columnKey={"createdAt"} className={"created-at-ordering-view"}/>
        </ThWithFilterView>
    );
}