import * as React from "react";
import {Chip} from "../../../../common/chips";
import {Button} from "../../../../common/Button";
import {Icon} from "../../../../common/Icon";
import {useQueryParams} from "../../../../QueryParams";
import {
    ALARM_CATEGORIES_DATA_KEY,
    ALARM_TYPES_DATA_KEY,
    COMPANIES_DATA_KEY,
    CompaniesQueryParamsType, COMPANY_TYPE_DATA_KEY,
    CompanyAlarmTypesQueryParamsType,
    CompanyComponentModelQueryParamsType,
    CompanyComponentTypesQueryParamsType,
    CompanyTypesQueryParamsType, COMPONENT_CATEGORIES_DATA_KEY,
    COMPONENT_MODELS_DATA_KEY,
    COMPONENT_TYPES_DATA_KEY, PARENT_COMPANIES_DATA_KEY,
    ParentCompaniesQueryParamsType
} from "../columns";
import {constant} from "../../../../../utils/functions";
import {useDatastore} from "../../../../../DataLoader";
import {CompanyType} from "../../../../../models/company/companyTypeModel";
import {AlarmType} from "../../../../../models/alarm/alarmType";
import {ComponentType} from "../../../../../models/component/componentType";
import {ComponentModel} from "../../../../../models/component/componentModel";
import {ComponentCategory} from "../../../../../models/component/componentCategory";
import {JSONList} from "../../../../../models/utils/jsonList";

import "./styles.scss";

type QueryParams =
    CompaniesQueryParamsType
    & CompanyTypesQueryParamsType
    & CompanyComponentTypesQueryParamsType
    & CompanyComponentModelQueryParamsType
    & CompanyAlarmTypesQueryParamsType
    & ParentCompaniesQueryParamsType;


type DatastoreType = JSONList<CompanyType | AlarmType| ComponentType | ComponentModel | ComponentCategory>;

export function FilterBar({className, ...props}: React.HTMLProps<HTMLDivElement>) {

    const {store} = useDatastore<DatastoreType>({
        keys: [
            COMPANIES_DATA_KEY,
            PARENT_COMPANIES_DATA_KEY,
            COMPONENT_CATEGORIES_DATA_KEY,
            COMPONENT_TYPES_DATA_KEY,
            COMPONENT_MODELS_DATA_KEY,
            COMPANY_TYPE_DATA_KEY,
            ALARM_TYPES_DATA_KEY,
            ALARM_CATEGORIES_DATA_KEY
        ],
    });

    const {params, remove} = useQueryParams<QueryParams>();

    const filterEntries = Object.entries(params);
    const filters = filterEntries.map(([key, x]: [keyof QueryParams, any]) => {
        switch (key) {
            case "company" : {

                const names = store.get(COMPANIES_DATA_KEY)?.list
                    ?.filter(({guid}) => guid === x || x instanceof Array && x.includes(guid))
                    ?.map(({name}) => `"${name}"`)
                    ?.join(" or ");

                return (
                    <Chip className={"filter-chip"} key={key}>
                        Is {names}
                        <Button onClick={constant(remove, key)}>
                            <Icon name={"close"}/>
                        </Button>
                    </Chip>
                );
            }
            case "searchTerm" : {
                return (
                    <Chip className={"filter-chip"} key={key}>
                        Name containing "{x}"
                        <Button onClick={constant(remove, key)}>
                            <Icon name={"close"}/>
                        </Button>
                    </Chip>
                );
            }
            case "parent": {
                const names = store.get(PARENT_COMPANIES_DATA_KEY)?.list
                    ?.filter(({guid}) => guid === x || x instanceof Array && x.includes(guid))
                    ?.map(({name}) => `"${name}"`)
                    ?.join(" or ");

                return (
                    <Chip className={"filter-chip"} key={key}>
                        Has parent company {names}
                        <Button onClick={constant(remove, key)}>
                            <Icon name={"close"}/>
                        </Button>
                    </Chip>
                );
            }
            case "parentName": {
                return (
                    <Chip className={"filter-chip"} key={key}>
                        Has parent company's name containing "{x}"
                        <Button onClick={constant(remove, key)}>
                            <Icon name={"close"}/>
                        </Button>
                    </Chip>
                );
            }
            case "type": {
                const names = store.get(COMPANY_TYPE_DATA_KEY)?.list
                    ?.filter(({guid}) => guid === x || x instanceof Array && x.includes(guid))
                    ?.map(({name}) => `"${name}"`)
                    ?.join(" or ");

                return (
                    <Chip className={"filter-chip"} key={key}>
                        Has type  {names}
                        <Button onClick={constant(remove, key)}>
                            <Icon name={"close"}/>
                        </Button>
                    </Chip>
                );
            }
            case "componentCategoryType": {
                return (
                    <Chip className={"filter-chip"} key={key}>
                        {x === "equipment" ? "Has equipment components" : "Has non-equipment components"}
                        <Button onClick={constant(remove, key)}>
                            <Icon name={"close"}/>
                        </Button>
                    </Chip>
                );
            }
            case "componentCategory": {
                const names = store.get(COMPONENT_CATEGORIES_DATA_KEY)?.list
                    ?.filter(({guid}) => guid === x || x instanceof Array && x.includes(guid))
                    ?.map(({name}) => `"${name}"`)
                    ?.join(" or ");

                return (
                    <Chip className={"filter-chip"} key={key}>
                        Has component category {names}
                        <Button onClick={constant(remove, key)}>
                            <Icon name={"close"}/>
                        </Button>
                    </Chip>
                );
            }
            case "componentType" : {
                const names = store.get(COMPONENT_TYPES_DATA_KEY)?.list
                    ?.filter(({guid}) => guid === x || x instanceof Array && x.includes(guid))
                    ?.map(({name}) => `"${name}"`)
                    ?.join(" or ");

                return (
                    <Chip className={"filter-chip"} key={key}>
                        Has component type {names}
                        <Button onClick={constant(remove, key)}>
                            <Icon name={"close"}/>
                        </Button>
                    </Chip>
                );
            }
            case "componentTypeName": {
                return (
                    <Chip className={"filter-chip"} key={key}>
                        Has component type name containing {x}
                        <Button onClick={constant(remove, key)}>
                            <Icon name={"close"}/>
                        </Button>
                    </Chip>
                );
            }
            case "componentModel": {
                const names = store.get(COMPONENT_MODELS_DATA_KEY)?.list
                    ?.filter(({guid}) => guid === x || x instanceof Array && x.includes(guid))
                    ?.map(({name}) => `"${name}"`)
                    ?.join(" or ");

                return (
                    <Chip className={"filter-chip"} key={key}>
                        Has component model {names}
                        <Button onClick={constant(remove, key)}>
                            <Icon name={"close"}/>
                        </Button>
                    </Chip>
                );
            }
            case "componentModelName": {
                return (
                    <Chip className={"filter-chip"} key={key}>
                        Has component model name containing {x}
                        <Button onClick={constant(remove, key)}>
                            <Icon name={"close"}/>
                        </Button>
                    </Chip>
                );
            }
            case "alarmCategory": {
                const names = store.get(ALARM_CATEGORIES_DATA_KEY)?.list
                    ?.filter(({guid}) => guid === x || x instanceof Array && x.includes(guid))
                    ?.map(({name}) => `"${name}"`)
                    ?.join(" or ");

                return (
                    <Chip className={"filter-chip"} key={key}>
                        Has alarm category {names}
                        <Button onClick={constant(remove, key)}>
                            <Icon name={"close"}/>
                        </Button>
                    </Chip>
                );
            }
            case "alarmType": {
                const names = store.get(ALARM_TYPES_DATA_KEY)?.list
                    ?.filter(({guid}) => guid === x || x instanceof Array && x.includes(guid))
                    ?.map(({name}) => `"${name}"`)
                    ?.join(" or ");

                return (
                    <Chip className={"filter-chip"} key={key}>
                        Has alarm type {names}
                        <Button onClick={constant(remove, key)}>
                            <Icon name={"close"}/>
                        </Button>
                    </Chip>
                );
            }
            case "alarmTypeName": {
                return (
                    <Chip className={"filter-chip"} key={key}>
                        Has alarm type name containing {x}
                        <Button onClick={constant(remove, key)}>
                            <Icon name={"close"}/>
                        </Button>
                    </Chip>
                );
            }
        }
        return null;
    });

    function removeAll() {
        remove(...filterEntries.filter(([key]) => !["sort", "page", "size"].includes(key)).map(([key]) => key) as Array<keyof QueryParams>);
    }

    if (filters.length === 0) {
        return null;
    }

    return (
        <div className={`filter-bar-view ${className?? ""}`} {...props}>
            <span className={"filter-bar-title"}>
                <Icon name={"filter_list"}/>
                Filters
            </span>
            <ul>
                {filters}
            </ul>
            <Button variant={"tertiary"} className={"clear-all-filter-btn"} onClick={removeAll}>
                Clear all
            </Button>
        </div>
    );
}