import * as React from "react";
import {StatefulMap} from "../../../../../../../hooks/useMap";
import {AbstractComponentBrand, VirtualComponentBrand} from "./types";

interface AbstractComponentBrandsContextType {
    componentBrands: StatefulMap<string | number, AbstractComponentBrand>;
    addBrand(description: string): VirtualComponentBrand;
}

export const AbstractComponentBrandsContext = React.createContext<AbstractComponentBrandsContextType>(null);
