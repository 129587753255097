// import {Icon, icons} from "@danfoss/etui-core";
import * as React from "react";
import {
    ADJACENT_TO_ANCHOR_BOTTOM_OR_TOP_START_ALIGNED_GAP_10PX,
} from "../../../../hooks/useOverlay";
import {inside} from "../../../../utils/arrays";
import {setInputValue} from "../../../../utils/DOM";
import {onKey, preventDefault, toOutsideElements} from "../../../../utils/eventsHandling";
import {constant} from "../../../../utils/functions";
import {Icon} from "../../Icon";
import {Overlay} from "../../Overlay";
import {getSelectChildren} from "./utils";

import "./styles.scss";

export type SelectRef =  HTMLSelectElement & {
    root: React.RefObject<HTMLSpanElement>;
    toggle(): void;
}

function SelectDef(
    {children, name, value, disabled, ...props}: React.HTMLProps<HTMLSelectElement>,
    ref: React.ForwardedRef<SelectRef>,
) {
    const spanRef = React.useRef<HTMLSpanElement>();
    const selectRef = React.useRef<SelectRef>();

    const [open, setOpen] = React.useState(false);

    React.useImperativeHandle(ref, () => {

        selectRef.current.root = spanRef;
        selectRef.current.toggle = () => {
            setOpen((prev) => !prev);
        };

        return selectRef.current;
    }, []);

    const { values, htmlOptions, valueIndex, options, nonOptions, afterElement, providedMenu, registeredOptions }
        = getSelectChildren(children, value);

    function fireOnChange(e: React.MouseEvent<HTMLSpanElement>) {
        if (!(e.target instanceof HTMLLIElement)) {
            setOpen(!disabled);
            return;
        } else if ((/true/i).test(e.target.dataset.disabled)) {
            return;
        }
        setOpen(false);
        setInputValue(selectRef.current, e.target.dataset.value);
    }

    const onDisplay = open && !disabled ? (
        <>
            {nonOptions}
            {Boolean(providedMenu) ? providedMenu : (
                <Overlay
                    asTag={"menu"}
                    positioned={ADJACENT_TO_ANCHOR_BOTTOM_OR_TOP_START_ALIGNED_GAP_10PX}
                    className={"pseudo-select-dropdown-menu"}
                >
                    {registeredOptions}
                </Overlay>
            )}
        </>
    ) : options.get(value)?.props?.children; // Todo investigate if cloning needed

    const handleKeyboardValueChange = onKey(
        "ArrowUp", preventDefault(constant(setInputValue, selectRef.current, inside(values, valueIndex - 1))),
        "ArrowDown", preventDefault(constant(setInputValue, selectRef.current, inside(values, valueIndex + 1))),
        "Enter", preventDefault(constant(setOpen, false)),
    );

    return (
        <span
            {...props}
            ref={spanRef}
            tabIndex={0}
            data-disabled={disabled ?? false}
            className={`pseudo-select input-field ${props.className ?? ""}`}
            onBlur={toOutsideElements(constant(setOpen, false))}
            onClick={fireOnChange}
            onKeyDown={handleKeyboardValueChange}
        >
            {onDisplay}
            {afterElement}

            <Icon name={"unfold_more"} className={"pseudo-select-arrows"}/>
            {/*<span className={"pseudo-select-arrows"}><Icon glyph={icons.DOUBLE_ARROW}/></span>*/}
            <select ref={selectRef} name={name} className={"form-input"}>{htmlOptions}</select>
        </span>
    );
}

export const SelectLegacy = React.forwardRef(SelectDef);
