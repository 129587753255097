import * as React from "react";
import {makeCurrentTargetTheTarget} from "../../../../utils/eventsHandling";
import {OptionProps} from "./types";

export function OptionLegacy({children, value, keywords, disabled, className, selected, ...props}: OptionProps) {
    const liRef = React.useRef<HTMLLIElement>();

    React.useEffect(() => {
        if (selected) {
            liRef.current.scrollIntoView({ block: "nearest" });
        }
    }, [selected]);

    const originalClassName = "option";

    const liClassName = typeof className === "function"
        ? className(originalClassName)
        : `${originalClassName} ${className ?? ""}`;

    return (
        <li
            {...props}
            ref={liRef}
            data-value={value}
            data-selected={selected}
            data-keywords={keywords ?? value}
            data-disabled={disabled}
            className={liClassName}
            onClick={makeCurrentTargetTheTarget(props?.onClick)}
        >
            {children}
        </li>
    );
}
