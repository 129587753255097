import {assertOk, toRequestParamsString} from "../../utils/requests";
import {JSONEntitiesCollection} from "../../models/utils/jsonList";
import {Location} from "../../models/location/locationModel";
import {LocationsFilter} from "./types";
import {Pageable} from "../../models/types";


type FindLocationsParams = Pageable<{criteria?: LocationsFilter}>;

export async function findLocations(params?: FindLocationsParams): Promise<JSONEntitiesCollection<Location>> {
    const {criteria, ...pagination} = params;
    const response = await fetch(`/locations?${toRequestParamsString({...criteria, ...pagination})}`);
    assertOk(response);
    return response.json();
}