import {ComponentModel} from "../../../models/component/componentModel";
import {ComponentModelTableFilters, useComponentModelTable} from "./TableView";
import {ComponentType} from "../../../models/component/componentType";
import {omit} from "../../../utils/objects";
import * as React from "react";
import {getComponentModels} from "./api";

const NUMBER_OF_ITEMS_PER_PAGE = 11;

interface State {
    offset: number;
    limit: number;
    total: number;
    loading: boolean;
    componentModels: ComponentModel[];
    filter: ComponentModelTableFilters;
}

type Action =
    { type: "fetch_component_models", offset: number }
    | { type: "update_component_models_list", newComponentModels: ComponentModel[], total: number }
    | { type: "filter_component_models", filter: ComponentModelTableFilters }
    | { type: "merge_component_models_list", updatedComponentModels: ComponentModel[] };

function componentModelsStateReducer(state: State, action: Action): State {
    switch (action.type) {
        case "fetch_component_models": {
            return {
                ...state,
                offset: action.offset,
                componentModels: [],
                loading: true,
            };
        }
        case "update_component_models_list": {
            return {
                ...state,
                total: action.total,
                componentModels: action.newComponentModels,
                loading: false,
            };
        }
        case "filter_component_models": {
            return {
                ...state,
                loading: true,
                offset: 0,
                filter: action.filter,
                componentModels: [],
            };
        }
        case "merge_component_models_list": {
            return {
                ...state,
                componentModels: state.componentModels.map((cm) => {
                    const updated = action.updatedComponentModels.find((ucm) => ucm.guid === cm.guid);
                    return {
                        ...cm,
                        ...updated,
                        componentCount: cm.componentCount,
                    };
                }),
            };
        }
    }
}

export function useComponentModels() {
    const [state, dispatch] = React.useReducer(componentModelsStateReducer, {
        offset: 0,
        limit: NUMBER_OF_ITEMS_PER_PAGE,
        total: 0,
        loading: false,
        componentModels: [],
        filter: {
            names: {
                selected: [],
                sortDirection: "none",
            },
            componentCategoriesGuids: {
                selected: [],
                sortDirection: "none",
            },
            componentTypesGuids: {
                selected: [],
                sortDirection: "none",
            },
            companiesGuids: {
                selected: [],
                sortDirection: "none",
            },
        },
    });

    const {filterColumnsOptions, filterColumnProps, clear} = useComponentModelTable(state.filter, (newFilter) => {
        dispatch({type: "filter_component_models", filter: newFilter});
        getComponentModels({
            variant: "FULL",
            limit: state.limit,
            offset: 0,
            sortBy: "NAME",
            sortDirection: "ASC",
            ...fromComponentModelTableFilterToApiFilter(newFilter, filterColumnsOptions.componentTypes),
        })
            .then(({list, total}) => {
                dispatch({type: "update_component_models_list", newComponentModels: list, total});
            })
            .catch(console.error);
    });

    function jumpToDifferentPage(page: number) {
        const offset = (page - 1) * NUMBER_OF_ITEMS_PER_PAGE;
        dispatch({type: "fetch_component_models", offset});
        getComponentModels({
            variant: "FULL",
            limit: state.limit,
            offset,
            sortBy: "NAME",
            sortDirection: "ASC",
            ...fromComponentModelTableFilterToApiFilter(state.filter, filterColumnsOptions.componentTypes),
        })
            .then(({list, total}) => {
                dispatch({type: "update_component_models_list", newComponentModels: list, total});
            })
            .catch(console.error);
    }

    return {state, dispatch, filterColumnProps, jumpToDifferentPage, clear};
}

function fromComponentModelTableFilterToApiFilter(filter: ComponentModelTableFilters, componentTypes: ComponentType[]) {
    const componentCategoryGuidsToExclude = componentTypes
        .filter((ct) => filter.componentTypesGuids.selected.includes(ct.guid))
        .map((ct) => ct.category.guid);

    const componentTypesGuidsFromComponentCategories = componentTypes
        .filter((ct) => {
            return ct.category
                && filter.componentCategoriesGuids.selected.includes(ct.category.guid)
                && !componentCategoryGuidsToExclude.includes(ct.category.guid);
        })
        .map((ct) => ct.guid);

    return omit({
        names: filter.names.selected,
        companiesGuids: filter.companiesGuids.selected,
        componentsTypeGuids: filter.componentTypesGuids.selected.concat(componentTypesGuidsFromComponentCategories),
    }, (_, value) => value.length === 0);
}