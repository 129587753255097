import * as React from "react";
import {ElementDirection, ElementSide} from "../models/types";

export function useAnchor<T extends HTMLElement>() {
    const anchorRef = React.useRef<T>();
    return {
        anchorRef,
        anchor(side: ElementSide) {
            return (elemSide: ElementSide) => {
                const boundingClientRect = anchorRef.current.getBoundingClientRect();
                const height = boundingClientRect.height;
                const width = boundingClientRect.width;
                const top = boundingClientRect.top;
                const left = boundingClientRect.left;
                const bottom = window.innerHeight - boundingClientRect.bottom;
                const right = window.innerWidth - boundingClientRect.right;
                switch (side) {
                    case "top": {
                        if (elemSide === "top") {
                            return top;
                        } else if (elemSide == "bottom") {
                            return bottom + height;
                        }
                        return 0;
                    }
                    case "bottom": {
                        if (elemSide === "top") {
                            return top + height;
                        } else if (elemSide == "bottom") {
                            return bottom;
                        }
                        return 0;
                    }
                    case "left": {
                        if (elemSide === "left") {
                            return left;
                        } else if (elemSide == "right") {
                            return right + width;
                        }
                        return 0;
                    }
                    case "right": {
                        if (elemSide === "left") {
                            return left + width;
                        } else if (elemSide == "right") {
                            return right;
                        }
                        return 0;
                    }
                }
                return 0;
            };
        },
        anchorCenter(elem: HTMLElement, elemDirection: ElementDirection) {
            const {top, left, width: anchorWidth, height: anchorHeight} = anchorRef.current.getBoundingClientRect();
            const {width, height} = elem.getBoundingClientRect();
            switch (elemDirection) {
                case "horizontal": {
                    return left - (width - anchorWidth) / 2;
                }
                case "vertical": {
                    return top - (height - anchorHeight) / 2;
                }
            }
            return 0;
        }
    };
}