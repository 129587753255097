import * as React from "react";
import {useSet} from "../../../hooks/useSet";
import {consumeTarget, preventDefault} from "../../../utils/eventsHandling";
import {constant} from "../../../utils/functions";
import {FilterState} from "../../Components/Models/TableView/types";
import {isOption} from "../form/OptionLegacy/utils";

import "./styles.scss";
import {Button} from "../Button";

export interface FilterViewProps {
    value: FilterState<string>;
    onChange(filter: FilterState<string>): void;
}

export function FilterView({children, value, onChange}: React.PropsWithChildren<FilterViewProps>) {
    const selected = useSet(value.selected);

    function updateSelection(v: string) {
        return (checked: boolean) => {
            if (checked) {
                selected.add(v);
            } else {
                selected.delete(v);
            }
        };
    }

    function apply() {
        onChange({...value, selected: selected.toArray()});
    }

    function clear() {
        onChange({...value, selected: []});
    }

    React.useEffect(() => {
        selected.from(value.selected);
    }, [value]);

    const sections = React.Children.map(children, (child) => {
        if (!(React.isValidElement<React.HTMLProps<HTMLElement>>(child) && child.type === "section")) {
            return null;
        }
        return child;
    });

    // Todo this should be a <Select multiple={true}> ... </Select>

    const options = React.Children.map(children, (child) => {
        if (!isOption(child)) {
            return null;
        }

        return React.cloneElement(
            child,
            child.props,
            (
                <label>
                    <input
                        type={"checkbox"}
                        checked={selected.has(`${child.props.value}`)}
                        onChange={consumeTarget("checked", updateSelection(`${child.props.value}`))}
                    />
                    {child.props.children}
                </label>
            ),
        );
    });

    return (
        <form onSubmit={preventDefault} className={"filter-view"}>
            {sections}
            <section className={"filter-options-section"}>
                <ul className={"filter-options"}>
                    {options}
                </ul>
            </section>
            <footer className={"filter-actions"}>
                {selected.isEmpty() ? (
                    <Button variant={"secondary"} onClick={constant(onChange, value)}>Ok</Button>
                ) : (
                    <>
                        <Button variant={"tertiary"} onClick={clear}>Clear</Button>
                        <Button variant={"secondary"} onClick={apply}>Apply</Button>
                    </>
                )}
            </footer>
        </form>
    );
}

export function isFilterView(element: unknown): element is React.ReactElement<FilterViewProps> {
    return React.isValidElement<FilterViewProps>(element) && element.type === FilterView;
}
