import {ElementDirection, ElementSide} from "../models/types";
import * as React from "react";
import {useRefInit} from "./useRefInit";

type AnchoredPositions = {
    top?: (side: ElementSide) => number,
    bottom?: (side: ElementSide) => number,
    left?: (side: ElementSide) => number,
    right?: (side: ElementSide) => number,
    justifySelf?: (popover: Element, direction: ElementDirection) => number,
    alignSelf?: (popover: Element, direction: ElementDirection) => number,
};
type UsePopoverProps = {
    position: AnchoredPositions;
};

export function usePopover<T extends HTMLElement>(props?: UsePopoverProps) {

    const popoverId = useUnique({length: 10});

    const popoverRef = React.useRef<HTMLElement>();
    function eventListener({newState, target}) {
        if (newState === "open") {
            target.style.display = "initial";
            if (props?.position?.top) {
                target.style.marginTop = `${props.position.top("top")}px`;
            }
            if (props?.position?.bottom) {
                target.style.marginBottom = `${props.position.bottom("bottom")}px`;
            }
            if (props?.position?.alignSelf) {
                target.style.marginTop = `${props.position.alignSelf?.(target, "vertical")}px`;
            }

            if (props?.position?.left) {
                target.style.marginLeft = `${props.position.left("left")}px`;
            }
            if (props?.position?.right) {
                target.style.marginRight = `${props.position.right("right")}px`;
            }
            if (props?.position?.justifySelf) {
                target.style.marginLeft = `${props.position.justifySelf?.(target, "horizontal")}px`;
            }
            target.style.display = "";
        }
    }

    // useEventListener
    React.useLayoutEffect(() => {
        // @ts-ignore:disable-next-line
        popoverRef.current.addEventListener("beforetoggle", eventListener);
        return () => {
            // @ts-ignore:disable-next-line
            popoverRef.current.removeEventListener("beforetoggle", eventListener)
        }
    }, []);

    return {
        popoverRef,
        popoverId,
        show() {
            // @ts-ignore:disable-next-line
            popoverRef.current.showPopover();
        },
        hide() {
            // @ts-ignore:disable-next-line
            popoverRef.current.hidePopover();
        },
        toggle() {
            // @ts-ignore:disable-next-line
            popoverRef.current.togglePopover();
        }
    };
}


const lowercaseAlphabet = "abcdefghijklmnopqrstuvwxyz";
const uppercaseAlphabet = lowercaseAlphabet.toUpperCase();
const numbers = '0123456789';
const specialCharacters = '_-';
const dictionary = lowercaseAlphabet + uppercaseAlphabet + numbers + specialCharacters;


type UseUniqueProps = {
    length: number
}

function useUnique({length}: UseUniqueProps): string {
    const ref = useRefInit(() => {
        return Array
            .from({length}, () => Math.round(Math.random() * dictionary.length))
            .map((x) => dictionary[x])
            .join("");
    });

    return ref.current;
}