import * as React from "react";
import {
    ADJACENT_TO_ANCHOR_BOTTOM_START_ALIGNED_GAP_10PX,
} from "../../../../hooks/useOverlay";
import {Overlay} from "../../Overlay";
import {DatalistRef} from "../Datalist";
import {useDatalist} from "./useDatalist";

import "./styles.scss";

type InputProps = Omit<React.HTMLProps<HTMLInputElement>, "list"> & {
    list?: React.RefObject<DatalistRef> | string;
};

export type Input = HTMLInputElement & {
    overlay?: React.RefObject<HTMLMenuElement>;
};

function InputDef({list, type, ...props}: InputProps, ref: React.ForwardedRef<Input>) {
    const inputRef = React.useRef<Input>();
    const overlayRef = React.useRef();

    React.useImperativeHandle(ref, () => {
        inputRef.current.overlay = overlayRef;
        return inputRef.current;
    }, []);

    if (typeof list === "string" || typeof list === "undefined") {
        return (
            <input {...props} ref={inputRef} list={list}/>
        );
    }

    return (
        <InputWithDatalist inputRef={inputRef} overlayRef={overlayRef} list={list} {...props}/>
    );
}

type InputWithDatalistProps = Omit<React.HTMLProps<HTMLInputElement>, "list"> & {
    inputRef: React.RefObject<Input>;
    overlayRef: React.RefObject<HTMLElement>;
    list: React.RefObject<DatalistRef>
};

function InputWithDatalist({inputRef, list, overlayRef, ...props}: InputWithDatalistProps) {

    const {datalistProps, isOpen } = useDatalist(inputRef, overlayRef, list);

    return (
        <>
            <input {...props} ref={inputRef} />
            {isOpen && React.Children.count(datalistProps?.children) > 0 ? (
                <Overlay
                    {...datalistProps}
                    asTag={"menu"}
                    tabIndex={-1}
                    params={{anchor: inputRef, target: overlayRef}}
                    positioned={ADJACENT_TO_ANCHOR_BOTTOM_START_ALIGNED_GAP_10PX}
                    className={`input-datalist ${datalistProps.className ?? ""}`}
                />
            ) : null}
        </>
    );
}

export const InputLegacy = React.forwardRef(InputDef);
