import * as React from "react";
import {Td} from "../../../../../../common/DataTable";
import {Chip} from "../../../../../../common/chips";
import {CompanyComponentType} from "../../../../../../../models/component/componentType";
import {createPartial} from "../../../../../../../utils/arrays";
import {Button} from "../../../../../../common/Button";
import {Icon} from "../../../../../../common/Icon";
import {useLocation, useNavigate} from "react-router-dom";
import {Company} from "../../../../../../../models/company/companyModel";

import "./styles.scss";

type CompanyComponentTypesCellViewProps = {
    company: Company,
    companyComponentTypes: CompanyComponentType[],
};

export function CompanyComponentTypesCellView({ company, companyComponentTypes}: CompanyComponentTypesCellViewProps) {
    const navigate = useNavigate();
    const location = useLocation();

    function openSideSheet() {
        const urlSearchParams = new URLSearchParams([["_search_origin", location.search]]);
        navigate(`/companies/${company.guid}/componentTypes?${urlSearchParams.toString()}`, {state: {company, companyComponentTypes}});
    }

    if (companyComponentTypes == null || companyComponentTypes.length === 0) {
        return (
            <Td className={"empty-company-component-type-cell-view"}>
                <span className={"empty-cell-fallback none-on-row-hovered"}>No custom component Types</span>
                <Button variant={"secondary"} className={"none-on-row-not-hovered add-custom"} onClick={openSideSheet}>
                    <Icon name={"add"}/>
                    Add custom component type
                </Button>
            </Td>
        );
    }

    const componentTypes = companyComponentTypes.map(({componentType}) => componentType);
    const visible = createPartial(componentTypes, (x) => x < 50, (sum, {name}) => sum + name.length, 0);

    return (
        <Td className={"embedded-list-cell-view"}>
            <div>
                <ul>
                    {visible.map((cm) => {
                        return (
                            <Chip key={cm.guid}>{cm.name}</Chip>
                        );
                    })}
                    {visible.length < componentTypes.length && (
                        <li className={"more-entities"}> +{componentTypes.length - visible.length} </li>
                    )}
                </ul>
                <Button variant={"tertiary"} className={"show-on-row-hovered"} onClick={openSideSheet}>
                    <Icon name={"menu_open"}/>
                </Button>
            </div>
        </Td>
    );
}

