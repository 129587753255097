import * as React from "react";
import {Table, Th} from "../../../../../common/DataTable";
import {Dialog} from "../../../../../common/Dialog";
import {AbstractComponentBrandsProvider} from "./AbstractComponentBrandsContext";
import {AbstractMediaProvider} from "./AbstractMediasContext";
import {Footer} from "./Footer";
import {useComponentModelCompaniesModalView} from "./hooks";
import {CompanyComponentModelsRowsView} from "./RowsView";
import {CompanySearch} from "./SearchCompany";
import {FilterView} from "../../../../../common/FilterView";
import {FilterState} from "../../types";
import {byEntityName} from "../../../../../../utils/arrays";
import {OptionLegacy} from "../../../../../common/form/OptionLegacy";
import {similar} from "../../../../../../utils/strings";
import {consumeTargetValue} from "../../../../../../utils/eventsHandling";

import "./styles.scss";
import {Skeleton} from "../../../../../common/Skeleton";

interface ComponentModelCompaniesModalViewProps {
    open: boolean;
    onClose(): void;
}

export function ComponentModelCompaniesModalView({open, onClose}: ComponentModelCompaniesModalViewProps) {
    const {companiesComponentModels, loading, componentModel} = useComponentModelCompaniesModalView(open);

    const [companyFilterName, setCompanyFilterName] = React.useState("");

    const [companyFilterState, setCompanyFilterState] = React.useState<FilterState<string>>({
        selected: [],
        sortDirection: "none",
    });

    const companies = companiesComponentModels
        .toArray()
        .map(([_, ccm]) => ccm?.[0]?.company)
        .filter(Boolean);

    return (
        <Dialog
            open={open}
            className={"component-model-connected-entities-dialog component-model-companies-modal-view"}
        >
            <header tabIndex={-1}>
                <hgroup>
                    <small>{componentModel.name}</small>
                    <h3>Companies</h3>
                </hgroup>
                <CompanySearch
                    className={"company-search"}
                    componentType={{guid: componentModel.typeGuid}}
                    companiesComponentModels={companiesComponentModels}
                />
            </header>
            <section>
                <Table>
                    <thead>
                        <tr>
                            <Th ordinal={"first"}>
                                Company
                                <FilterView value={companyFilterState} onChange={setCompanyFilterState}>
                                    <section className={"options-filter-section"}>
                                        <input
                                            type={"text"}
                                            value={companyFilterName}
                                            className={"input-field"}
                                            placeholder={"Search"}
                                            onChange={consumeTargetValue(setCompanyFilterName)}
                                        />
                                    </section>
                                    {companies
                                        .filter((c) => similar(c.name, companyFilterName))
                                        .sort(byEntityName)
                                        .map((c) => {
                                            return (
                                                <OptionLegacy key={c.guid} value={c.guid}>
                                                    {c.name}
                                                </OptionLegacy>
                                            );
                                        })
                                    }
                                </FilterView>
                            </Th>
                            <Th> Branding </Th>
                            <Th> Client's name</Th>
                            <Th> Client's code </Th>
                            <Th> Image </Th>
                            <Th> Nickname </Th>
                            <Th> Number of Components </Th>
                            <Th ordinal={"last"}/>
                        </tr>
                    </thead>
                    <tbody>
                        <AbstractMediaProvider>
                            <AbstractComponentBrandsProvider>
                                {loading
                                    ? Array.from(
                                        {length: 10},
                                        (_, i) => <Skeleton key={i} type={"table-row"} size={8}/>
                                    ) : companiesComponentModels.toArray()
                                        .filter(([companyGuid]) => {
                                            return companyFilterState.selected.includes(companyGuid)
                                                || companyFilterState.selected.length === 0;
                                        })
                                        .map(([companyGuid, ccmList]) => (
                                            <CompanyComponentModelsRowsView
                                                key={companyGuid}
                                                companyComponentModels={ccmList}
                                            />
                                        ))}
                            </AbstractComponentBrandsProvider>
                        </AbstractMediaProvider>
                    </tbody>
                </Table>
            </section>
            <Footer onClose={onClose}/>
        </Dialog>
    );
}
