import {useQueryParams} from "../../../../../../QueryParams";
import {Button} from "../../../../../../common/Button";
import {Icon} from "../../../../../../common/Icon";
import * as React from "react";

import "./styles.scss";

type SortOrder = "asc" | "desc";
type SortValue<K extends string> = `${K},${SortOrder}`;
type FilterColumOrderViewProps<K extends string> = React.HTMLProps<HTMLFieldSetElement> & {
    columnKey: K
}
type SortQueryParamType<K extends string> = {
    page: 0,
    sort: SortValue<K>
};

export function FilterColumnOrderView<K extends string>({columnKey, className}: FilterColumOrderViewProps<K>) {
    const {params, update, remove} = useQueryParams<SortQueryParamType<typeof columnKey>>();
    function setOrder(order: SortOrder) {
        return () => {
            const newSortValue: SortValue<K> = `${columnKey},${order}`;
            if (newSortValue !== params?.sort) {
                update({page: 0, sort: newSortValue});
            } else {
                remove("sort");
            }
        }
    }

    const isAscending = params?.sort === `${columnKey},asc`;
    const isDescending = params?.sort === `${columnKey},desc`;

    return (
        <fieldset className={`filter-column-order-view ${className ?? ""}`}>
            <legend>Order</legend>
            <Button variant={"toggle"} on={isAscending} type={"button"} onClick={setOrder("asc")}>
                <Icon name={"arrow_upward"}/>
                Ascending
            </Button>
            <Button variant={"toggle"} on={isDescending} type={"button"} onClick={setOrder("desc")}>
                <Icon name={"arrow_downward"}/>
                Descending
            </Button>
        </fieldset>
    );
}