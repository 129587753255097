import * as React from "react";
import {StatelessStream} from "../hooks/observables";


export type DataState<T, E = unknown> = {
   state: "loading" | "successful" | "error",
   data: T,
   error: E,
};

type DatastoreContextType = {
   datastore: Map<string, DataState<unknown>>;
   stream: StatelessStream<[string, DataState<unknown>]>
};

export const DatastoreContext = React.createContext<DatastoreContextType>(null);