import * as React from "react";

export function createSlots<D extends object>(
    children: React.ReactNode,
    definition: D
): Record<keyof D, React.ReactElement> {
    const slots = {} as Record<keyof D, React.ReactElement>;

    const mapper = new Map(
        Object.entries(definition).flatMap(([key, value]) => {
            if (value instanceof Array) {
                return value.map((v) => [v, {key, unique: false}]);
            }
            return [[value, {key, unique: true}]];
        })
    );

    React.Children.forEach(children, (child) => {
        if (React.isValidElement(child) && mapper.has(child.type)) {
            const {key, unique} = mapper.get(child.type);
            if (unique) {
                slots[key] = child;
            } else {
                slots[key] = (slots[key] instanceof Array ? slots[key] : []).concat(child);
            }
        } else if (mapper.has("*")) {
            const {key} = mapper.get("*");
            slots[key] = (slots[key] instanceof Array ? slots[key] : []).concat(child);
        }
    });


    return slots;
}