import * as React from "react";
import {Tr} from "../../../../../../common/DataTable";

type CcmRowViewProps = React.PropsWithChildren<{
    className: string;
    state: "default" | "added" | "removed";
}>;

export function CcmRowView({className, state, children}: CcmRowViewProps) {

    const trRef = React.useRef<HTMLTableRowElement>();

    React.useEffect(() => {
        if (state === "added") {
            trRef.current.scrollIntoView({block: "nearest"});
            trRef.current.focus();
        }
    }, []);

    return (
        <Tr className={className} state={state} ref={trRef} tabIndex={-1}>
            {children}
        </Tr>
    );
}
