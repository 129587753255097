import {PickGuid} from "../../../models/types";
import {Company} from "../../../models/company/companyModel";
import {CompanyComponentType} from "../../../models/component/componentType";
import {assertOk} from "../../../utils/requests";
import {JSONList} from "../../../models/utils/jsonList";

export async function updateCompanyComponentTypes(
    company: PickGuid<Company>,
    companyComponentTypes: Array<Partial<CompanyComponentType>>
): Promise<JSONList<CompanyComponentType>> {
    const response = await fetch(
        `/companies/${company.guid}/componentTypes`,
        {
            method: "PUT",
            body: JSON.stringify({
                list: companyComponentTypes,
            })
        },
    );
    assertOk(response);
    return response.json();
}