import * as React from "react";

import "./styles.scss";

type InputProps = React.HTMLProps<HTMLInputElement>;
export function InputDef({ className, ...inputProps}: InputProps, ref: React.ForwardedRef<HTMLInputElement>) {

    const inputRef = React.useRef<HTMLInputElement>();

    React.useImperativeHandle(ref, () => inputRef.current, []);

    return (
        <input ref={inputRef} className={`input-component ${className ?? ""}`} {...inputProps}/>
    );
}

export const Input = React.forwardRef(InputDef);