import {Button, Checkbox, SelectInput, TextInput} from "@danfoss/etui-core";
import * as React from "react";
import {Col, Row} from "react-bootstrap";
import Services from "../../../controllers/utils/Services";
import {
    AlarmTypeSeveritySuggestions,
    CompanyAlarmType,
    TableCompanyAlarmType,
} from "../../../models/alarm/alarmType";
import RowColorPickerModal from "./RowColorPickerModal";

interface CompanyAlarmTypeRowProps {
    value: TableCompanyAlarmType;
    setShowTranslationModal: (show: boolean) => void;
    setCompanyAlarmTypeToTranslate: (item: CompanyAlarmType) => void;
    setSelectedTranslation: (t: string) => void;
    setAllCheckedStatus: (allChecked: boolean, indeterminate: boolean) => void;
    tableAlarmTypes: TableCompanyAlarmType[];
    setTableAlarmTypes: (items: TableCompanyAlarmType[]) => void;
    selectedTrigger: boolean;
    updated: Array<{ item: string, status: boolean }>;
    setUpdated: (items: Array<{ item: string, status: boolean }>) => void;
    severitySuggestions;
}

const CompanyAlarmTypeRow: React.FC<CompanyAlarmTypeRowProps> = (props) => {
    const services = new Services();

    const [checked, setChecked] = React.useState<boolean>(true);
    const [displayColorPicker, setDisplayColorPicker] = React.useState<boolean>(false);
    const [color, setColor] = React.useState<string>(props.value.color);
    const [selectedColor, setSelectedColor] = React.useState<{ r: string, g: string, b: string, a: string }>(
        props.value.selectedColor);
    const [description, setDescription] = React.useState(props.value.description);
    const [severity, setSeverity] = React.useState<AlarmTypeSeveritySuggestions>(
        props.value.severity != null ? props.value.severity : undefined);

    function setCheckedWrapper() {
        return (t) => {
            setChecked(t.target.checked);
            const hypotheticalList = props.tableAlarmTypes.map((e) => {
                if (e.item.guid === props.value.item.guid) {
                    e.checked = t.target.checked;
                }
                return e;
            });
            props.setAllCheckedStatus(
                hypotheticalList.every((e) => e.checked),
                !hypotheticalList.every((e) => e.checked) && hypotheticalList.some((e) => e.checked),
            );
        };
    }

    function setSeverityWrapper() {
        return (t) => {
            setSeverity(t);
        };
    }

    React.useEffect(() => {
        if (props.updated != null) {
            if (props.updated.length > 0 && props.tableAlarmTypes.length > 0) {
                if (!props.updated.every((e) => e.status === true)) {
                    if (props.updated.map((e) => e.item).indexOf(props.value.item.alarmType.guid) === 0 ||
                        (!props.updated[props.updated.map((e) => e.item)
                                .indexOf(props.value.item.alarmType.guid)].status &&
                            props.updated[props.updated.map(
                                (e) => e.item).indexOf(props.value.item.alarmType.guid) - 1].status)) {
                        props.setTableAlarmTypes(props.tableAlarmTypes.map((item) => {
                            if (item.item.alarmType.guid === props.value.item.alarmType.guid) {
                                item.checked = checked;
                                item.color = color;
                                item.selectedColor = selectedColor;
                                item.description = description;
                                item.severity = severity;
                            }
                            return item;
                        }));
                        const newList = props.updated.map((e) => {
                            if (e.item === props.value.item.alarmType.guid) {
                                e.status = true;
                            }
                            return e;
                        });
                        console.log(newList);
                        props.setUpdated(newList);
                    }
                }
            }
        }
    }, [props.tableAlarmTypes.indexOf(props.value) === 0 ? props.selectedTrigger : props.updated]);
    React.useEffect(() => {
        setChecked(props.value.checked);
        setDescription(props.value.description);
    }, [props.value, props.tableAlarmTypes]);
    return (
        <tr key={props.value.item.guid}>
            <td style={{verticalAlign: "middle"}}>
                <Row style={{textAlign: "center"}}>
                    <Col>
                        <Checkbox
                            checked={checked}
                            onChange={setCheckedWrapper()}
                        />

                    </Col>
                </Row>
            </td>
            <td style={{verticalAlign: "middle"}}>
                <Row style={{justifyContent: "space-evenly"}}>
                    <Col style={{maxWidth: "90%"}}>
                        <b>{props.value.item.alarmType.name}</b>
                        <p>
                            {props.value.item.alarmType.category.name}
                        </p>
                    </Col>
                    <Col className={"px-0 my-auto"} style={{maxWidth: "10%"}}>
                        <Button
                            className={"mx-auto"}
                            disabled={props.value.item.alarmType.nameKey == null}
                            onClick={services.setTranslateModalWrapper(
                                props.setShowTranslationModal,
                                props.setCompanyAlarmTypeToTranslate,
                                props.value.item,
                                props.setSelectedTranslation,
                                "name",
                            )}
                        >
                            {services.addTranslateButton()}
                        </Button>
                    </Col>
                </Row>
            </td>
            <td style={{verticalAlign: "middle"}}>
                <Row style={{justifyContent: "space-evenly"}}>
                    <Col style={{maxWidth: "90%"}}>
                        <p>{props.value.item.alarmType.description != null ?
                            props.value.item.alarmType.description : (
                                <span style={{color: "red"}}>
                                    This Alarm type has no description
                                </span>
                            )}
                        </p>
                    </Col>
                    <Col className={"px-0"} style={{maxWidth: "10%"}}>
                        <Button
                            className={"mx-auto"}
                            disabled={props.value.item.alarmType.description == null}
                            onClick={services.setTranslateModalWrapper(
                                props.setShowTranslationModal,
                                props.setCompanyAlarmTypeToTranslate,
                                props.value.item,
                                props.setSelectedTranslation,
                                "description",
                            )}
                        >
                            {services.addTranslateButton()}
                        </Button>
                    </Col>
                </Row>
            </td>
            <td style={{verticalAlign: "middle"}}>
                <RowColorPickerModal
                    color={color}
                    displayColorPicker={displayColorPicker}
                    selectedColor={selectedColor}
                    setColor={setColor}
                    setDisplayColorPicker={setDisplayColorPicker}
                    setSelectedColor={setSelectedColor}
                />
            </td>
            <td style={{verticalAlign: "middle"}}>
                <SelectInput
                    searchable={true}
                    name="Severity"
                    value={severity}
                    styles={{
                        input: {color: (services.colorFromSeverity(severity.label))},
                    }}
                    options={props.severitySuggestions}
                    onChange={setSeverityWrapper()}
                />
            </td>
            {/*<td style={{verticalAlign: "middle"}}>*/}
            {/*    <TextInput/>*/}
            {/*</td>*/}
        </tr>
    );
};

export default CompanyAlarmTypeRow;
