import * as React from "react";
import {CompanyAlarmType} from "../../../../../../models/alarm/alarmType";
import {Td} from "../../../../../common/DataTable";
import {Chip} from "../../../../../common/chips";
import {toMap} from "../../../../../../utils/maps";
import {createPartial} from "../../../../../../utils/arrays";
import {Button} from "../../../../../common/Button";
import {Icon} from "../../../../../common/Icon";

type CompanyAlarmTypesCellViewProps = {
    companyAlarmTypes: CompanyAlarmType[],
}

export function CompanyAlarmTypesCellView({companyAlarmTypes}: CompanyAlarmTypesCellViewProps) {

    if (companyAlarmTypes == null || companyAlarmTypes.length === 0) {
        return (
            <Td><span className={"empty-cell-fallback"}>No alarm types</span></Td>
        );
    }

    const alarmTypes = companyAlarmTypes.map(({alarmType}) => alarmType);
    const visible = createPartial(alarmTypes, (x) => x < 50, (sum, {name}) => sum + name.length, 0);

    return (
        <Td className={"embedded-list-cell-view"}>
            <div>
                <ul>
                    {visible.map((at) => {
                        return (
                            <Chip key={at.guid}>{at.name}</Chip>
                        );
                    })}
                    {visible.length < alarmTypes.length && (
                        <li className={"more-entities"}> +{alarmTypes.length - visible.length} </li>
                    )}
                </ul>
                <Button value={"tertiary"} className={"show-on-row-hovered"}>
                    <Icon name={"menu_open"}/>
                </Button>
            </div>
        </Td>
    );
}