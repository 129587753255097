import * as React from "react";
import {Td} from "../../common/DataTable";
import {LocationCore} from "../../../models/location/location";

import "./style.scss";
import {useLocation, useNavigate} from "react-router-dom";
import {Company} from "../../../models/company/companyModel";

type LocationCellViewProps = React.HTMLAttributes<HTMLTableDataCellElement> & {
    company: Company;
    location: LocationCore;
    fallback: React.ReactNode;
};

export function LocationCellView({ company, location, fallback, ...tdProps}: LocationCellViewProps) {

    if (location == null) {
        return (
            <Td {...tdProps}>{fallback}</Td>
        );
    }

    if (location.description == null) {
        return (
            <Td {...tdProps}>{location.address.fullAddress}</Td>
        );
    }

    return (
        <Td {...tdProps} className={`location-cell-view-with-description ${tdProps.className ?? ""}`}>
            <p>{location?.description}</p>
            <small>{location.address.fullAddress}</small>
        </Td>
    );
}