import * as React from "react";
import {CompanyItemView} from "../../../../../../common/CompanyItemView";
import {Th} from "../../../../../../common/DataTable";
import {
    useComponentModelChangeManager,
} from "../../../contexts/ComonentModelChangesContext";
import {useComponentBrands} from "../../../contexts/ComponentBrandsProvider";
import {useComponentModel} from "../../../contexts/ComponentModelProvider";
import {AbstractCompanyComponentModel} from "../../types";
import {isVirtualCompanyComponentModel} from "../../utils";
import {AlreadyUsedComponentBrandsProvider} from "./AlreadyUsedComponentBrandsContext";
import {CcmRowView} from "./CcmRowView";
import {CompanyComponentModelCellsView} from "./CellsView";

import "./styles.scss";
import {Button} from "../../../../../../common/Button";
import {Icon} from "../../../../../../common/Icon";

interface CompanyComponentModelsRowsViewProps {
    companyComponentModels: AbstractCompanyComponentModel[];
}

export function CompanyComponentModelsRowsView({companyComponentModels}: CompanyComponentModelsRowsViewProps) {

    const componentModel = useComponentModel();
    const {firstAvailableBrand} = useComponentBrands();

    const company = companyComponentModels?.[0].company;
    const changesManager = useComponentModelChangeManager();

    function add() {
        const alreadyUsedComponentBrandsGuids = companyComponentModels.map((ccm) => ccm?.componentBrand?.guid ?? null);
        changesManager.addToCompany(company, firstAvailableBrand(...alreadyUsedComponentBrandsGuids));
    }

    return (
        <AlreadyUsedComponentBrandsProvider company={company} companyComponentModels={companyComponentModels}>
            {companyComponentModels.map((ccm, index) => {
                const entityState =
                    isVirtualCompanyComponentModel(ccm)
                        ? ccm.__virtual === "add" ? "added" : "removed"
                        : "default";

                const rowKey = `${ccm.company.guid}-${componentModel.guid}-${ccm.componentBrand?.guid}`;
                if (index === 0) {
                    return (
                        <CcmRowView key={rowKey} className={"company-component-models-row-view"} state={entityState}>
                            <Th scope={"row"} rowSpan={companyComponentModels.length}>
                                <div className={"company-component-models-row-head-content-view"}>
                                    <CompanyItemView company={ccm.company}/>
                                    <Button variant={"tertiary"} onClick={add}>
                                        <Icon name={"add"}/>
                                    </Button>
                                </div>
                            </Th>
                            <CompanyComponentModelCellsView companyComponentModel={ccm}/>
                        </CcmRowView>
                    );
                }
                return (
                    <CcmRowView key={rowKey} className={"company-component-models-row-view"} state={entityState}>
                        <CompanyComponentModelCellsView companyComponentModel={ccm}/>
                    </CcmRowView>
                );
            })}
        </AlreadyUsedComponentBrandsProvider>
    );
}
