import * as React from "react";
import {Button} from "../Button";
import {clamp, range} from "../../../utils/numbers";
import {constant, doNothing} from "../../../utils/functions";
import {Icon} from "../Icon";

import "./style.scss";


type PaginationProps = {
    value: number;
    first: number;
    last: number;
    width: number;
    onChange?(newValue: number):void;
};

export function Pagination({ value, first, last, width: providedWidth, onChange = doNothing}: PaginationProps) {

    const width = Math.min(providedWidth, last - first);

    const showLeftEllipsis = value - first >= width - 3;
    const showRightEllipsis = last - value >= width - 3;

    const left = showLeftEllipsis ? value - Math.floor((width - 3) / 2) : first + 1;


    // todo convert button variant to toggle

    return (
        <ul className={"pagination"}>
            <li>
                <Button
                    variant={"tertiary"}
                    onClick={constant(onChange, clamp(first, last, value - 1))}
                    disabled={value === first}
                >
                    <Icon name={"arrow_back"}/>
                </Button>
            </li>
            <li className={"pagination-option"}>
                <Button variant={value === first ? "secondary" : "tertiary"} onClick={constant(onChange, first)}>
                    {first}
                </Button>
            </li>
            {range(left, left + width - (width === providedWidth ? 2 : 1)).map((page, i) => {
                if ((showLeftEllipsis && i === 0 || showRightEllipsis && i == width - 3) && (width === providedWidth)) {
                    return <li key={i} className={"pagination-ellipsis"}>...</li>;
                }
                const btnVariant = value === page ? "secondary": "tertiary";
                return (
                    <li key={i} className={"pagination-option"}>
                        <Button key={i} variant={btnVariant} onClick={constant(onChange, page)}>
                            {page}
                        </Button>
                    </li>
                );
            })}
            <li className={"pagination-option"}>
                <Button variant={value === last ? "secondary" : "tertiary"} onClick={constant(onChange, last)}>
                    {last}
                </Button>
            </li>
            <li>
                <Button
                    variant={"tertiary"}
                    onClick={constant(onChange, clamp(first, last, value + 1))}
                    disabled={value === last}
                >
                    <Icon name={"arrow_forward"}/>
                </Button>
            </li>
        </ul>
    );
}