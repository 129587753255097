import * as React from "react";

type UseSyntheticEventEmitterProps = {
    type: "change" | "input" | "click",
    eventInitDict?: EventInit
}

export function useSyntheticEventEmitter({type, eventInitDict}: UseSyntheticEventEmitterProps) {
    const inputRef = React.useRef<HTMLInputElement>();
    function emit() {
        const e = new Event(type, eventInitDict);
        inputRef.current.dispatchEvent(e);
    }

    return {inputRef, emit}
}