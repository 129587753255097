import * as React from "react";
import {Button} from "../../../../../../common/Button";
import {Icon} from "../../../../../../common/Icon";
import {Th} from "../../../../../../common/DataTable";
import {usePopover} from "../../../../../../../hooks/usePopover";
import {useAnchor} from "../../../../../../../hooks/useAnchor";
import {useQueryParams} from "../../../../../../QueryParams";
import {preventDefault} from "../../../../../../../utils/eventsHandling";

import "./styles.scss";

type FilterViewProps = React.PropsWithChildren<{
    heading: React.ReactNode,
    onReset?: React.FormEventHandler<HTMLFormElement>,
    onApply?: React.FormEventHandler<HTMLFormElement>,
    ordinal?: "first" | "last" | number,
}>;

export function ThWithFilterView({ heading, onApply, onReset, children, ordinal}: FilterViewProps) {

    const {anchorRef, anchor} = useAnchor<HTMLTableHeaderCellElement>();
    const {popoverRef, popoverId: id, hide} = usePopover({
        position: {
            top: anchor("bottom"),
            right: anchor("right"),
        }
    });

    const {params} = useQueryParams();

    React.useEffect(() => {
        hide()
    }, [params]);

    const applicable = onApply != null && onReset != null;

    return (
        <Th ref={anchorRef} ordinal={ordinal} className={"column-headed-with-filter"}>
            <div>
                {heading}
                {/* @ts-ignore:disable-next-line  */}
                <Button popovertarget={id}>
                    <Icon name={"keyboard_arrow_down"}/>
                </Button>
                {/* @ts-ignore:disable-next-line  */}
                <div ref={popoverRef} popover={"auto"} id={id} className={"table-column-filter"}>
                    <form onSubmit={onApply} onReset={preventDefault(onReset)}>
                        {children}
                        {(applicable == null || applicable) && (
                            <footer>
                                {/* @ts-ignore:disable-next-line*/}
                                <Button popovertarget={id} popovertargetaction={"hide"} variant={"tertiary"} type={"reset"}>
                                    Reset All
                                </Button>
                                {/* @ts-ignore:disable-next-line*/}
                                <Button popovertarget={id} popovertargetaction={"hide"} variant={"secondary"}>
                                    Apply
                                </Button>
                            </footer>
                        )}
                    </form>
                </div>
            </div>
        </Th>
    );
}