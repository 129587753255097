import * as React from "react";
import {DataState, DatastoreContext} from "./context";
import {useRefInit} from "../hooks/useRefInit";
import {useStream} from "../hooks/observables";
import {useMap} from "../hooks/useMap";


export function DatastoreProvider({children}: React.PropsWithChildren<unknown>) {

    const stream = useStream<[string, DataState<unknown>]>();

    const datastore = useMap<string, DataState<unknown>>();

    return (
        <DatastoreContext.Provider value={{datastore: datastore.ref(), stream}}>
            {children}
        </DatastoreContext.Provider>
    )
}