import {usePromise} from "../../../hooks/usePromise";
import {findCompanies,} from "../api";
import {useLocation, useSearchParams} from "react-router-dom";
import * as React from "react";

type CompanyRequestParams = Parameters<typeof findCompanies>[0];
export function usePageNavigation() {
    const {data, state, refresh} = usePromise(async (params?: CompanyRequestParams) => {
        return findCompanies({
            ...params,
            embeds: [
                "MARKETING_CONFIGURATION_ENABLED",
                "INTEGRATION_FLOW_ENABLED",
                "LOCK_ENABLED",
                "GENERIC_PRODUCT_ENABLED",
                "DEFAULT_WAREHOUSE",
                "COMPANY_COMPONENT_TYPES",
                "COMPANY_ALARM_TYPES",
                "COMPANY_COMPONENT_MODELS",
            ]});
    }, [], {callOnMount: false},);

    const [searchParams] = useSearchParams();

    React.useEffect(() => {
        if (state === "fulfilled" && searchParams.has("_search_origin")) {
            return;
        }

        const requestSearchParams = searchParams.has("_search_origin")
            ? new URLSearchParams(searchParams.get("_search_origin"))
            : searchParams;

        const parameter: CompanyRequestParams = requestSearchParams.size === 0
            ? {page: 0, size: 20, sort: ["createdAt,desc"]} // Fixme
            : companiesFilterFromSearchParams(requestSearchParams);


        refresh([parameter]);
    }, [searchParams]);

    return {data, state};
}

function companiesFilterFromSearchParams(searchParams: URLSearchParams): CompanyRequestParams{
    return {
        page: Number(searchParams.get("page")),
        size: 20, // Standard page size // todo make it variable
        sort: searchParams.getAll("sort"),
        criteria: {
            guids: searchParams.getAll("company"),
            name: searchParams.get("searchTerm"),
            usePartialName: searchParams.has("searchTerm") || null,
            isParentCompany: searchParams.get("relation") === "child" ? false
                : searchParams.get("relation") === "parent" ? true
                : null,
            countriesISO3: searchParams.getAll("country"),
            code: searchParams.get("code"),
            usePartialCode: searchParams.has("code") || null,
            withoutCode: searchParams.get("withCode") === "true" ? true
                : searchParams.get("withCode") === "false" ? false
                : null,
            parentCompanies: {
                guids: searchParams.getAll("parent"),
                name: searchParams.get("parentName"),
                usePartialName: searchParams.has("parentName") || null,
            },
            currencies: {
                guids: searchParams.getAll("currency")
            },
            types: {
                guids: searchParams.getAll("type")
            },
            companyComponentTypes: {
                types: {
                    guids: searchParams.getAll("componentType"),
                    name: searchParams.get("componentTypeSearchTerm"),
                    usePartialName: searchParams.has("componentTypeSearchTerm")  || null,
                    categories: {
                        guids: searchParams.getAll("componentCategory"),
                        isEquipment: searchParams.get("componentCategoryType") === "equipment" ? true
                            : searchParams.get("componentCategoryType") === "non-equipment" ? false
                            : null,
                    }
                },
            },
            companyComponentModels: {
                brands: {
                    guids: searchParams.getAll("componentBrand"),
                    description: searchParams.get("componentBrandName"),
                    usePartialDescription: searchParams.has("componentBrandName") || null,
                },
                models: {
                    guids: searchParams.getAll("componentModel"),
                    name: searchParams.get("componentModelName"),
                    usePartialName: searchParams.has("componentModelName") || null,
                    isGeneric: searchParams.get("componentModelGeneric") === "true" ? true
                        : searchParams.get("componentModelGeneric") === "false" ? false
                        : null,
                },
            },
            companyAlarmTypes: {
                types: {
                    guids: searchParams.getAll("alarmType"),
                    name: searchParams.get("alarmTypeName"),
                    usePartialName: searchParams.has("alarmTypeName") || null,
                    severities: {
                        guids: searchParams.getAll("alarmTypeSeverity"),
                    },
                    categories: {
                        guids: searchParams.getAll("alarmCategory"),
                    },
                },
            },
            createdAfter: searchParams.get("createdAfter"),
            createdBefore: searchParams.get("createdBefore"),
        },
    };
}