import * as React from "react";

type CurrencyIconName =
    "euro_symbol"
    | "currency_lira"
    | "currency_pound"
    | "currency_yuan"
    | "currency_franc"
    | "currency_yen"
    | "currency_ruble"
    | "currency_rupee"
    | "attach_money" // This should be the dollar
    ;

type IconName =
    CurrencyIconName
    | "edit"
    | "filter_list" | "filter_alt"
    | "select_window"
    | "undo"
    | "unfold_more" | "unfold_less"
    | "open_in_full"
    | "pan_zoom"
    | "expand_content"
    | "open_in_new"
    | "add"
    | "add_circle"
    | "delete"
    | "backspace"
    | "first_page" | "last_page"
    | "chevron_left" | "chevron_right"
    | "close"
    | "arrow_upward" | "arrow_downward" | "arrow_back" | "arrow_forward"
    | "warehouse"
    | "factory"
    | "pin_drop"
    | "arrow_drop_down"
    | "radio_button_unchecked" | "radio_button_checked"
    | "check_box" | "check_box_outline_blank" | "indeterminate_check_box"
    | "menu_open"
    | "keyboard_arrow_down";

type IconProps = React.HTMLProps<HTMLSpanElement> &  {
    name: IconName;
};

export function Icon({name, className, ...props}: IconProps) {
    return (
        <span {...props} className={`icon material-symbols-outlined ${className ?? ""}`}>{name}</span>
    );
}
