import {ComponentTypesFilter} from "../../Components";
import {assertOk, toRequestParamsString} from "../../../utils/requests";
import {JSONList} from "../../../models/utils/jsonList";
import {CompanyType} from "../../../models/company/companyTypeModel";


export async function findCompanyTypes(criteria?: ComponentTypesFilter): Promise<JSONList<CompanyType>> {
    const paramString: string = toRequestParamsString(criteria);
    const response = await fetch(`/companies/types?${paramString}`);
    assertOk(response);
    return response.json();
}