import * as React from "react";
import {ThWithFilterView, FilterMultiValueSelectView, FilterCheckboxChipGroupView, FilterRadioChipGroupView} from "../filters";
import {ComponentType} from "../../../../../../models/component/componentType";
import {findComponentTypes} from "../../../../../Components/Types/api";
import {useQueryParams} from "../../../../../QueryParams";
import {useData, useDatastore} from "../../../../../../DataLoader";
import {findComponentCategories} from "../../../../../Components/Categories/api";
import {useForm} from "../../../../../../Form";
import {LOAD_ITEM_COUNT, toArray} from "../common";
import {pickGuid} from "../../../../../../utils/objects";
import {Chip} from "../../../../../common/chips";
import {Input} from "../../../../../common/form/Input";
import {CategoryType, CompanyComponentTypesQueryParamsType} from "./types";
import {COMPONENT_CATEGORIES_DATA_KEY, COMPONENT_TYPES_DATA_KEY} from "./const";

type FilterForm = {
    categories: string[],
    categoryType: CategoryType,
    values: ComponentType[],
    valueName: string,
    specific: boolean,
};

export function CompanyComponentTypeColumnHeaderView() {
    const datastore = useDatastore();
    const {params, update} = useQueryParams<CompanyComponentTypesQueryParamsType>();
    const categories = useData({loader: findComponentCategories, keys: [COMPONENT_CATEGORIES_DATA_KEY]});

    const form = useForm<FilterForm>({
        defaultValues: async () => {
            const componentTypesGuids = toArray(params.componentType);

            if (componentTypesGuids.length > 0) {
                const componentTypes = await findComponentTypes({
                    page: 0,
                    size: componentTypesGuids.length,
                    criteria: {
                        guids: componentTypesGuids,
                    },
                });
                return {
                    categories: toArray(params.componentCategory),
                    categoryType: params.componentCategoryType ?? "any",
                    values: componentTypes.list,
                    valueName: "",
                    specific: true,
                };
            }

            return {
                categories: toArray(params.componentCategory),
                categoryType: params.componentCategoryType ?? "any",
                values: [],
                valueName: params.componentTypeName ?? "",
                specific: params.componentTypeName == null,
            };
        },
    });


    function apply(data: FilterForm) {
        datastore.set([COMPONENT_TYPES_DATA_KEY], {list: data.values});
        update({
            page: 0,
            componentType: data.specific ? data.values.map(pickGuid) : null,
            componentTypeName: data.specific ? null : data.valueName,
            componentCategory: data.categories,
            componentCategoryType: data.categoryType === "any" ? null : data.categoryType,
        });
    }
    function resetAll() {
        form.reset({
            categories: [],
            categoryType: "any",
            values: [],
            valueName: "",
            specific: true,
        });
        update({
            page: 0,
            componentType: [],
            componentTypeName: null,
            componentCategory: [],
            componentCategoryType: null
        });
    }

    return (
        <ThWithFilterView heading={"Component types"} onApply={form.handleSubmit(apply)} onReset={resetAll}>
            <FilterRadioChipGroupView legend={"Component category types"}>
                <Chip>
                    <Input type={"radio"} value={"any"} {...form.register("categoryType")}/>
                    All
                </Chip>
                <Chip>
                    <Input type={"radio"} value={"non-equipment"} {...form.register("categoryType")}/>
                    Non-equipment
                </Chip>
                <Chip>
                    <Input type={"radio"} value={"equipment"} {...form.register("categoryType")}/>
                    Equipment
                </Chip>
            </FilterRadioChipGroupView>
            {categories.state === "successful" && (
                <FilterCheckboxChipGroupView legend={"Component categories"}>
                    {categories.data.list.map(({guid, name}) => {
                        return (
                            <Chip key={guid}>
                                <Input type={"checkbox"} value={guid} {...form.register("categories")}/>
                                {name}
                            </Chip>
                        );
                    })}
                </FilterCheckboxChipGroupView>
            )}
            <FilterMultiValueSelectView legend={"Component types"} form={form as any} listLoader={componentTypeLoader}/>
        </ThWithFilterView>
    )
}

function componentTypeLoader(page: number, name: string) {
    return findComponentTypes({
        page,
        size: LOAD_ITEM_COUNT,
        sort: ["name,asc"],
        criteria: {
            name,
            usePartialName: true,
        },
    });
}

