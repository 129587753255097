import * as React from "react";
import {useDebounceCallback} from "./useDebounceCallback";
import {JSONEntitiesCollection} from "../models/utils/jsonList";

type Actions<I, O> =
    | {type: "update-input", input: I}
    | { type: "fetch-options", input: I }
    | { type: "load-more-options" }
    | { type: "append-options", options: O[], total: number };

type State<I, O> = {
    input: I,
    loading: boolean,
    page: number,
    total: number,
    list: O[],
};

function optionsReducer<I, O>(state: State<I, O>, action: Actions<I, O>): State<I, O> {
    switch (action.type) {
        case "update-input": {
            return {
                ...state,
                input: action.input,
            }
        }
        case "fetch-options": {
            return {
                ...state,
                input: action.input,
                loading: true,
                page: 0,
                total: 0,
                list: [],
            };
        }
        case "load-more-options": {
            return {
                ...state,
                page: state.page + 1,
                loading: true,
            };
        }
        case "append-options": {
            return {
                ...state,
                loading: false,
                list: state.list.concat(action.options),
                total: action.total,
            };
        }
    }
}

type MultiselectOptionsLoaderProps<I, V> = {
    loader(state: State<I, V>): Promise<Omit<JSONEntitiesCollection<V>, "links" | "count">>,
    initialInput?: I,
    loadOnMount?: boolean;
};

export function useLazyList<I, V>({loader, initialInput, loadOnMount}: MultiselectOptionsLoaderProps<I, V>) {
    const [state, dispatch] = React.useReducer((optionsReducer<I, V>), {
        input: initialInput,
        loading: loadOnMount ?? true,
        page: 0,
        total: 0,
        list: [],
    });


    React.useEffect(() => {
        if (!state.loading) {
            return;
        }

        loader(state).then(({list, total}) => {
            dispatch({type: "append-options", options: list, total});
        });

    }, [state.loading]);


    function reload(input: I) {
        dispatch({type: "fetch-options", input});
    }

    function loadMore() {
        dispatch({type: "load-more-options"});
    }

    function hasMore() {
        return state.list.length < state.total;
    }

    return {...state, hasMore, reload, loadMore};
}
