import {JSONList, JSONPage} from "../../models/utils/jsonList";
import {Company, CompanyComponentModel} from "../../models/company/companyModel";
import {LocationCore} from "../../models/location/location";
import {assertOk, toRequestParamsString} from "../../utils/requests";
import {Pageable, PickGuid} from "../../models/types";
import {CompanyAlarmType} from "../../models/alarm/alarmType";
import {CompanyComponentType} from "../../models/component/componentType";
import {toJSONPatch} from "../../utils/json";
import {flatten, omit} from "../../utils/objects";
import {CompaniesFilter} from "./types";

// type FindCompaniesParams =
//     { componentTypeGuid: string } |
//     { companyRelationType?: "ALL" | "PARENT_COMPANIES" | "CHILD_COMPANIES", partialName?: string};
//
// type FindPaginatedCompanies = {
//     page: number,
//     size: number,
//     sort?: string[],
//     searchTerm?: string,
//     company?: string[],
// };
//
//
// export async function findCompanies(params?: FindPaginatedCompanies): Promise<JSONEntitiesCollection<Company>>;
// export async function findCompanies(params?: FindCompaniesParams): Promise<JSONEntitiesCollection<Company>>;
// export async function findCompanies(params?: unknown) {
//     const paramString: string = toRequestParamsString(params);
//     const response = await fetch(`/companies?${paramString}`);
//     assertOk(response);
//     return response.json();
// }


type Embed = "DEFAULT_WAREHOUSE"
    | "LOCK_ENABLED"
    | "INTEGRATION_FLOW_ENABLED"
    | "MARKETING_CONFIGURATION_ENABLED"
    | "GENERIC_PRODUCT_ENABLED"
    | "COMPANY_COMPONENT_TYPES"
    | "COMPANY_COMPONENT_MODELS"
    | "COMPANY_ALARM_TYPES";


type FindCompaniesParams= Partial<Pageable<{ criteria?: CompaniesFilter, embeds?: Embed[]}>>;

export async function findCompanies(params ?:FindCompaniesParams): Promise<JSONPage<Company>> {
    const {criteria, embeds, ...pagination} = params;
    const paramString: string = toRequestParamsString({...criteria, ...pagination, embeds});
    const response = await fetch(`/companies?${paramString}`);
    assertOk(response);
    return response.json();
}


interface GetCompanyLocationsParams {
    partialLocationName?: string;
    alwaysIncludeDefaultWarehouse?: boolean;
    includeDummies?: boolean;
    limit?: number;
    offset?: number;
}

export async function getCompanyLocations(
    companyGuid: string,
    params: GetCompanyLocationsParams = {},
    signal: AbortSignal = null,
): Promise<JSONList<LocationCore>> {
    const paramString: string = toRequestParamsString(params);
    const response = await fetch(`/companies/${companyGuid}/locations?${paramString}`, {signal});
    assertOk(response);
    return response.json();
}


// WAREHOUSE: 55fce2be-c7bf-11e7-ad82-525400c5f801
// FACTORY: 55fce746-c7bf-11e7-ad82-525400c5f801
// todo: should be `/locations?company=${company.guid}&category=55fce2be-c7bf-11e7-ad82-525400c5f801&category=55fce746-c7bf-11e7-ad82-525400c5f801` but endpoint doesn't exist yet
export async function findFactoriesAndWarehouses(company: PickGuid<Company>): Promise<JSONList<LocationCore>> {
    const response = await fetch(`/companies/${company.guid}/locations/factoriesAndWarehouses`);
    assertOk(response);
    return response.json();
}

export async function updateCompany(company: PickGuid<Company>, params: Partial<Company>) {
    const response = await fetch(
        `/companies/${company.guid}`,
        {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(toJSONPatch(flatten(omit(params, "guid"), "/"))),
        }
    );
    assertOk(response);
    return response.json();
}