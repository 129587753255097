import * as React from "react";
import {Chip} from "../../../../../../common/chips";

import "./styles.scss";


type FilterCheckboxChipGroupViewProps = React.HTMLProps<HTMLFieldSetElement> & { legend: React.ReactNode}

export function FilterCheckboxChipGroupView({children, legend, className}: FilterCheckboxChipGroupViewProps) {

    const chips = React.Children.map(children, (child, index) => {
        if (!React.isValidElement(child) || child.type !== Chip) {
            return null;
        }
        return (
            <li key={index}>
                {child}
            </li>
        );
    });

    return (
        <fieldset className={"filter-checkbox-chip-group-view"}>
            <legend>{legend}</legend>
            <ul className={className ?? ""}>
                {chips}
            </ul>
        </fieldset>
    );
}