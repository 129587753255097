import * as React from "react";
import {findCompanies} from "../../../../api";
import {Company} from "../../../../../../models/company/companyModel";
import {useQueryParams} from "../../../../../QueryParams";
import {ThWithFilterView, FilterMultiValueSelectView, FilterColumnOrderView, FilterRadioChipGroupView} from "../filters";
import {useForm} from "../../../../../../Form";
import {pickGuid} from "../../../../../../utils/objects";
import {LOAD_ITEM_COUNT, toArray} from "../common";
import {Input} from "../../../../../common/form/Input";
import {CompaniesQueryParamsType, CompanyRelation} from "./types";
import {useDatastore} from "../../../../../../DataLoader";
import {COMPANIES_DATA_KEY} from "./const";
import {Chip} from "../../../../../common/chips";

type FilterForm<V> = {
    valueName: string,
    specific: boolean,
    values: V[],
    relation: CompanyRelation,
};

export function CompanyNameColumnHeaderView() {
    const datastore = useDatastore();
    const { params, update } = useQueryParams<CompaniesQueryParamsType>();

    const form = useForm<FilterForm<Company>>({
        defaultValues: async () => {
            const companiesGuids = toArray(params.company);
            if (companiesGuids.length > 0) {
                const companies = await findCompanies({
                    criteria: { guids: companiesGuids },
                    page: 0,
                    size: companiesGuids.length,
                });

                datastore.set([COMPANIES_DATA_KEY], companies);

                return {
                    valueName: "",
                    values: companies.list,
                    specific: true,
                    relation: params.relation == null ? "any" : params.relation,
                }
            }

            return {
                valueName: params.searchTerm ?? "",
                specific: params.searchTerm == null,
                values: [],
                relation: params.relation == null ? "any" : params.relation,
            };
        },
    });

    function apply(data: FilterForm<Company>) {
        datastore.set([COMPANIES_DATA_KEY], {list: data.values});
        update({
            page: 0,
            searchTerm: data.specific ? null : data.valueName,
            company: data.specific ? data.values.map(pickGuid) : [],
            relation: data.relation === "any" ? null : data.relation,
        });
    }

    function resetAll() {
        form.reset({valueName: "", specific: true, values: [], relation: "any"});
        update({page: 0, searchTerm: null, company: [], relation: null});
    }

    return (
        <ThWithFilterView ordinal={"first"} heading={"Company"} onApply={form.handleSubmit(apply)} onReset={resetAll}>
            <FilterColumnOrderView columnKey={"name"}/>
            <FilterMultiValueSelectView legend={"Companies"} form={form as any} listLoader={companiesListLoader}/>
            <FilterRadioChipGroupView legend={"Relation"}>
                <Chip>
                    <Input type={"radio"} value={"any"} {...form.register("relation")}/>
                    Any
                </Chip>
                <Chip>
                    <Input type={"radio"} value={"parent"} {...form.register("relation")}/>
                    Parent
                </Chip>
                <Chip>
                    <Input type={"radio"} value={"child"} {...form.register("relation")} />
                    Child
                </Chip>
            </FilterRadioChipGroupView>
        </ThWithFilterView>
    );
}


function companiesListLoader(page, input) {
    return findCompanies({
        size: LOAD_ITEM_COUNT,
        page,
        sort: ["name,asc"],
        criteria: {
            usePartialName: true,
            name: input
        },
    });
}