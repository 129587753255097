import * as React from "react";
import {useIntersectionObserver} from "./hooks";

type IntersectionObserverProps = React.PropsWithChildren<{
    callback: IntersectionObserverCallback,
    options?: IntersectionObserverInit,
}>;

export function IntersectionObserver({children, callback, options}: IntersectionObserverProps) {
    const intersectionObserver = useIntersectionObserver(callback, options);

    const mappedChildren = React.Children.map(children, (child) => {
        if (React.isValidElement<React.ReactHTMLElement<HTMLElement>>(child)) {
            return React.cloneElement(
                child,
                {
                    ref(node){
                        if (node != null) {
                            // refs.ref().add(node);
                            intersectionObserver.observe(node);
                        }
                    },
                },
            );
        }
        return child;
    });


    return (
        <>{mappedChildren}</>
    );
}