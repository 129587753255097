import * as React from "react";
import {ThWithFilterView, FilterMultiValueSelectView, FilterCheckboxChipGroupView} from "../filters";
import {useData, useDatastore} from "../../../../../../DataLoader";
import {findAlarmCategories, findAlarmTypes} from "../../../../../Alarms";
import {useForm} from "../../../../../../Form";
import {useQueryParams} from "../../../../../QueryParams";
import {AlarmType} from "../../../../../../models/alarm/alarmType";
import { LOAD_ITEM_COUNT, toArray} from "../common";
import {pickGuid} from "../../../../../../utils/objects";
import {Chip} from "../../../../../common/chips";
import {Input} from "../../../../../common/form/Input";
import {CompanyAlarmTypesQueryParamsType} from "./types";
import {ALARM_CATEGORIES_DATA_KEY, ALARM_TYPES_DATA_KEY} from "./const";

type FilterForm = {
    categories: string[],
    valueName: string,
    values: AlarmType[],
    specific: boolean,
};

export function CompanyAlarmTypeColumnHeaderView() {
    const datastore = useDatastore();
    const { params, update } = useQueryParams<CompanyAlarmTypesQueryParamsType>();
    
    const categories = useData({loader: findAlarmCategories, keys: [ALARM_CATEGORIES_DATA_KEY]})

    const form = useForm<FilterForm>({
        defaultValues: async () => {
            const alarmTypesGuids = toArray(params.alarmType);
            if (alarmTypesGuids.length > 0) {
                const alarmTypes = await findAlarmTypes({
                    page: 0,
                    size: alarmTypesGuids.length,
                    criteria: {
                        guids: alarmTypesGuids,
                    },
                });

                datastore.set([ALARM_TYPES_DATA_KEY], alarmTypes);

                return {
                    categories: toArray(params.alarmCategory),
                    valueName: "",
                    values: alarmTypes.list,
                    specific: true,
                };
            }

            return {
                categories: toArray(params.alarmCategory),
                valueName: params.alarmTypeName ?? "",
                values: [],
                specific: params.alarmTypeName == null,
            };
        },
    });


    function apply(data: FilterForm) {
        datastore.set([ALARM_TYPES_DATA_KEY], {list: data.values});
        update({
            page: 0,
            alarmCategory: data.categories,
            alarmTypeName: data.specific ? null : data.valueName,
            alarmType: data.specific ? data.values.map(pickGuid) : [],
        });
    }

    function resetAll() {
        form.reset({categories: [], valueName: "", values: [], specific: true});
        update({page: 0, alarmType: [], alarmCategory: [], alarmTypeName: null});
    }

    return (
        <ThWithFilterView heading={"Alarm types"} onApply={form.handleSubmit(apply)} onReset={resetAll}>
            {categories.state === "successful" && (
                <FilterCheckboxChipGroupView legend={"Alarm Categories"}>
                    {categories.data.list.map(({guid, name}) => {
                        return (
                            <Chip key={guid}>
                                <Input type={"checkbox"} value={guid} {...form.register("categories")}/>
                                {name}
                            </Chip>
                        );
                    })}
                </FilterCheckboxChipGroupView>
            )}
            <FilterMultiValueSelectView legend={"Alarm types"} form={form as any} listLoader={alarmTypesLoader}/>
        </ThWithFilterView>
    )
}

function alarmTypesLoader(page: number, input: string) {
    return findAlarmTypes({
        page,
        size: LOAD_ITEM_COUNT,
        sort: ["name,asc"],
        criteria: {
            name: input,
            usePartialName: true,
        },
    });
}