import * as React from "react";
import {OptionControllerContext} from "./context";
import {Icon} from "../../Icon";

type OptionProps = React.HTMLProps<HTMLElement> & {
    fieldDisplay?: React.ReactNode;
};

export function Option({value, fieldDisplay, children}: OptionProps) {
    const ref = React.useRef<HTMLLIElement>();
    const controller = React.useContext(OptionControllerContext);

    const isSelected =  controller.isSelected(`${value}`);

    React.useEffect(() => {
        controller.register(value, {
            display: fieldDisplay ?? React.cloneElement(<>children</>, {}, []),
            ref
        });
    }, []);

    function onClick() {
        if (!isSelected) {
            //controller.add(value);
            controller.change(`${value}`);
        }
    }

    const iconName = controller.multiple
        ? (isSelected ? "check_box" : "check_box_outline_blank")
        : (isSelected ? "radio_button_checked" : "radio_button_unchecked");

    return (
        <li ref={ref} className={"option"} onClick={onClick}>
            <Icon name={iconName}/>
            {children}
        </li>
    );
}