export const DEBOUNCE_DELAY = 600;
export const LOAD_ITEM_COUNT = 20;


export function valueFromTo<T extends {guid: string}>(o: T) {
    return {
        valueAs() {
            return o;
        },
        stringify(c: T) {
            return c?.guid;
        }
    };
}

export function toArray<T>(x: T | T[]) {
    return Array.isArray(x) ? x : x != null ? [x] : [];
}