import * as React from "react";
import {getComponentModelCompanies} from "../../../api";
import {StatefulMap, useMap} from "../../../../../../hooks/useMap";
import {Company, CompanyComponentModel} from "../../../../../../models/company/companyModel";
import {ComponentBrand} from "../../../../../../models/component/componentBrand";
import {ComponentModel} from "../../../../../../models/component/componentModel";
import {PickGuid} from "../../../../../../models/types";
import {concat} from "../../../../../../utils/arrays";
import {consumeJSONList} from "../../../../../../utils/json";
import {groupBy} from "../../../../../../utils/maps";
import {omit} from "../../../../../../utils/objects";
import {
    useStatelessComponentModelChangeObserver,
} from "../../contexts/ComonentModelChangesContext";
import {
    ComponentModelChangeEvent,
} from "../../contexts/ComonentModelChangesContext/types";
import {isSuccessfulChangeResult} from "../../contexts/ComonentModelChangesContext/utils";
import {useComponentModel} from "../../contexts/ComponentModelProvider";
import {AbstractCompanyComponentModel} from "../types";
import {isVirtualCompanyComponentModel, newVirtualCompanyComponentModel} from "../utils";
import {constant} from "../../../../../../utils/functions";

export function useComponentModelCompaniesModalView(open: boolean) {
    const componentModel = useComponentModel();
    const companiesComponentModels = useMap<string, AbstractCompanyComponentModel[]>();

    const [loading, setLoading] = React.useState(false);

    useStatelessComponentModelChangeObserver(action, [companiesComponentModels, componentModel]);

    React.useEffect(() => {
        if (open) {
            setLoading(true);
            getComponentModelCompanies(componentModel)
                .then(consumeJSONList(groupBy((ccm: AbstractCompanyComponentModel) => ccm.company.guid)))
                .then(companiesComponentModels.from)
                .then(constant(setLoading,false))
                .catch(console.error);
        } else {
            companiesComponentModels.clear();
        }
    }, [open]);

    return {companiesComponentModels, loading, componentModel};
}

type CompaniesComponentModels =  StatefulMap<string, AbstractCompanyComponentModel[]>;
type CompaniesComponentModelsEntries =  Map<string, AbstractCompanyComponentModel[]>;
type ComponentBrandRef = PickGuid<ComponentBrand>;
type CompanyRef = PickGuid<Company>;

function remove(entries: CompaniesComponentModelsEntries, componentBrand: ComponentBrandRef, company: CompanyRef) {
    const prevBrands = entries.get(company.guid) ?? [];
    entries.set(company.guid, prevBrands.filter((ccm) => ccm.componentBrand?.guid !== componentBrand?.guid));
    if (entries.get(company.guid).length === 0) {
        entries.delete(company.guid);
    }
    return entries;
}

function removeComponentBrand(companiesComponentModels: CompaniesComponentModels, componentBrand: ComponentBrandRef) {
    return (company: Company) => {
        companiesComponentModels.from((entries: Map<string, AbstractCompanyComponentModel[]>) => {
            return remove(entries, componentBrand, company);
        });
    };
}

function replace(entries: CompaniesComponentModelsEntries, replacement: CompanyComponentModel, cb: ComponentBrandRef) {
    const brandedModels = entries.get(replacement.company.guid);
    entries.set(replacement.company.guid, brandedModels.map((ccm) => {
        if ((ccm.componentBrand?.guid ?? null) !== (cb?.guid ?? null)) {
            return ccm;
        }
        return replacement;
    }));
}

type Updater = (prevCcm: AbstractCompanyComponentModel) => AbstractCompanyComponentModel;
function update(entries: CompaniesComponentModels, componentBrand: ComponentBrandRef, updater: Updater) {
    return (company: Company) => {
        entries.set(company.guid, (brandedModels: AbstractCompanyComponentModel[], cb) => {
            return brandedModels.map((ccm) => {
                if (ccm.componentBrand?.guid === cb?.guid) {
                    return updater(ccm);
                }
                return ccm;
            });
        }, [componentBrand]);
    };
}

type CMCEvent = ComponentModelChangeEvent;
function action(event: CMCEvent, companiesComponentModels: CompaniesComponentModels, componentModel: ComponentModel) {
    switch (event.type) {
        case "add": {
            event.target.forEach((change) => {
                if (change.type === "add-component-model-to-company") {
                    const [_, componentBrand, companies] = change.params;
                    companies.forEach((company: Company) => {
                        const newCcm = newVirtualCompanyComponentModel(company, componentModel, componentBrand);
                        companiesComponentModels.set(company.guid, concat(newCcm));
                    });
                } else if (change.type === "remove-component-model-from-company") {
                    const [_, componentBrand, companies] = change.params;
                    return companies.forEach(update(companiesComponentModels, componentBrand, (ccm) => {
                        return isVirtualCompanyComponentModel(ccm) ? ccm : {...ccm, __virtual: "remove"};
                    }));
                }
            });
            break;
        }
        case "remove": {
            event.target.forEach((change) => {
                if (change.type === "add-component-model-to-company") {
                        const [_, componentBrand, companies] = change.params;
                        companies.filter((company) => companiesComponentModels.has(company.guid))
                            .forEach(removeComponentBrand(companiesComponentModels, componentBrand));
                    } else if (change.type === "remove-component-model-from-company") {
                        const [ _, componentBrand, companies] = change.params;
                        companies.forEach(update(companiesComponentModels, componentBrand, (ccm) => {
                            return !isVirtualCompanyComponentModel(ccm) || ccm.__virtual !== "remove"
                                ? ccm
                                : omit(ccm, "__virtual");
                        }));
                    }
            });
            break;
        }
        case "result": {
            companiesComponentModels.from((entries) => {
                event.results.forEach((result) => {
                    if (isSuccessfulChangeResult(result, "remove-component-model-from-company")) {
                        const [_, componentBrand, companies] = result.change.params;
                        companies.forEach((company) => remove(entries, componentBrand, company));
                    } else if (isSuccessfulChangeResult(result, "add-component-model-to-company")) {
                        const [_, componentBrand, companies] = result.change.params;
                        companies.forEach((company) => {
                            const newCcm = result.result.list.find((ccm) => {
                                return ccm.company.guid === company.guid &&
                                    (ccm.componentBrand?.guid ?? null) === (componentBrand?.guid ?? null);
                            });
                            replace(entries, newCcm, componentBrand);
                        });
                    } else if (
                        isSuccessfulChangeResult(result, "update-existing-company-component-model") ||
                        isSuccessfulChangeResult(result, "update-new-company-component-model")) {
                        replace(entries, result.result, result.change.params[0].componentBrand);
                    }
                });
                return entries;
            });
        }
    }
}
