import * as React from "react";
import {Td} from "../../../../../common/DataTable";
import {useData} from "../../../../../../DataLoader";
import {getAllCurrencies} from "../../../../../Currencies";
import {Skeleton} from "../../../../../common/Skeleton";
import {Icon} from "../../../../../common/Icon";
import {Chip} from "../../../../../common/chips";

import "./style.scss";
import {Thumbnail} from "../../../../../common/Thumbnail";


type CompanyCurrencyCellViewProps = {
    currencyGuid: string,
};

export function CompanyCurrencyCellView({currencyGuid}: CompanyCurrencyCellViewProps) {
    const currency = useData({
        loader: getAllCurrencies,
        map({list}) {
            return list.find(({guid}) => guid === currencyGuid);
        },
        keys: ["currencies"],
    });

    switch (currency.state) {
        case "loading": {
            return (
                <Td><Skeleton type={"text"}/></Td>
            );
        }
        case "successful": {


            const symbol = currency.data?.iso3 === "EUR" ? <Icon name={"euro_symbol"}/>
                : ["CAD", "USD", "AUD", "CLP", "COP", "MXN"].includes(currency.data?.iso3) ? <Icon name={"attach_money"}/>
                : ["TRY"].includes(currency.data?.iso3) ? <Icon name={"currency_lira"}/>
                : ["GBP"].includes(currency.data?.iso3) ? <Icon name={"currency_pound"}/>
                : ["CHF"].includes(currency.data?.iso3) ? <Icon name={"currency_franc"}/>
                : ["RUB"].includes(currency.data?.iso3) ? <Icon name={"currency_ruble"}/>
                : <span className={"currency-iso3-code"}>{currency.data?.iso3}</span>;

            return (
                <Td className={"company-currency-cell-view"}>
                    <Chip className={"company-currency-chip"}>
                        <Thumbnail>{symbol}</Thumbnail>
                        {currency.data?.name}
                    </Chip>
                </Td>
            )
        }
        case "error": {
            return (
                <Td>Couldn't find the currency</Td>
            )
        }
    }
}