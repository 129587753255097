import {updateCompanyComponentModelValues} from "../../../api";
import {StatelessStream} from "../../../../../../hooks/observables";
import {FnCallResult, FunctionQueueInterceptors} from "../../../../../../hooks/useFunctionQueue/types";
import {Company, CompanyComponentModel} from "../../../../../../models/company/companyModel";
import {ComponentBrand} from "../../../../../../models/component/componentBrand";
import {PickGuid} from "../../../../../../models/types";
import {JSONList} from "../../../../../../models/utils/jsonList";
import {objectValueMap} from "../../../../../../utils/objects";
import {
    AddComponentModelToCompaniesCall,
    AddComponentTypeToCompaniesCall,
    Change,
    ChangeResult,
    ComponentModelChangeEvent,
    CreateNewBrand, RemoveComponentModelFromCompaniesCall, SuccessfulChangeResult,
    UpdateExistingCompanyComponentModelValuesCall,
    UpdateNewCompanyComponentModelValueCall,
    UploadMedia,
} from "./types";
import {CompanyComponentType} from "../../../../../../models/component/componentType";
import {flatMap} from "../../../../../../utils/arrays";

export function generateKey(company: PickGuid<Company>, componentBrand?: PickGuid<ComponentBrand> | null) {
    if (typeof componentBrand === "undefined") {
        return `company:${company.guid}`;
    }
    return `company:${company.guid};component-brand:${componentBrand?.guid}`;
}

export function changeWithKey(
    company: PickGuid<Company>,
    componentBrand?: PickGuid<ComponentBrand> | null,
    ...changeTypes: Array<Change["type"]>
) {
    return (change: Change) => {
        if (typeof componentBrand === "undefined") {
            return change.key.toString().startsWith(generateKey(company));
        }
        return change.key === generateKey(company, componentBrand) &&
            (changeTypes.includes(change.type) || changeTypes.length === 0);
    };
}

export function changeResultWithKey(
    company: PickGuid<Company>,
    componentBrand?: PickGuid<ComponentBrand> | null,
    changeType?: Change["type"],
) {
    return ({change}: ChangeResult) => {
        if (typeof componentBrand === "undefined") {
            return change.key.toString().startsWith(generateKey(company));
        }
        return change.key === generateKey(company, componentBrand) && (change.type === changeType || !changeType);
    };
}

type ComponentModelChangeEventStream = StatelessStream<ComponentModelChangeEvent>;
export function changesInterceptors(changesStream: ComponentModelChangeEventStream): FunctionQueueInterceptors<Change> {
    return {
        enqueue(call, changes, original, fq) {
            original(call);
            changesStream.push({type: "add", pending: fq.calls(), target: [call]});
        },
        remove(key, _, original, fq) {
            const removed = fq.find(key);
            const removalCount = original(key);
            if (removalCount > 0) {
                changesStream.push({type: "remove", pending: fq.calls(), target: removed});
            }
            return removalCount;
        },
        update(key, updater, original, fq): number {
            const updateCount = original(key, updater);
            const updated = fq.find(key);
            if (updateCount > 0) {
                changesStream.push({type: "edit", pending: fq.calls(), target: updated});
            }
            return updateCount;
        },
        async dequeueAndCall<E>(call, original, fq) {
            // todo should add a check to see if it can be updated
            switch (call.type) {
                case "create-new-brand": {
                    // todo make sure is getting used else cancel the call
                    const nextCalls = fq.calls();
                    const willBeUsed =  nextCalls.findIndex((otherChanges) => {
                        return (otherChanges.type === "update-existing-company-component-model" ||
                            otherChanges.type === "update-new-company-component-model") &&
                            otherChanges.params[1]?.componentBrand?.guid === call.key;
                    }) > -1;
                    if (!willBeUsed) {
                        return {
                            call,
                            status: "cancelled",
                            reason: "Component brand is not being used",
                        };
                    }
                    break;
                }
                case "upload-media": {
                    const nextCalls = fq.calls();
                    const willBeUsed =  nextCalls.findIndex((otherChanges) => {
                        return (otherChanges.type === "update-existing-company-component-model" ||
                                otherChanges.type === "update-new-company-component-model") &&
                            otherChanges.params[1]?.media?.guid === call.key;
                    }) > -1;
                    if (!willBeUsed) {
                        return {
                            call,
                            status: "cancelled",
                            reason: "Media is not being used",
                        };
                    }
                    break;
                }
                case "add-component-type-to-company": {
                    const nextCalls: Change[] = fq.calls();
                    const companiesGuids: string[] = call.params[1].map(({guid}) => guid);
                    const willBeUsed = flatMap(nextCalls, (nextCall) => {
                        if (nextCall.type === "add-component-model-to-company") {
                            return nextCall.params[2];
                        }
                        return null;
                    }).findIndex((company) => companiesGuids.includes(company?.guid)) > -1;

                    if (!willBeUsed) {
                        return {
                            call,
                            status: "cancelled",
                            reason: "Component type is not being used",
                        };
                    }
                    break;
                }
                case "add-component-model-to-company": {
                    const additionResult: FnCallResult<Change> = fq.results()
                        .find((r) => r.call.key === call.key && r.call.type === "add-component-type-to-company");
                    if (Boolean(additionResult) && additionResult.status !== "successful") {
                        return {
                            call,
                            status: "cancelled",
                            reason: "Failed to add component type, cannot proceed to add component model",
                        };
                    }
                    break;
                }
                case "update-existing-company-component-model": {
                    // todo check if component-brand or media was successfully uploaded
                    const newCallUpdatedParams = getNewCompanyComponentModelObject(call, fq.results());

                    if (!isValidEntity(newCallUpdatedParams?.componentBrand)) {
                        return {
                            call,
                            status: "cancelled",
                            reason: "Component brand was not created",
                        };
                    } else if (!isValidEntity(newCallUpdatedParams?.media)) {
                        return {
                            call,
                            status: "cancelled",
                            reason: "Media was not created",
                        };
                    }
                    return original({
                        ...call,
                        params: [call.params[0], newCallUpdatedParams],
                    });
                }
                case "update-new-company-component-model": {
                    // todo check if component-brand or media was successfully uploaded
                    const additionResult: FnCallResult<Change> = fq.results()
                        .find((r) => r.call.key === call.key && r.call.type === "add-component-model-to-company");

                    if (!additionResult || additionResult.status !== "successful") {
                        return  {
                            call,
                            status: "cancelled",
                            reason: "Failed to add component model, cannot proceed to update values",
                        };
                    }

                    const newCallUpdatedParams = getNewCompanyComponentModelObject(call, fq.results());

                    if (!isValidEntity(newCallUpdatedParams?.componentBrand)) {
                        return {
                            call,
                            status: "cancelled",
                            reason: "Component brand was not created",
                        };
                    } else if (!isValidEntity(newCallUpdatedParams?.media)) {
                        return {
                            call,
                            status: "cancelled",
                            reason: "Media was not created",
                        };
                    }

                    const [addedComponentModel] = (additionResult.result as JSONList<CompanyComponentModel>).list;
                    return original({
                        ...call,
                        fun: updateCompanyComponentModelValues,
                        params: [addedComponentModel, newCallUpdatedParams],
                    });
                }
            }
            return original(call);
        },
        async callAll(callQueue, original) {
            callQueue.sort((a, b) => a.priority - b.priority);
            changesStream.push({type: "updating", updating: [...callQueue]});
            const results = await original<any>(callQueue);
            changesStream.push({
                type: "result",
                results: results.map((result): ChangeResult => {
                    if (result.status === "successful") {
                        return {
                            change: result.call,
                            success: true,
                            result: result.result,
                        } as any; // todo remove as any with better type inference
                    }
                    return {
                        change: result.call,
                        success: false,
                        error: result.status === "error" ? result.error : result.reason,
                    };
                }),
            });
            return results;
        },
    };
}

type UpdateCall = UpdateExistingCompanyComponentModelValuesCall | UpdateNewCompanyComponentModelValueCall;

function getNewSupportingEntityGuid(results: Array<FnCallResult<Change>>, call: UpdateCall, callType: Change["type"]) {
    const supportingEntity = results.find((r: FnCallResult<Change>) =>
        r.call.type === callType && (
            r.call.key === call.params[1].componentBrand?.guid ||
            r.call.key === call.params[1].media?.guid
        ),
    ) as FnCallResult<CreateNewBrand | UploadMedia>;
    if (!supportingEntity || supportingEntity.status !== "successful") {
        return null;
    }
    return "list" in  supportingEntity.result
        ? supportingEntity.result.list[0].guid
        : supportingEntity.result.guid;
}

function isValidEntity(e: {guid: string}) {
    return e == null || e?.guid != null;
}

function getNewCompanyComponentModelObject(
    call: UpdateCall,
    results: Array<FnCallResult<Change>>,
): Partial<CompanyComponentModel> {
    return objectValueMap(
        call.params[1] as CompanyComponentModel,
        (key, value: any) => {
            if (key === "componentBrand" && typeof value?.guid === "number") {
                return {
                    guid: getNewSupportingEntityGuid(results, call, "create-new-brand"),
                };
            } else if (key === "media" && typeof value?.guid === "number") {
                return {
                    guid: getNewSupportingEntityGuid(results, call, "upload-media"),
                };
            }
            return value;
        },
    );
}
export function isSuccessfulChangeResult<T extends Change["type"]>(
    x: ChangeResult,
    type: T,
): x is SuccessfulChangeResult<T> {
    return x.success && x.change.type === type;
}
