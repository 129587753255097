import {AutoSuggest, Button, H6, SelectInput, SelectInputOption} from "@danfoss/etui-core";
import {useCallback, useState} from "react";
import {Col, Form, FormControl, InputGroup} from "react-bootstrap";
import Services from "../../../controllers/utils/Services";
import {companyController, movementController, MovType} from "../Transfer";
import {DeviceConflictsResolutionModal} from "./CodeConflictResolutionModal";
import {DeviceIdentifierType, getCode, PartialDevice, reduceByCode} from "./common";
import {useDebounceCallback} from "../../../hooks/useDebounceCallback";
import React = require("react");


const identifierOptions = [
    {
        value: DeviceIdentifierType.DEVICE_CODE,
        label: "Devices' codes",
    },
    {
        value: DeviceIdentifierType.DEVICE_CUSTOMER_CODE,
        label: "Customer's devices' codes",
    },
    {
        value: DeviceIdentifierType.EQUIPMENT_CUSTOMER_CODE,
        label: "Customer's equipment's codes",
    },
    {
        value: DeviceIdentifierType.EQUIPMENT_MANUFACTURER_CODE,
        label: "Equipment's manufacturers' codes",
    },
];

const LeftColumn = (
    {
        setDevices,
        setPackages,
        setEquipment,
        setInvalidDevices,
        setInvalidPackages,
        setInvalidEquipment,
        setValidEquipmentWithWarnings,
        setCompleteEquipment,
        setValidEquipment,
        movType,
        setMovType,
        setPackagesDevices,
        csvFile,
        setCsvFile,
        isLoading,
        setLoading,
        resetParams,
        setDuplicateElements,
        inputCompanyValue, setInputCompanyValue,
        selectedCompany, setSelectedCompany,
        setCompanyWarehouses,
        setSelectedLocation,

    }) => {

    const movimentationOptions = [
        {value: MovType.DevicesOrEquipments, label: "Devices / Equipment"},
        {value: MovType.Packages, label: "Packages"},
        {value: MovType.InstallAndMove, label: "Install and move devices"},
    ];

    const services: Services = new Services();

    const [inputValue, setInputValue] = useState("");
    const [movTypeInput, setMovTypeInput] = useState<SelectInputOption | undefined>();

    const [identifierType, setIdentifierType] = useState<SelectInputOption>();

    const [devicesListWithConflicts, setDevicesListWithConflicts] = useState<PartialDevice[]>([]);
    const [numberOfConflicts, setNumberOfConflicts] = useState<number>(0);

    function updateIdentifierType(newIdentifierType) {
        setIdentifierType(newIdentifierType);
    }

    function updateDevicesList(resolution: PartialDevice[]) {
        setDevices(resolution);
        setNumberOfConflicts(0);
    }

    function onCancelConflict() {
        setDevicesListWithConflicts([]);
        setNumberOfConflicts(0);
    }

    const handleChange = useCallback((inVal) => {
        resetParams();
        setInputValue("");
        setMovTypeInput(inVal);
        setMovType(inVal.value);
    }, []);

    const validationRequest = async () => {
        setEquipment([]);
        setPackages([]);
        setDevices([]);

        const showNotification = (response) => {
            setLoading(false);

            if (response.status === 500) {
                services.errorNotification(" ", "Server error, validation aborted");
            } else if (response.status !== 200) {
                response.json().then((data) => {
                    services.errorNotification(" ", "Validation aborted, " + data.message);
                });
            }
        };

        setLoading(true);

        const checkDuplicates = (array) => array.filter((item, index) => array.indexOf(item) !== index);

        switch (movType) {
            case MovType.DevicesOrEquipments: {
                const deviceCodes = inputValue.split("\n").filter((code: string) => (code !== ""));

                setDuplicateElements(checkDuplicates(deviceCodes).length);

                const response = await movementController.validateDevices(deviceCodes, identifierType?.value,
                    selectedCompany?.guid);

                if (response.status === 200) {
                    response.json().then((data) => {
                        let currentInvalidDevices = 0;
                        const mappedDevices = reduceByCode(data.devices.map((device) => {
                                if (!device.valid) {
                                    currentInvalidDevices++;
                                }
                                return {
                                    code: getCode(device, identifierType?.value as DeviceIdentifierType),
                                    deviceCode: device.deviceCode,
                                    valid: device.valid,
                                    dataOwnerCompany: device.dataOwnerCompany,
                                    status: device.deviceStatus,
                                    equipmentCode: device.equipmentCode,
                                    equipmentManufacturerCode: device.equipmentManufacturerCode,
                                    equipmentModel: device.equipmentModel,
                                    telemetryInfo: device.telemetryInfo,
                                };
                            },
                        ));
                        setLoading(false);
                        setInvalidDevices(currentInvalidDevices);


                        const conflictCount = mappedDevices.reduce((count, {conflicts}) => {
                            return count + Number(Boolean(conflicts));
                        }, 0);

                        setNumberOfConflicts(conflictCount);

                        if (conflictCount > 0) {
                            setDevicesListWithConflicts(mappedDevices);
                        } else {
                            setDevices(mappedDevices);
                        }
                    });
                } else {
                    showNotification(response);
                }
                break;
            }

            case MovType.Packages: {
                const packagesCodes = inputValue.split("\n").filter((code: string) => (code !== ""));

                setDuplicateElements(checkDuplicates(packagesCodes).length);

                const response = await movementController.validatePackages(packagesCodes);

                if (response.status === 200) {
                    response.json().then((data) => {
                        setPackagesDevices(data.devicesCount);
                        let currentInvalidPackages = 0;
                        const mappedPackages = data.packages.map((pkg) => {
                                if (!pkg.valid) {
                                    currentInvalidPackages++;
                                }
                                return {
                                    code: pkg.packageCode,
                                    openingDate: pkg.openingDate,
                                    closureDate: pkg.closureDate,
                                    devicesModel: pkg.devicesModel,
                                    valid: pkg.valid,
                                    status: pkg.packageStatus,
                                    devicesCount: pkg.devicesCount,
                                    devices: pkg.devices,
                                };
                            },
                        );
                        setLoading(false);
                        setInvalidPackages(currentInvalidPackages);
                        setPackages(mappedPackages);
                    });
                } else {
                    showNotification(response);
                }
                break;
            }

            case MovType.InstallAndMove: {
                const response = await movementController.validateEquipment(csvFile);
                if (response.status === 200) {
                    response.json().then((data) => {
                        let currentInvalidEquipment = 0;
                        let currentValidEquipmentWithWarnings = 0;

                        const mappedEquipment = data.records.map((equipment) => {
                            if (!equipment.valid) {
                                currentInvalidEquipment++;
                            }
                            if (equipment.valid && equipment.equipmentWarningMessages.length > 0) {
                                currentValidEquipmentWithWarnings++
                            }
                            return {
                                customerCode: equipment.customerCode,
                                deviceCode: equipment.deviceCode,
                                equipmentErrorMessages: equipment.equipmentErrorMessages,
                                equipmentWarningMessages: equipment.equipmentWarningMessages,
                                manufacturerCode: equipment.manufacturerCode,
                                model: equipment.model,
                                valid: equipment.valid,
                                complete: equipment.complete,
                            };
                        });
                        setLoading(false);
                        setInvalidEquipment(currentInvalidEquipment);
                        setValidEquipment(data.validRecords);
                        setValidEquipmentWithWarnings(currentValidEquipmentWithWarnings);
                        setEquipment(mappedEquipment);
                        setCompleteEquipment(data.completeRecords);
                    });
                } else {
                    showNotification(response);
                }
                break;
            }
        }
    };


    const [companySuggestions, setCompanySuggestions] = useState([]);
    const [emptyPlaceholder, setEmptyPlaceholder] = useState("No companies found");
    const [isCompanySuggestionLoading, setIsLoading] = useState(false);


    const getParentCompanies = useDebounceCallback((params: any) => {
        companyController.getParentCompanies(params.inputValue).then((data) => {
            params.inputValue.length < companyController.minCompanyCharacters ?
                setEmptyPlaceholder("Insert at least 2 characters") :
                setEmptyPlaceholder("No companies found");
            const suggestions = data.list.map((company) => ({
                item: company,
                label: company.name,
                value: company.guid,
            }));
            setCompanySuggestions(suggestions);
            setIsLoading(false);
        });
    }, 600);

    const handleStateChange = (downshiftStateAndHelpers,
                               params,
    ) => {
        if (!downshiftStateAndHelpers.isOpen) {
            setInputCompanyValue("");
            setSelectedCompany(null);
            resetParams();
        } else if (params.hasOwnProperty("inputValue")) {
            setCompanyWarehouses([]);
            setSelectedLocation(undefined);
            setInputCompanyValue(params.inputValue);
            if (params.inputValue) {
                setIsLoading(true);
                getParentCompanies(params);
            }
        }
    };

    const disabledValidateButton = inputValue === "" && csvFile === undefined || isLoading === true
        || (selectedCompany == null || inputCompanyValue === "")
        || (!identifierType && movType === MovType.DevicesOrEquipments);

    return (
        <Col className="movimentationsColumn col-3 h-100">
            <SelectInput
                name="Movement type"
                label="Movement type..."
                options={movimentationOptions}
                value={movTypeInput}
                onChange={handleChange}
                styles={{root: {zIndex: 4}}}
            />
            {
                movType === MovType.DevicesOrEquipments ? (
                    <SelectInput
                        name={"identifierType"}
                        label={"Identifiers type"}
                        value={identifierType}
                        options={identifierOptions}
                        onChange={updateIdentifierType}
                        styles={{root: {zIndex: 3, mt: 10, mb: 10}}}
                    />
                ) : null
            }
            <InputType
                movType={movType}
                inputValue={inputValue}
                setInputValue={setInputValue}
                setCsvFile={setCsvFile}
            />
            {inputValue !== "" && (
                <>
                    <AutoSuggest
                        styles={{root: {zIndex: 4, mt: 10, mb: 10}}}
                        label="Destination company..."
                        emptyPlaceholder={emptyPlaceholder}
                        inputValue={inputCompanyValue}
                        suggestions={companySuggestions}
                        isLoading={isCompanySuggestionLoading}
                        itemToString={(item) => item && item.label}
                        onStateChange={(p, stateAndHelpers) =>
                            handleStateChange(stateAndHelpers, p)
                        }
                        onSelectedValueChange={
                            (suggestion) => {
                                if (suggestion != null) {
                                    setSelectedCompany(suggestion.item);
                                    setInputCompanyValue(suggestion.label);
                                    companyController.getCompanyFactoriesAndWarehouses(
                                        suggestion.item.guid,
                                    ).then((data: { list: any[] }) => {
                                        data.list.sort(
                                            (a, b) => a.isDefaultWarehouse
                                            && !b.isDefaultWarehouse ? -1 : 1,
                                        );
                                        const warehouses = data.list.map(
                                            (location) => ({
                                                item: location,
                                                value: location.guid,
                                                label: `${location.code} - ${location.description} (${location.address.province}) ${location.isDefaultWarehouse ? "DEFAULT" : ""}`,
                                                isDefault: location.isDefaultWarehouse as boolean,
                                            }),
                                        );
                                        if (warehouses[0]?.isDefault) {
                                            // setSelectedWarehouse(warehouses[0]);
                                            setSelectedLocation(warehouses[0].item);
                                        }
                                        setCompanyWarehouses(warehouses);
                                    });
                                }
                            }
                        }
                    />
                </>
            )}
            {
                movType !== null && (
                    <Button
                        className="my-2 w-100"
                        variant="primary"
                        disabled={disabledValidateButton}
                        onClick={() => {
                            validationRequest();
                            resetParams();
                        }}
                    >
                        Validate →
                    </Button>
                )
            }
            <DeviceConflictsResolutionModal
                conflictsCount={numberOfConflicts}
                conflicts={devicesListWithConflicts}
                identifierType={(identifierType?.value ?? DeviceIdentifierType.DEVICE_CODE) as DeviceIdentifierType}
                onResolve={updateDevicesList}
                onCancel={onCancelConflict}
            />
        </Col>
    );
};

// Returns different input components based on the movement type
function InputType({movType, inputValue, setInputValue, setCsvFile}) {

    switch (movType) {
        case MovType.DevicesOrEquipments: {
            return (
                <>
                    <TextArea
                        placeholder={"357865095624236" + "\n" + "357865095334236" + "\n" + "457265095334236..."}
                        inputValue={inputValue}
                        setInputValue={setInputValue}
                    />
                </>
            );
        }

        case MovType.Packages: {
            return (
                <>
                    <H6 className="mt-2 ml-1 mb-1">Packages numbers</H6>
                    <TextArea
                        placeholder={"L1623660775441" + "\n" + "L1625860775441..."}
                        inputValue={inputValue}
                        setInputValue={setInputValue}
                    />
                </>
            );
        }

        case MovType.InstallAndMove: {
            return (
                <>
                    <Form.Group>
                        <Form.File
                            className="position-relative mt-1"
                            required={true}
                            name="file"
                            label=".csv file"
                            onChange={(v) => {
                                console.log(v.target.files[0]);
                                setCsvFile(v.target.files[0]);
                                setInputValue(v.target.files?.[0]?.name ?? "filename");
                            }}
                            onClick={(v) => {
                                v.target.value = "";
                                setCsvFile(undefined);
                            }}
                        />
                    </Form.Group>
                    <div className="border rounded p-2">
                        Expected file columns:
                        <br/>
                        <code>model</code> *<br/>
                        <code>manufacturer_code</code> *<br/>
                        <code>client_code</code><br/>
                        <code>device_code</code> *<br/>
                        <code>destination_company</code><br/>
                        <code>destination_location</code><br/>
                        <code>shipment_date</code> <i>yyyy-MM-dd hh:mm</i><br/>
                        <br/>
                        Field delimiter is semicolon (<code>;</code>)<br/>
                        <b>NO HEADING ROW IS NECESSARY</b><br/>
                        Fields like device_code should be formatted as plain text when
                        using Excel.<br/>
                        * → mandatory fields.<br/>
                        In case the optional fields are left blank
                        the process will use the information provided in the next step.
                    </div>
                </>
            );
        }

        default:
            return (
                <div/>
            );
    }
}

const TextArea = ({placeholder, inputValue, setInputValue}) => {
    return (
        <InputGroup className="h-100">
            <FormControl
                as="textarea"
                style={{height: "300px", overflow: "hidden"}}
                value={inputValue}
                onChange={
                    (e) => {
                        setInputValue(e.target.value);
                    }}
                placeholder={placeholder}
            />
        </InputGroup>
    );
};

export default LeftColumn;
