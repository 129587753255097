import * as React from "react";

export function useDebounceValue<V>(value: V, ms: number) {
    const [debounceValue, setDebounceValue] = React.useState<V>();

    React.useEffect(() => {
        const timeout = setTimeout(() => setDebounceValue(value), ms);
        return () => clearTimeout(timeout);
    }, [value]);

    return debounceValue;
}
