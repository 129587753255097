import * as React from "react";
import {useData} from "../../../../../../DataLoader";
import {findCompanyTypes} from "../../../../Types/api";
import {useQueryParams} from "../../../../../QueryParams";
import {ThWithFilterView, FilterCheckboxChipGroupView} from "../filters";
import {useForm} from "../../../../../../Form";
import {CompanyTypesQueryParamsType} from "./types";
import {COMPANY_TYPE_DATA_KEY} from "./const";
import {Chip} from "../../../../../common/chips";
import {Input} from "../../../../../common/form/Input";

import "./styles.scss";

type FilterForm = {
    companyTypes: string[]
};

export function CompanyTypeColumnHeaderView() {

    const { params, update } = useQueryParams<CompanyTypesQueryParamsType>();

    const companyTypes = useData({ loader: findCompanyTypes, keys: [COMPANY_TYPE_DATA_KEY] });

    const {register, handleSubmit, reset} = useForm<FilterForm>({
        defaultValues: {
            companyTypes: params == null ? [] : Array.isArray(params.type) ? params.type : [params.type],
        },
    });

    function apply({companyTypes}: FilterForm) {
        update({ type: companyTypes, page: 0});

    }
    function resetAll() {
        reset({companyTypes: []});
        update({type: [], page: 0});
    }

    return (
        <ThWithFilterView heading={"Type"} onReset={resetAll} onApply={handleSubmit(apply)}>
            {companyTypes.state === "successful" && (
                <FilterCheckboxChipGroupView legend={"Types"} className={"company-type-filter-options"}>
                    {companyTypes.data.list.map(({guid, name}) => {
                        return (
                            <Chip className={"company-type-chip"} key={guid}>
                                <Input type={"checkbox"} value={guid} {...register("companyTypes")}/>
                                {name}
                            </Chip>
                        );
                    })}
                </FilterCheckboxChipGroupView>
            )}
        </ThWithFilterView>
    );
}