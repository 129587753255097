import {ComponentCategoriesFilter} from "./types";
import {JSONEntitiesCollection} from "../../../models/utils/jsonList";
import {Pageable} from "../../../models/types";
import {ComponentCategory} from "../../../models/component/componentCategory";
import {assertOk, toRequestParamsString} from "../../../utils/requests";

export async function findComponentCategories(
    criteria?: Pageable<ComponentCategoriesFilter>
): Promise<JSONEntitiesCollection<ComponentCategory>> {
    const response = await fetch(`/componentCategories?${toRequestParamsString(criteria)}`);
    assertOk(response);
    return response.json();
}