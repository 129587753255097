import * as React from "react";
import {Td} from "../../../../../common/DataTable";
import {useFormContext} from "../../../../../../Form";
import {Input} from "../../../../../common/form/Input";

type CompanyNameCellViewProps = {
    companyGuid: string;
};


export function CompanyNameCellView({companyGuid} : CompanyNameCellViewProps) {
    const {register, formId, state} = useFormContext();

    const cellState = state().changedFields.includes(`${companyGuid}/name`) ? "edited": "default";

    return (
        <Td ordinal={"first"} state={cellState} className={"company-name-cell-view"}>
            <Input type={"text"} form={formId} {...register(`${companyGuid}/name`)} />
        </Td>
    );
}