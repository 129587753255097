import * as React from "react";
import {QueryParamsContext} from "./context";


type QueryParamsMap<T> = {
    params: T;
    update(params: Partial<T>): void;
    set(params: T): void;
    remove(...paramNames: (keyof T)[]): void;
};

export function useQueryParams<T extends object = any>(): QueryParamsMap<T> {

    const { params, setUrlSearchParams } = React.useContext(QueryParamsContext);

    return {
        params: params as T,
        set() {
        },
        update(newParams) {
            setUrlSearchParams((prev) => {
                Object.entries(newParams).forEach(([key, value]) => {
                    prev.delete(key);
                    if (value instanceof Array) {
                        value.filter((v) => v != null).forEach((v) => prev.append(key, String(v)))
                    } else if(value != null){
                        prev.set(key, String(value));
                    }
                });
                return prev;
            });
        },
        remove(...paramsNames) {
            setUrlSearchParams((prev) => {
                paramsNames.forEach((name) => prev.delete(String(name)));
                return prev;
            });
        },
    };
}