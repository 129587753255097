import {AutoSuggest, Button, Checkbox, icons, SelectInput, TextInput, Tooltip,} from "@danfoss/etui-core";
import * as React from "react";
import {Col, Form, Row, Table} from "react-bootstrap";
import CompanyController from "../../../controllers/api/CompanyController";
import {OperationsResponse} from "../../../controllers/api/ComponentModelController";
import TranslationController from "../../../controllers/api/TranslationController";
import Services from "../../../controllers/utils/Services";
import {CompanySuggestion, TableCompany} from "../../../models/company/companyModel";
import {ComponentBrand, ComponentBrandSuggestion} from "../../../models/component/componentBrand";
import {ComponentModel} from "../../../models/component/componentModel";
import GeneralAlert from "../../common/GeneralAlert";
import LoadingModal from "../../common/LoadingModal";
import {MediaModal} from "../../common/MediaModal";
import CreateNewBrandModal from "../modals/CreateNewBrandModal";

interface CompanySectionProps {
    companies: any[];
    tableCompanies: TableCompany[];
    componentBrands: ComponentBrand[];
    currentComponentModel: ComponentModel;
    setTableCompanies: (companies: TableCompany[]) => void;
    setCompanies: (c) => void;
    setSearchCompanyInputValue: (s: string) => void;
    searchCompanyInputValue: string;
    changeStep?: (forward: boolean) => void;
    loading: boolean;
    submit: (imageList: File[]) => void;
    refreshCompaniesList?: () => void;
    addNewComponentBrand: (brandDescription: string) => void;
    findSimilarBrands: (brandDescription: string) => Promise<OperationsResponse>;
    errorCreateNewBrandModal: string;
    disabled?: boolean;
    errorCreateNewBrandModalManager: {
        state: string,
        setState: (value: (((prevState: string) => string) | string)) => void;
    };
}

const CompanySection: React.FC<CompanySectionProps> = ({
                                                           addNewComponentBrand,
                                                           changeStep,
                                                           componentBrands,
                                                           currentComponentModel: {name, nameKey},
                                                           disabled,
                                                           errorCreateNewBrandModalManager: {setState, state},
                                                           findSimilarBrands,
                                                           loading,
                                                           refreshCompaniesList,
                                                           searchCompanyInputValue,
                                                           setSearchCompanyInputValue,
                                                           setTableCompanies,
                                                           submit,
                                                           tableCompanies,
                                                       }) => {
    // controllers
    const companyController: CompanyController = new CompanyController();
    const translationController: TranslationController = new TranslationController();
    const services: Services = new Services();
    const INFO_TRANSLATION_TEXT_KEY = "operations.component_models_creation.companies.generic_note";

    const [infoMessage, setInfoMessage] = React.useState<string>(undefined);
    const [searchCompanies, setSearchCompanies] = React.useState<CompanySuggestion[]>([]);
    const [componentBrandSuggestions, setComponentBrandSuggestions] = React.useState<ComponentBrandSuggestion[]>([]);
    const [selectedCompany, setSelectedCompany] = React.useState<TableCompany>(undefined);
    const [allChecked, setAllChecked] = React.useState<boolean>(true);
    const [indeterminate, setIndeterminate] = React.useState<boolean>(false);
    const [actionsAvailable, setActionsAvailable] = React.useState<boolean>(true);
    const [showModal, setShowModal] = React.useState<boolean>(false);
    const [showSelectedCompaniesAtTheTop, setShowSelectedCompaniesAtTheTop] = React.useState<boolean>(true);
    const [showCreateNewBrandModal, setShowCreateNewBrandModal] = React.useState<boolean>(false);
    const [localMedia, setLocalMedia] = React.useState<File[]>([]);

    const columns = [
        {
            key: "checkbox",
            style: {width: "10%"},
            render: (
                <div style={{margin: "0 auto", textAlign: "center"}}>
                    <span> Select all</span>
                    <br/>
                    <Checkbox
                        key={"selectAll"}
                        checked={allChecked}
                        indeterminate={indeterminate}
                        disabled={disabled}
                        onChange={() => {
                            setAllChecked(!allChecked);
                            setIndeterminate(false);
                            setTableCompanies(tableCompanies.map((t) => {
                                t.checked = !allChecked;
                                return t;
                            }));
                        }}
                    />
                </div>
            ),
        },
        {
            key: "name",
            style: {width: "10%"},
            render: "Name",
        },
        {
            key: "client_name",
            style: {width: "12%"},
            render: "Client name",
        },
        {
            key: "client_name_key",
            style: {width: "18%"},
            render: "Client name key",
        },
        {
            key: "client_code",
            style: {width: "8%"},
            render: "Client code",
        },
        {
            key: "brand",
            style: {width: "20%"},
            render: (
                <Row className="w-100 m-0">
                    <Col className="d-flex justify-content-start align-items-end w-100 p-0">
                        <span>Brand</span>
                    </Col>
                    <Col className="d-flex justify-content-end align-items-end w-100 p-0">
                        <Tooltip message={"Add new brand"} placement={"left"}>
                            <Button
                                className={"mr-0"}
                                iconProps={{glyph: icons.ADD}}
                                onClick={() => setShowCreateNewBrandModal(true)}
                            />
                        </Tooltip>
                    </Col>
                </Row>
            ),
        },
        {
            key: "nickname",
            style: {width: "12%"},
            render: "Nickname",
        },
        {
            key: "media",
            style: {width: "10%", maxWidth: "10%"},
            render: (
                <div>
                    <span style={{paddingRight: 2}}>Media </span>
                    {services.mediaIcon()}
                </div>
            ),
        },
    ];

    function onStateChange(params, downshiftStateAndHelpers) {
        if (!downshiftStateAndHelpers.isOpen) {
            setSearchCompanyInputValue("");
        } else if (params.hasOwnProperty("inputValue")) {
            setSearchCompanyInputValue(params.inputValue);
            if (params.inputValue.length >= 3) {
                companyController.getCompaniesByPatternAndRelationType(params.inputValue, "PARENT_COMPANIES").then(
                    (response) => {
                        setSearchCompanies(response.list.map((item) => {
                            const i: CompanySuggestion = {
                                item,
                                label: item.name,
                                value: item.guid,
                            };
                            return i;
                        }));
                    },
                );
            }
        }
    }

    function prepareCompanies(item: TableCompany, value) {
        const newCompanies = tableCompanies.map((c) => {
            if (c.item.guid === item.item.guid &&
                tableCompanies.indexOf(c) === tableCompanies.indexOf(item)) {
                c.checked = value;
            }
            return c;
        });
        if (showSelectedCompaniesAtTheTop) {
            setTableCompanies([...newCompanies.sort(checkedCompaniesSorter)]);
        } else {
            setTableCompanies(newCompanies);
        }
    }

    function orderSelectedCompaniesAtTheTop(value) {
        const atTheTop: boolean = value.target.checked;
        setShowSelectedCompaniesAtTheTop(atTheTop);
        if (atTheTop) {
            setTableCompanies(
                [...tableCompanies.filter((e) => e.checked)
                    .sort((a, b) => (a.item.name.toLowerCase() >= b.item.name.toLowerCase()) ? 1 : -1),
                    ...tableCompanies.filter((e) => !e.checked)
                        .sort((a, b) => (a.item.name.toLowerCase() >= b.item.name.toLowerCase()) ? 1 : -1)],
            );
        } else {
            setTableCompanies([...tableCompanies
                .sort((a, b) => (a.item.name.toLowerCase() >= b.item.name.toLowerCase()) ? 1 : -1)]);
        }
    }

    function onSelectedCompany(e) {
        if (e !== null) {
            const newCompanies = [...(tableCompanies)];
            newCompanies.push({
                item: e.item,
                checked: true,
                clientCode: "",
                clientName: name,
                clientNameKey: nameKey,
            } as TableCompany);
            if (showSelectedCompaniesAtTheTop) {
                setTableCompanies(newCompanies.sort(checkedCompaniesSorter));
            } else {
                setTableCompanies(newCompanies.sort((a, b) => a.item.name >= b.item.name ? 1 : -1));
            }
        }
    }

    function onInputTextChange(item: TableCompany, value: string, column: string) {
        const newCompanies = tableCompanies.map((c) => {
            if (item.item.guid === c.item.guid &&
                tableCompanies.indexOf(item) === tableCompanies.indexOf(c)) {
                switch (column) {
                    case "client_name":
                        c.clientName = value;
                        break;
                    case "client_code":
                        c.clientCode = value;
                        break;
                    case "client_name_key":
                        c.clientNameKey = value;
                        break;
                    case "nickname":
                        c.nickname = value;
                        break;
                }
            }
            return c;
        });
        setTableCompanies(newCompanies);
    }

    function deleteUnselected() {
        setTableCompanies(
            tableCompanies.filter((e) => e.checked),
        );
    }

    function deleteAll() {
        setTableCompanies([]);
    }

    function onSelectInputChange(value, actionMeta, item) {
        setTableCompanies(
            tableCompanies.map((c) => {
                if (item.item.guid === c.item.guid &&
                    tableCompanies.indexOf(item) === tableCompanies.indexOf(c)) {
                    c.brand = value;
                }
                return c;
            }),
        );
    }

    function onShowModalButtonPressed(value: TableCompany) {
        setSelectedCompany(value);
        setShowModal(true);
    }

    function deleteItemMedia(item: TableCompany) {
        setTableCompanies(
            tableCompanies.map((e) => {
                    if (e.item.guid + (e.brand != null ? e.brand.value : "") ===
                        item.item.guid + (item.brand != null ? item.brand.value : "")) {
                        e.existingMedia = undefined;
                        e.newMedia = undefined;
                    }
                    return e;
                },
            ),
        );
        setSelectedCompany(undefined);
    }

    function addComponentBrand(brandDescription: string) {
        setShowCreateNewBrandModal(false);
        addNewComponentBrand(brandDescription);
    }

    React.useEffect(() => {
        const map = {};
        let changed = false;
        tableCompanies.filter((e) => e.checked).forEach(
            (item) => {
                if (map[item.item.guid + (item.brand !== undefined ? item.brand.item.guid : "")]) {
                    changed = true;
                    return;
                }
                map[item.item.guid + (item.brand !== undefined ? item.brand.item.guid : "")] = true;
            },
        );
        setActionsAvailable(!changed);
        if (tableCompanies.every((v) => v.checked)) {
            setAllChecked(true);
            setIndeterminate(false);
        } else if (tableCompanies.some((v) => v.checked)) {
            setAllChecked(false);
            setIndeterminate(true);
        } else {
            setAllChecked(false);
            setIndeterminate(false);
        }
    }, [tableCompanies]);
    React.useEffect(() => {
        setComponentBrandSuggestions(
            componentBrands?.map(
                (item) => {
                    const suggestion: ComponentBrandSuggestion = {
                        item,
                        value: item.guid,
                        label: item.description,
                    };
                    return suggestion;
                },
            ),
        );
    }, [componentBrands]);
    React.useEffect(() => {
        translationController.getTranslationByTextKey(INFO_TRANSLATION_TEXT_KEY).then(
            (response) => {
                if (response.status === 200) {
                    setInfoMessage(response.content.englishSource);
                }
            },
        );
    }, []);

    return (
        <>
            <Form>
                {changeStep && (
                    <Row className="w-100 flex-row m-0">
                        <GeneralAlert
                            message={infoMessage}
                        />
                    </Row>
                )}
                <Form.Group>
                    <Row className="w-100 m-0">
                        {changeStep && (
                            <Col className="d-flex justify-content-start align-items-center w-100 p-0">
                                <Row>
                                    <div className="col-auto mr-auto">
                                        <AutoSuggest
                                            label={"Search company"}
                                            isInputDisabled={!actionsAvailable}
                                            inputValue={searchCompanyInputValue}
                                            suggestions={searchCompanies}
                                            itemToString={(item) => {
                                                return item ? item.label : "";
                                            }}
                                            onSelectedValueChange={onSelectedCompany}
                                            onStateChange={onStateChange}
                                        />
                                    </div>
                                    <div className="col-auto mr-auto">
                                    <span>
                                        <Checkbox
                                            onClick={orderSelectedCompaniesAtTheTop}
                                            checked={showSelectedCompaniesAtTheTop}
                                        />
                                        <span style={{marginLeft: "10px"}}>Show selected companies at the top</span>
                                    </span>
                                    </div>
                                </Row>
                            </Col>
                        )}
                        {
                            refreshCompaniesList &&
                            (
                                <Col className="d-flex justify-content-end align-items-center w-100 p-0">
                                    <Tooltip message={"Refresh companies list"} placement={"left"}>
                                        <Button
                                            iconProps={{glyph: icons.REFRESH}}
                                            onClick={refreshCompaniesList}
                                            styles={{root: {mr: 10, height: 50, width: 50}}}
                                            variant={"secondary"}
                                        />
                                    </Tooltip>
                                </Col>
                            )
                        }
                    </Row>
                </Form.Group>
                {/* Spacer */}
                <div style={{height: "20px"}}/>
                {/* Companies list */}
                {
                    tableCompanies !== undefined && tableCompanies.length > 0 && (
                        <Table striped={true} bordered={true} hover={true}>
                            <thead>
                            <tr>
                                {
                                    columns.map((col) => (
                                        <th key={col.key} style={col.style}>{col.render}</th>
                                    ))
                                }
                            </tr>
                            </thead>
                            <tbody>
                            {
                                tableCompanies.map((value) => (
                                    <tr key={value.item.guid + tableCompanies.indexOf(value)}>
                                        <td style={{verticalAlign: "middle"}}>
                                            <div style={{margin: "0 auto", textAlign: "center"}}>
                                                <Checkbox
                                                    checked={value.checked}
                                                    disabled={disabled}
                                                    onChange={(t) => prepareCompanies(
                                                        value,
                                                        t.target.checked,
                                                    )}
                                                />
                                            </div>
                                        </td>
                                        <td style={{verticalAlign: "middle"}}>
                                            {value.item.name}
                                        </td>
                                        <td style={{verticalAlign: "middle"}}>
                                            <TextInput
                                                value={value.clientName}
                                                disabled={disabled}
                                                onChange={(t) =>
                                                    onInputTextChange(value, t.target.value, "client_name")}
                                            />
                                        </td>
                                        <td style={{verticalAlign: "middle"}}>
                                            <TextInput
                                                value={value.clientNameKey}
                                                disabled={disabled}
                                                onChange={(t) =>
                                                    onInputTextChange(value, t.target.value, "client_name_key")}
                                            />
                                        </td>
                                        <td style={{verticalAlign: "middle"}}>
                                            <TextInput
                                                value={value.clientCode}
                                                disabled={disabled}
                                                onChange={(t) =>
                                                    onInputTextChange(value, t.target.value, "client_code")}
                                            />
                                        </td>
                                        <td style={{verticalAlign: "middle"}}>
                                            <SelectInput
                                                searchable={true}
                                                name="Component brand"
                                                value={value.brand}
                                                disabled={disabled}
                                                options={componentBrandSuggestions}
                                                onChange={(v, actionMeta) =>
                                                    onSelectInputChange(v, actionMeta, value)}
                                            />
                                        </td>
                                        <td style={{verticalAlign: "middle"}}>
                                            <TextInput
                                                value={value.nickname}
                                                disabled={disabled}
                                                onChange={(t) =>
                                                    onInputTextChange(value, t.target.value, "nickname")}
                                            />
                                        </td>
                                        <td style={{verticalAlign: "middle"}}>
                                            <Row>
                                                <div style={{margin: "0 auto", textAlign: "center"}}>
                                                    <Button
                                                        className={"mx-auto"}
                                                        disabled={disabled}
                                                        iconProps={value.newMedia === undefined ? (
                                                            value.existingMedia === undefined ?
                                                                services.uploadIconButton() :
                                                                services.removeIconButton()
                                                        ) : services.removeIconButton()
                                                        }
                                                        onClick={() => {
                                                            value.newMedia === undefined ? (
                                                                value.existingMedia === undefined ?
                                                                    onShowModalButtonPressed(value) :
                                                                    deleteItemMedia(value)
                                                            ) : deleteItemMedia(value);
                                                        }}
                                                    />
                                                    <br/>
                                                    <span
                                                        style={{
                                                            maxWidth: "75%",
                                                            display: "inline-block",
                                                        }}
                                                    >
                                                        {
                                                            value.newMedia === undefined ?
                                                                (value.existingMedia === undefined ?
                                                                    "" : value.existingMedia.name)
                                                                : value.newMedia.name
                                                        }
                                                    </span>
                                                </div>
                                            </Row>
                                        </td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </Table>
                    )
                }
                {/* Bottom nav buttons */}
                <Row className={"pb-4 pt-4"}>
                    {changeStep && (
                        <Col>
                            <div className={"text-left"}>
                                <Button
                                    onClick={deleteUnselected}
                                >
                                    Delete unselected
                                </Button>
                                {" "}
                                <Button
                                    variant="secondary"
                                    onClick={deleteAll}
                                >
                                    Delete all
                                </Button>
                            </div>
                        </Col>
                    )}
                    <Col>
                        <div className={"text-right"}>
                            {changeStep && (
                                <Button
                                    disabled={!actionsAvailable}
                                    onClick={() => changeStep(false)}
                                >
                                    Previous
                                </Button>
                            )}
                            {" "}
                            {!disabled && (
                                <Button
                                    variant="strong"
                                    disabled={!actionsAvailable}
                                    onClick={() => submit(localMedia)}
                                >
                                    {changeStep ? "Next" : "Save"}
                                </Button>
                            )}
                        </div>
                    </Col>
                </Row>

            </Form>
            <MediaModal
                show={showModal}
                onHide={() => setShowModal(false)}
                table={selectedCompany}
                localMedia={localMedia}
                setLocalMedia={setLocalMedia}
                arrayTables={tableCompanies}
                setArrayTables={setTableCompanies}
            />
            <CreateNewBrandModal
                show={showCreateNewBrandModal}
                onHide={() => {
                    setShowCreateNewBrandModal(false);
                    setState("");
                }}
                addBrand={addComponentBrand}
                errorManager={{
                    state: state,
                    setState: setState,
                }}
                findSimilarBrands={findSimilarBrands}
            />
            {/* Loading modal */}
            <LoadingModal loading={loading}/>
        </>
    );
};

function checkedCompaniesSorter(a: TableCompany, b: TableCompany): number {
    if (a.checked && !b.checked) {
        return -1
    } else if (!a.checked && b.checked) {
        return 1
    }
    return a.item.name.localeCompare(b.item.name);
}

export default CompanySection;
