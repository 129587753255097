import * as React from "react";

import "./styles.scss";

export type TdState =  "default" | "edited" | "invalid" | "empty" | "updating";

type TdProps = React.HTMLProps<HTMLTableDataCellElement> & {
    state?: TdState,
    ordinal?: "first" | "last" | number,
};

function TdDef({children, ordinal, state, ...props}: TdProps, ref: React.ForwardedRef<HTMLTableDataCellElement>) {

    const tdRef = React.useRef<HTMLTableDataCellElement>();
    React.useImperativeHandle(ref, () => tdRef.current, []);

    const tdStateClassName = state === "edited" ? "edited-data-cell"
        : state === "invalid" ? "invalid-data-cell"
        : state === "empty" ? "empty-data-cell"
        : state === "updating" ? "updating-date-cell"
        : "" ;

    const ordinalClassName = ordinal === "first" || ordinal === 1 ? "table-data-cell-stick-left"
        : ordinal === "last" ? "table-data-cell-stick-right"
        : "";

    const additionalClassName = ` ${tdStateClassName} ${ordinalClassName} ${props.className ?? ""}`;

    return (
        <td {...props} className={`table-data-cell ${additionalClassName}`} ref={tdRef}>
            {children}
        </td>
    );
}

export const Td = React.forwardRef(TdDef);
