import * as React from "react";

export function useThrottleCallback<C extends (...args: unknown[]) => unknown>(callback: C, delay: number) {

    const timeoutId = React.useRef<NodeJS.Timeout>();

    React.useEffect(() => {
        return () => clearTimeout(timeoutId.current);
    }, []);


    return (...args: Parameters<C>) => {
        if (timeoutId.current == null) {
            callback(...args);
            timeoutId.current = setTimeout(() => {
                timeoutId.current = null;
            }, delay);
        }
    }
}