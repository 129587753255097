import * as React from "react";
import {ThWithFilterView, FilterMultiValueSelectView} from "../filters";
import {useQueryParams} from "../../../../../QueryParams";
import {useForm} from "../../../../../../Form";
import {ComponentModel} from "../../../../../../models/component/componentModel";
import {findComponentModels} from "../../../../../Components";
import {pickGuid} from "../../../../../../utils/objects";
import {LOAD_ITEM_COUNT} from "../common";
import {CompanyComponentModelQueryParamsType} from "./types";
import {useDatastore} from "../../../../../../DataLoader";
import {COMPONENT_MODELS_DATA_KEY} from "./const";

type FilterForm = {
    values: ComponentModel[],
    valueName: string,
    specific: boolean,
}

export function CompanyComponentModelColumnHeaderView() {
    const datastore = useDatastore();
    const { params, update } = useQueryParams<CompanyComponentModelQueryParamsType>();

    const form = useForm<FilterForm>({
        defaultValues: {
            specific: params.componentModelName == null,
            values: [],
            valueName: params.componentModelName ?? "",
        },
    });

    function resetAll() {
        form.reset({specific: true, values: [], valueName: ""});
        update({page: 0, componentModel: [], componentModelName: null})
    }

    function apply(data: FilterForm) {
        datastore.set([COMPONENT_MODELS_DATA_KEY], {list: data.values});
        update({
            page: 0,
            componentModel: data.specific ? data.values.map(pickGuid) : [],
            componentModelName: data.specific? null : data.valueName,
        });
    }

    return (
        <ThWithFilterView heading={"Component models"} onApply={form.handleSubmit(apply)} onReset={resetAll}>
            <FilterMultiValueSelectView
                legend={"Component models"}
                form={form}
                listLoader={loadComponentModels}
                resettable={false}
            />
        </ThWithFilterView>
    )
}

function loadComponentModels(page: number, input: string) {
    return findComponentModels({
        size: LOAD_ITEM_COUNT,
        page,
        sort: ["name,asc"],
        criteria: {
            name: input,
            usePartialName: true,
        },
    });
}