import * as React from "react";
import {Company} from "../../../../../../../../models/company/companyModel";
import {useStatefulComponentModelChangeObserver} from "../../../../contexts/ComonentModelChangesContext";
import {AbstractCompanyComponentModel} from "../../../types";
import {isVirtualCompanyComponentModel} from "../../../utils";
import {AlreadyUsedComponentBrandsContext} from "./context";
import {action} from "./utils";

type AlreadyUsedComponentBrandsProviderProps = React.PropsWithChildren<{
    company: Company;
    companyComponentModels: AbstractCompanyComponentModel[],
}>;

export function AlreadyUsedComponentBrandsProvider(
    {
        companyComponentModels,
        company,
        children,
    }: AlreadyUsedComponentBrandsProviderProps,
) {

    const [updatedBrandsGuids] = useStatefulComponentModelChangeObserver(
        action,
        () => new Map<string, string>(),
        [company],
    );

    const brandsGuids = new Set(companyComponentModels
        .filter((ccm) => !isVirtualCompanyComponentModel(ccm) || ccm.__virtual === "add")
        .map((ccm) => {
            const componentBrandGuid = ccm.componentBrand?.guid ?? null;
            if (updatedBrandsGuids.has(componentBrandGuid)) {
                return updatedBrandsGuids.get(componentBrandGuid) ?? null;
            }
            return componentBrandGuid;
        }));

    return (
        <AlreadyUsedComponentBrandsContext.Provider value={brandsGuids}>
            {children}
        </AlreadyUsedComponentBrandsContext.Provider>
    );
}
