import {Company} from "../../../../../../../../models/company/companyModel";
import {ComponentModelChangeEvent} from "../../../../contexts/ComonentModelChangesContext/types";
import {changeWithKey} from "../../../../contexts/ComonentModelChangesContext/utils";

export function action(
    event: ComponentModelChangeEvent,
    prevState: Map<string, string>,
    company: Company,
): Map<string, string> {
    if (event.type !== "updating" && event.type !== "result") {
        return event.pending.filter(changeWithKey(
            company,
            undefined,
            "update-new-company-component-model", "update-new-company-component-model",
        )).reduce<Map<string, string>>((mapping, change) => {
            if (change.type !== "update-new-company-component-model" && change.type !== "update-existing-company-component-model") {
                return mapping;
            }
            if (!("componentBrand" in change.params[1])) {
                return mapping;
            }
            const {componentBrand: oldComponentBrand} = change.params[0];
            const {componentBrand: newComponentBrand} = change.params[1];
            return mapping.set(oldComponentBrand?.guid ?? null, newComponentBrand?.guid ?? null);
        }, new Map<string, string>());
    }
    return prevState;
}
