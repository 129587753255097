import * as React from "react";
import {getAllComponentBrands} from "../../api";
import {StatefulMap, useMap} from "../../../../../hooks/useMap";
import {ComponentBrand} from "../../../../../models/component/componentBrand";
import {identity} from "../../../../../utils/functions";
import {consumeJSONList} from "../../../../../utils/json";
import {toMap} from "../../../../../utils/maps";

interface ComponentBrandsContextType {
    componentBrands: StatefulMap<string, ComponentBrand>;
    refresh(): void;
    firstAvailableBrand(...notAvailableBrands: string[]): ComponentBrand;
}

const ComponentBrandsContext = React.createContext<ComponentBrandsContextType>(null);

export function useComponentBrands() {
    return React.useContext(ComponentBrandsContext);
}

export function ComponentBrandsProvider({children}: React.PropsWithChildren<unknown>) {
    const componentBrands = useMap<string, ComponentBrand>();

    function refresh() {
        getAllComponentBrands()
            .then(consumeJSONList(toMap<ComponentBrand, string, ComponentBrand>((cb) => cb.guid, identity)))
            .then(componentBrands.from)
            .catch(console.error); // todo handle better
    }

    function firstAvailableBrand(...brandsTakenGuid: string[]) {
        const alreadyUsedComponentBrandsGuids = new Set<string>(brandsTakenGuid);
        const firstNotTakenBrandGuid: string = [null].concat(componentBrands.toArray<string>(identity))
            .find((brandGuid) => !alreadyUsedComponentBrandsGuids.has(brandGuid));
        return componentBrands.get(firstNotTakenBrandGuid) ?? null;
    }

    React.useEffect(() => {
        refresh();
    }, []);

    return (
        <ComponentBrandsContext.Provider value={{componentBrands, refresh, firstAvailableBrand}}>
            {children}
        </ComponentBrandsContext.Provider>
    );
}
