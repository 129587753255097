import * as React from "react";
import {LocationCore} from "../../../../../../models/location/location";
import {Td} from "../../../../../common/DataTable";
import {Chip} from "../../../../../common/chips";
import {FACTORY_LOCATION_CATEGORY_GUID, WAREHOUSE_LOCATION_CATEGORY_GUID} from "../../../../../Locations";
import {Icon} from "../../../../../common/Icon";
import {Select} from "../../../../../common/form/Select";
import {Option} from "../../../../../common/form/Option";
import {useLazyList} from "../../../../../../hooks/useLazyList";
import {findLocations} from "../../../../../Locations/api";
import {Skeleton} from "../../../../../common/Skeleton";
import {range} from "../../../../../../utils/numbers";
import {consumeTargetValue} from "../../../../../../utils/eventsHandling";
import {useDebounceValue} from "../../../../../../hooks/useDebounceValue";

import "./styles.scss";

import {useFormContext} from "../../../../../../Form";
import {Field} from "../../../../../common/form/Field";
import {Input} from "../../../../../common/form/Input";
import {Thumbnail} from "../../../../../common/Thumbnail";

const PAGE_SIZE = 20;
const DEBOUNCE_DELAY = 600;

type DefaultWarehouseCellViewProps = {
    location: LocationCore,
    companyGuid: string,
}

export function DefaultWarehouseCellView({location, companyGuid}:DefaultWarehouseCellViewProps) {
    if (location == null) {
        return (
            <Td>
                <span className={"empty-cell-fallback"}>No warehouse</span>
            </Td>
        );
    }

    const {register, formId, state} = useFormContext();

    const {list, loading, hasMore, reload, input, total} = useLazyList<string, LocationCore>({
        loader({page, input}) {
            return findLocations({
                page,
                size: PAGE_SIZE,
                sort: ["description,desc"],
                criteria: {
                    code: input,
                    usePartialCode: true,
                    companies: {
                        guids: [companyGuid],
                    },
                    type: {
                        categories: {
                            guids: [WAREHOUSE_LOCATION_CATEGORY_GUID, FACTORY_LOCATION_CATEGORY_GUID],
                        },
                    }
                },
            });
        },
        loadOnMount: false,
    });

    const [inputText, setInputText] = React.useState<string>("");
    const [isOpen, setIsOpen] = React.useState(false);
    const debounceInputText = useDebounceValue(inputText, DEBOUNCE_DELAY);

    function loadLocations(event) {
        setIsOpen(event.newState === "open");
        if (event.newState === "open" && (inputText !== input || total === 0)) {
            reload("");
            setInputText("");
        }
    }

    React.useEffect(() => {
        if (!loading && isOpen) {
            reload(debounceInputText);
        }
    }, [debounceInputText]);


    return (
        <Td
            className={"default-warehouse-cell-view"}
            state={state().changedFields.includes(`${companyGuid}/defaultWarehouse`) ? "edited": "default"}
        >
            <Select form={formId} {...register(`${companyGuid}/defaultWarehouse`)} onBeforeToggle={loadLocations}>
                <Field className={"default-warehouse-display-field"}/>
                <menu className={"location-list-dropdown"}>
                    <header>
                        <Input
                            type={"text"}
                            value={inputText}
                            placeholder={"Filter by code"}
                            onChange={consumeTargetValue(setInputText)}
                        />
                    </header>
                    <ul>
                        <LocationOption location={location}/>
                        {list.filter(({guid}) => guid !== location.guid).map((l) => {
                            return (
                                <LocationOption key={l.guid} location={l}/>
                            );
                        })}
                        {(hasMore() || loading) && range(PAGE_SIZE).map((i) => {
                            return (
                                <li key={i} className={"option-skeleton"}>
                                    <div className={"location-option-details"}>
                                        <h5><Skeleton type={"text"}/></h5>
                                        <p><Skeleton type={"text"}/></p>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </menu>
            </Select>
        </Td>
    );
}


type LocationOptionProps = {
    location: LocationCore,
};

function LocationOption({location}: LocationOptionProps) {

    const chip = (
        <Chip>
            <Thumbnail>
                <Icon name={getIconName(location?.type?.locationCategory?.guid)}/>
            </Thumbnail>
            {location.code}
        </Chip>
    );

    return (
        <Option value={location.guid} fieldDisplay={chip}>
            <div className={"location-option-details"}>
                <h5>{location.code} - {location.description ?? <span className={"italic"}>No description</span>}</h5>
                <p>{location.address?.fullAddress ?? <span className={"italic"}>No address</span>}</p>
            </div>
        </Option>
    )
}

function getIconName(locationCategoryGuid: string) {
    return locationCategoryGuid === WAREHOUSE_LOCATION_CATEGORY_GUID ? "warehouse"
        : locationCategoryGuid === FACTORY_LOCATION_CATEGORY_GUID ? "factory"
        : "pin_drop";
}
