import * as React from "react";
import {useAnchor} from "../../../../hooks/useAnchor";
import {usePopover} from "../../../../hooks/usePopover";
import {OptionControllerProvider, useOptionController} from "../Option";
import {Icon} from "../../Icon";
import {SelectComponent} from "./types";
import {Field} from "../Field";
import {HTMLElementValue} from "../../types";

import "./styles.scss";
import {useSyntheticEventEmitter} from "../../../../hooks/useSyntheticEventEmitter";
import {createSlots} from "../../../../utils/components";

type SelectProps = React.HTMLProps<HTMLInputElement> & {
    onToggle?(event: Event): void,
    onBeforeToggle?(event: Event): void,
    values?: HTMLElementValue[],
};

export function SelectDef(props: SelectProps, ref: React.ForwardedRef<SelectComponent>) {

    const {name, value, values, children, form, onChange, onToggle, onBeforeToggle} = props;

    const {inputRef: hiddenInputRef, emit} = useSyntheticEventEmitter({
        type: "input",
        eventInitDict: {
            bubbles: true,
        },
    });

    const [fieldDisplay, setFieldDisplay] = React.useState<React.ReactNode>(null);

    const optionsController = useOptionController({
        multiple: false,
        values: values ?? ( value ? [value] : undefined),
        onChange(value) {
            hiddenInputRef.current.value = value;
            setFieldDisplay(optionsController.options.get(hiddenInputRef.current.value).display);
            emit();
        },
        isSelected(value) {
            return hiddenInputRef.current?.value === value;
        }
    });

    const {anchorRef, anchor} = useAnchor<HTMLTableDataCellElement>();
    const {toggle, popoverRef} = usePopover({
        position: {
            top: anchor("bottom"),
            left: anchor("left"),
        },
    });

    React.useImperativeHandle(ref, () => {
        return {
            type: "select-component",
            hiddenInput: hiddenInputRef.current,
            dropdownMenu: popoverRef.current,
            get value() {
                return hiddenInputRef.current.value;
            },
            set value(newValue) {
                hiddenInputRef.current.value = newValue;
                setFieldDisplay(optionsController.options.get(hiddenInputRef.current.value).display);
            }
        };
    }, []);

    React.useEffect(() => {
        popoverRef.current.addEventListener("beforetoggle", onBeforeToggle);
        return () => {
            if (popoverRef.current != null) {
                popoverRef.current.removeEventListener("beforetoggle", onBeforeToggle);
            }
        };
    }, [onBeforeToggle]);


    React.useEffect(() => {
        popoverRef.current.addEventListener("toggle", onToggle);
        return () => {
            if (popoverRef.current != null) {
                popoverRef.current.removeEventListener("toggle", onToggle);
            }
        };
    }, [onToggle]);


    const slots = createSlots(children, {
        menu: "menu",
        field: Field,
        rest: "*",
    });


    const {className: dropdownClass, children: dropdownChildren} = slots.menu?.props ?? {};
    const {className: fieldClass} = slots.field?.props ?? {};

    return (
        <>
            <input type={"hidden"} name={name} form={form} ref={hiddenInputRef} onInput={onChange}/>
            <span className={`select-field ${fieldClass?? ""}`} ref={anchorRef} onClick={toggle}>
                {fieldDisplay}
                <Icon className={"select-field-dropdown-arrow"}  name={"arrow_drop_down"}/>
            </span>
            {/* @ts-ignore:disable-next-line */}
            <menu className={`select-field-dropdown ${dropdownClass ?? ""}`} popover={"auto"} ref={popoverRef}>
                <OptionControllerProvider {...optionsController}>
                    {dropdownChildren ?? children}
                </OptionControllerProvider>
            </menu>
        </>
    );
}


export const Select = React.forwardRef(SelectDef);


