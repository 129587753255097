import {AutoSuggest, Button, Checkbox, DateRangePicker, H3, H6, Modal, SelectInput,} from "@danfoss/etui-core";
import {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import Services from "../../../controllers/utils/Services";
import {companyController, movementController, MovType} from "../Transfer";
import React = require("react");

const RightColumn = (
    {
        movementType,
        devices,
        packages,
        packagesDevices,
        equipment,
        completeEquipment,
        validEquipment,
        invalidDevices,
        invalidPackages,
        invalidEquipment,
        csvFile,
        deviceOnly, setDeviceOnly,
        companyWarehouses,
        selectedCompany,
        selectedLocation, setSelectedLocation,
        shipmentDate, setShipmentDate,
        setWaitingForResponse,
        resetParams,
    }) => {

    const services: Services = new Services();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [hours, setHours] = useState([]);

    const [selectedWarehouse, setSelectedWarehouse] = useState(null);

    useEffect(() => {
        setSelectedWarehouse(null);
    }, [selectedCompany?.guid]);

    const movementRequest = async () => {

        const showNotification = (response) => {
            setWaitingForResponse(false);

            if (response.status === 500) {
                services.errorNotification(" ", "Server error, movement aborted");
            } else if (response.status !== 200) {
                response.json().then((data) => {
                    services.errorNotification(" ", "Movement aborted. " + data.message);
                });
            } else {
                resetParams();
                services.successNotification(
                    "Movement started",
                    "You can follow its progress in the relative screen",
                );
                response.json().then((data) => {
                    console.log(data);
                    // TODO gestire il ritorno dell'API (con una pagina di monitoraggio delle movimentazioni in corso)
                });
            }
        };

        switch (movementType) {
            case MovType.DevicesOrEquipments: {
                setWaitingForResponse(true);

                const response = await movementController.moveDevices(
                    selectedCompany.guid,
                    selectedLocation.code,
                    devices.map((device) => device.deviceCode),
                    shipmentDate,
                    deviceOnly,
                );
                showNotification(response);
                break;
            }

            case MovType.Packages: {
                setWaitingForResponse(true);
                const response = await movementController.movePackages(
                    selectedCompany.guid,
                    selectedLocation.code,
                    packages.map((pkg) => pkg.code),
                    shipmentDate,
                );
                showNotification(response);
                break;
            }

            case MovType.InstallAndMove: {
                setWaitingForResponse(true);

                const response = await movementController.installAndMoveDevices(
                    csvFile,
                    selectedCompany === undefined ? null : selectedCompany.guid,
                    selectedLocation === undefined ? null : selectedLocation.code,
                    shipmentDate === undefined ? null : shipmentDate,
                );
                showNotification(response);
                break;
            }
        }
    };

    return (
        <Col className="movimentationsColumn col-5">
            {
                (devices.length !== 0 || packages.length !== 0 || equipment.length !== 0) && (
                    <>
                        {
                            (
                                movementType === MovType.InstallAndMove
                                && completeEquipment !== equipment.length
                                || movementType !== MovType.InstallAndMove
                            ) &&
                            (
                                <div className="movement-fields">
                                    <SelectInput
                                        name="destinationLocation"
                                        label="Destination location..."
                                        options={companyWarehouses}
                                        defaultValue={selectedWarehouse}
                                        value={selectedWarehouse}
                                        onChange={(input: any) => {
                                            setSelectedWarehouse(input);
                                            setSelectedLocation(input.item);
                                        }}
                                        searchable={true}
                                        styles={{root: {width: "100%", zIndex: 3, my: 2}}}
                                    />
                                    <Row>
                                        <Col className="col-8">
                                            <DateRangePicker
                                                label="Shipment date..."
                                                startDate={shipmentDate}
                                                dateFormat={"DD MMMM YYYY"}
                                                onChange={(p) => {
                                                    const startDate = p.startDate.setHours(0, 0, 0, 0);
                                                    const now = new Date().setHours(0, 0, 0, 0);
                                                    if (startDate <= now) {
                                                        const hrs = [];
                                                        if (startDate === now) {
                                                            // If the day selected is today
                                                            // filling with only the past hours
                                                            for (let i = 1; i <= new Date().getHours(); i++) {
                                                                hrs.push({label: `${i}:00`, value: i});
                                                            }
                                                        } else {
                                                            for (let i = 1; i <= 24; i++) {
                                                                hrs.push({label: `${i}:00`, value: i});
                                                            }
                                                        }
                                                        setHours(hrs);
                                                        setShipmentDate(p.startDate);
                                                    }
                                                }}
                                                disableRange={true}
                                            />
                                        </Col>
                                        <Col className="col-4 pl-0">
                                            <SelectInput
                                                name="shipmentTime"
                                                label="Shipment time..."
                                                options={shipmentDate !== undefined ? hours : []}
                                                onChange={(input: any) => {
                                                    shipmentDate.setHours(input.value);
                                                }}
                                                styles={{root: {width: "100%", zIndex: 2}}}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            )
                        }
                        <Row>
                            { // Showing the deviceOnly checkbox for device movements
                                (movementType === MovType.DevicesOrEquipments) &&
                                (
                                    <>
                                        <H6 className="my-auto ml-3">Devices only </H6>
                                        <div className="my-auto ml-2">
                                            <Checkbox
                                                name="deviceOnly"
                                                onChange={(e) => {
                                                    setDeviceOnly(e.target.checked);
                                                }}
                                            />
                                        </div>
                                    </>
                                )
                            }
                            <Button
                                className="my-2 ml-auto mr-3"
                                variant="strong"
                                disabled={
                                    !((devices.length > 0 || movementType !== MovType.DevicesOrEquipments)
                                        && selectedCompany !== undefined
                                        && selectedLocation !== undefined
                                        && shipmentDate !== undefined
                                        && invalidDevices === 0
                                        && invalidPackages === 0
                                        && invalidEquipment === 0
                                        ||
                                        (movementType === MovType.InstallAndMove
                                            && validEquipment === equipment.length)
                                    )
                                }
                                onClick={() => {
                                    setIsModalOpen(true);
                                }}
                            >
                                Start movement
                            </Button>
                        </Row>
                        <Modal
                            isOpen={isModalOpen}
                            actions={
                                (
                                    <>
                                        <Button
                                            textCenter={true}
                                            onClick={() => setIsModalOpen(false)}
                                            styles={{
                                                root: {mr: "0.5rem"},
                                            }}
                                        >
                                            No
                                        </Button>
                                        <Button
                                            variant="primary"
                                            textCenter={true}
                                            onClick={() => {
                                                movementRequest();
                                                setIsModalOpen(false);
                                            }}
                                        >
                                            Yes
                                        </Button>
                                    </>
                                )
                            }
                        >
                            <H3>Confirm movement</H3>
                            <H6 className="mt-3">Are you sure you want to proceed with the movement of
                                {movementType === MovType.DevicesOrEquipments ? ` ${devices.length} ` : ""}
                                {movementType === MovType.Packages ? ` ${packagesDevices} ` : ""}
                                {movementType === MovType.InstallAndMove ? ` ${equipment.length} ` : ""}
                                entities?</H6>
                        </Modal>
                    </>
                )
            }
        </Col>
    );
};
export default RightColumn;
