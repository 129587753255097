import * as React from "react";
import {CompanyComponentModel} from "../../../../../../models/company/companyModel";
import {Td} from "../../../../../common/DataTable";
import {toMap} from "../../../../../../utils/maps";
import {Chip} from "../../../../../common/chips";
import {createPartial} from "../../../../../../utils/arrays";
import {Button} from "../../../../../common/Button";
import {Icon} from "../../../../../common/Icon";


type CompanyComponentModelsCellViewProps = {
    companyComponentModels: CompanyComponentModel[],
};

export function CompanyComponentModelsCellView({companyComponentModels}: CompanyComponentModelsCellViewProps) {
    if (companyComponentModels == null || companyComponentModels.length === 0) {
        return (
            <Td><span className={"empty-cell-fallback"}>No custom component models</span></Td>
        );
    }

    const componentModels = Array.from(
        toMap(companyComponentModels, (ccm) => ccm.componentModel.guid, (ccm) => ccm.componentModel).values()
    );


    const visible = createPartial(componentModels, (x) => x < 50, (sum, {name}) => sum + name.length, 0);

    return (
        <Td className={"embedded-list-cell-view"}>
            <div>
                <ul>
                    {visible.map((cm) => {
                        return (
                            <Chip key={cm.guid}>{cm.name}</Chip>
                        );
                    })}
                    {visible.length < componentModels.length && (
                        <li className={"more-entities"}> +{componentModels.length - visible.length} </li>
                    )}
                </ul>
                <Button variant={"tertiary"} className={"show-on-row-hovered"}>
                    <Icon name={"menu_open"}/>
                </Button>
            </div>
        </Td>
    );
}