import * as React from "react";
import {Table, Td, Th, Tr} from "../../../common/DataTable";
import {range} from "../../../../utils/numbers";
import {Skeleton} from "../../../common/Skeleton";
import {Company} from "../../../../models/company/companyModel";
import {
    CompanyAlarmTypeColumnHeaderView,
    CompanyAlarmTypesCellView, CompanyComponentModelColumnHeaderView,
    CompanyComponentModelsCellView, CompanyComponentTypeColumnHeaderView, CompanyComponentTypesCellView,
    CompanyCountryCellView,
    CompanyCurrencyCellView, CompanyNameCellView, CompanyNameColumnHeaderView, DefaultWarehouseCellView,
    CompanyParentColumnHeaderView,
    CompanyTypeColumnHeaderView, CreatedAtColumnHeadView, CreatedAtCellView
} from "./columns";
import {useFormContext} from "../../../../Form";
import {Input} from "../../../common/form/Input";

import "./styles.scss";

type CompaniesTableViewProps = {
    loading: boolean,
    companies: Company[]
};

export function CompaniesTableView({companies, loading}: CompaniesTableViewProps) {

    const {register, formId, state} = useFormContext();

    const tBody = loading ? range(20).map((i) => <Skeleton key={i} type={"table-row"} size={17}/>)
        :  companies.map((c) => {
            return (
                <Tr key={c.guid}>
                    <CompanyNameCellView companyGuid={c.guid}/>
                    <Td>{c.type?.name}</Td>
                    <Td>{c.parentCompany?.name}</Td>
                    <Td>{c.companyCode}</Td>
                    <CompanyCountryCellView countryISO3={c.country}/>
                    <CompanyCurrencyCellView currencyGuid={c.currencyGuid}/>
                    <Td>{c.vatCode}</Td>
                    <DefaultWarehouseCellView location={c.defaultWarehouse} companyGuid={c.guid}/>
                    <Td state={state().changedFields.includes(`${c.guid}/pingDevices`) ? "edited": "default"}>
                        <label>
                            <Input form={formId} type={"checkbox"} {...register(`${c.guid}/pingDevices`)}/>
                        </label>
                    </Td>
                    <Td state={state().changedFields.includes(`${c.guid}/lockEnabled`) ? "edited": "default"}>
                        <label>
                            <Input form={formId} type={"checkbox"} {...register(`${c.guid}/lockEnabled`)}/>
                        </label>
                    </Td>
                    <Td state={state().changedFields.includes(`${c.guid}/integrationFlowEnabled`) ? "edited": "default"}>
                        <label>
                            <Input form={formId} type={"checkbox"} {...register(`${c.guid}/integrationFlowEnabled`)}/>
                        </label>
                    </Td>
                    <Td state={state().changedFields.includes(`${c.guid}/marketingConfigurationEnabled`) ? "edited": "default"}>
                        <label>
                            <Input form={formId} type={"checkbox"} {...register(`${c.guid}/marketingConfigurationEnabled`)}/>
                        </label>
                    </Td>
                    <Td state={state().changedFields.includes(`${c.guid}/genericProductEnabled`) ? "edited": "default"}>
                        <label>
                            <Input form={formId} type={"checkbox"} {...register(`${c.guid}/genericProductEnabled`)}/>
                        </label>
                    </Td>
                    <CompanyComponentTypesCellView company={c} companyComponentTypes={c.companyComponentTypes}/>
                    <CompanyComponentModelsCellView companyComponentModels={c.companyComponentModels}/>
                    <CompanyAlarmTypesCellView companyAlarmTypes={c.companyAlarmTypes}/>
                    <CreatedAtCellView date={new Date(c.createdAt)}/>
                </Tr>
            );
        });

    return (
        <Table className={"companies-table"}>
            <thead>
                <tr>
                    <CompanyNameColumnHeaderView/>
                    <CompanyTypeColumnHeaderView/>
                    <CompanyParentColumnHeaderView/>
                    <Th>Code</Th>
                    <Th>Country</Th>
                    <Th>Currency</Th>
                    <Th>VAT</Th>
                    <Th>Default warehouse </Th>
                    <Th>Ping device enabled</Th>
                    <Th>Lock enabled</Th>
                    <Th>Integration flow enabled</Th>
                    <Th>Marketing configuration enabled</Th>
                    <Th>Generic product enabled</Th>
                    <CompanyComponentTypeColumnHeaderView/>
                    <CompanyComponentModelColumnHeaderView/>
                    <CompanyAlarmTypeColumnHeaderView/>
                    <CreatedAtColumnHeadView/>
                </tr>
            </thead>
            <tbody>{tBody}</tbody>
        </Table>
    );
}