import * as React from "react";
import {useRefInit} from "../hooks/useRefInit";
import {flatten, mergeDeep, objectValuePathExists, omit, pick, update} from "../utils/objects";
import {checkInput, setInputValue} from "../utils/DOM";
import {HTMLElementValue} from "../presentation/common/types";
import {useMap} from "../hooks/useMap";
import {useSet} from "../hooks/useSet";
import {preventDefault} from "../utils/eventsHandling";
import {identity} from "../utils/functions";
import {FormState, RegisterOptions, UseFormType} from "./types";

type InputRegister<I, R> = {
    name: string,
    ref: React.RefCallback<R>,
    onChange(e: React.ChangeEvent<I>): void,
};

export type FormInputRegister<I = HTMLInputElement, R = I> = (name: string) =>  any;


type FormChange = {
    name: string,
    value: HTMLElementValue,
    defaultValue: HTMLElementValue,
    event: React.ChangeEvent<HTMLInputElement>,
};

type UseFormProps<T> = {
    defaultValues: T,
    listen?(change: FormChange): void,
};


export function useForm_legacy<T extends object>(props: UseFormProps<T>): UseFormType<T> {
    const values = React.useRef<T>({} as T);

    const inputsRefs = useRefInit(() => new Map<string, HTMLInputElement | Map<HTMLElementValue, HTMLInputElement>>());
    const watching = useRefInit(() => ({
        list: new Set<string>(),
        everything: false,
    }));


    const [watchedInput, setWatchedInputs] = React.useState<T>();

    // const [defaultValues, setDefaultValues] = React.useState<T>({} as T);
    //
    // React.useLayoutEffect(() => {
    //     setDefaultValues((prevValues) => mergeDeep(prevValues, props.defaultValues))
    // }, [props.defaultValues]);


    React.useLayoutEffect(() => {
        inputsRefs.current.forEach((inputOrMap, name) => {
            const path = name.split("/");
            const defaultValue = pick(props.defaultValues, path);
            const currentValue = pick(values.current, path);
            const valueAlreadySet = objectValuePathExists(values.current, path);
            if (inputOrMap == null) {
                return;
            } else if (inputOrMap instanceof Map) {
                inputOrMap.forEach((checkbox) => {
                    const currentValues = pick(values.current ?? {}, path);
                    if (Array.isArray(currentValues) && currentValues.includes(checkbox.value) === checkbox.checked) {
                        return;
                    }
                    const isChecked = Array.isArray(defaultValue) ? defaultValue.includes(checkbox.value) : Boolean(defaultValue);
                    checkInput(checkbox, isChecked);
                });
                return;
            } else if (!valueAlreadySet && inputOrMap.type === "checkbox") {
                const isChecked = Boolean(defaultValue);
                if (!isChecked) {
                    values.current = update(values.current, path, isChecked);
                }
                checkInput(inputOrMap, isChecked);
            } else if (!valueAlreadySet) {
                setInputValue(inputOrMap, defaultValue);
            } else if (currentValue !== inputOrMap.value) {
                setInputValue(inputOrMap, currentValue);
            }
        });
    }, [props.defaultValues]);

    return {
        register(name: string): InputRegister<any, any> {
            return {
                name,
                ref(instance: HTMLInputElement | null) {
                    if (instance == null) {
                        return;
                    }
                    //if (!inputsRefs.current.has(name)) {
                        inputsRefs.current.set(
                            name,
                            instance.type === "checkbox" && (instance.value ?? "on") !== "on" ? new Map() : instance,
                        );
                    //}
                    if (instance.type === "checkbox" && (instance.value ?? "on") !== "on") {
                        const checkboxes = inputsRefs.current.get(name);
                        if (checkboxes instanceof Map && !checkboxes.has(instance.value)) {
                            checkboxes.set(instance.value, instance);
                        }
                    }
                },
                onChange(e: React.ChangeEvent<HTMLInputElement>) {
                    const path = name.split("/");
                    const newValue = e.target.type === "checkbox" ? e.target.checked
                        : e.target.type === "number" ? e.target.valueAsNumber
                        : e.target.value;
                    const isCheckboxWithValue = e.target.type === "checkbox" && (e.target.value ?? "on") !== "on";
                    if(isCheckboxWithValue) {
                        const value: HTMLElementValue[] = pick(values.current, path);
                        const tmp = (value == null
                            ? pick<T, HTMLElementValue[]>(props.defaultValues, path)
                            : value
                        ).filter((v) => v !== e.target.value);

                        values.current = update(
                            values.current,
                            path,
                            e.target.checked ? tmp.concat(e.target.value) : tmp
                        );
                    } else {
                        values.current = update(values.current, path, newValue);
                    }
                    if (watching.current.everything || watching.current.list.has(name)) {
                        setWatchedInputs((prev) => {
                            return update(
                                prev ?? props.defaultValues,
                                path,
                                isCheckboxWithValue ? pick(values.current, path) : newValue
                            );
                        });
                    }
                }
            }
        },
        watch() {
            // todo add possibility to choose what property to watch
            watching.current.everything = true;
            return watchedInput ?? props.defaultValues as any; // fixme should deep merge
        },
        handleSubmit(handler) {
            return (e) => {
                e.preventDefault();
                handler(mergeDeep([props.defaultValues, values.current], { arrayMerge: "keep-right"}), e);
            }
        },
        reset() {
        },
        state(): FormState<T> {
            return null
        },
        setValue<K extends keyof T>(name, value) {
        },
    }
}


type Props<T extends object> = {
    defaultValues?: (() => Promise<T>) | T,
    values?: T,
    formId?: string,
};


export function useForm<T extends object>({defaultValues, values, formId}: Props<T>): UseFormType<T> {
    const [_defaultValues, _setDefaultValues] = React.useState<React.MutableRefObject<T>>({current: null});
    const [_values, _setValues] = React.useState<React.MutableRefObject<T>>({current: null});

    const valuesWatched = useSet();
    const watchEverything = React.useRef(false);

    const inputsMap = useMap<string, HTMLInputElement>();

    const modifiedFields = useSet<keyof T>();

    function setValues(newValues?: Partial<T>) {
        // Set input based on default_values
        Array.from(inputsMap.ref()).map(([key, input]) => {
            if (!(input.name in newValues)) {
                return;
            }
            const inputDefaultValue = newValues?.[input.name];
            if (input.type === "checkbox" && Array.isArray(inputDefaultValue)) {
                input.checked = inputDefaultValue.findIndex((v) => `${input.name}/${v}` === key) > -1;
            } else if (input.type === "checkbox" && typeof inputDefaultValue === "boolean") {
                input.checked = inputDefaultValue;
            } else if (input.type === "radio") {
                input.checked = key === `${input.name}/${inputDefaultValue}`;
            } else {
                input.value = inputDefaultValue;
            }
        });
    }

    async function initDefaultValues() {
        if (defaultValues == null) {
            return;
        }
        const obj = typeof defaultValues === "object"? defaultValues :  await defaultValues();
        _setValues({current: obj});
        _setDefaultValues({current: obj});
    }

    React.useLayoutEffect(() => {
        initDefaultValues();
    }, []);

    React.useEffect(() => {
        // Change values
        if (values != null) {
            _setDefaultValues({current: mergeDeep([values, _defaultValues.current ?? {}])});
            _setValues({current: values});
        }
    }, [values]);

    function changeValue(name: keyof T, path: string[], newValue: unknown, options: RegisterOptions<any> ) {
        if (watchEverything.current) {
            _setValues({current: update(_values.current, path, newValue)});
            watchEverything.current = false;
        } else if (valuesWatched.has(name)) {
            _values.current = update(_values.current, path, newValue);
            valuesWatched.delete(name);
        } else {
            _values.current = update(_values.current, path, newValue);
        }
        // todo add a check so not to have wasted re-renders
        if (!modifiedFields.has(name)) {
            modifiedFields.add(name)
        } else if (newValue instanceof Array ){
            // fixme should compare both arrays
        } else if (newValue === getValue(pick(_defaultValues.current, path), options)) {
            modifiedFields.delete(name);
        }
        // todo remove if like original
    }

    return {
        formId,
        register<I extends HTMLInputElement>(name, options) {
            const path = name.split("/");
            const isArray = Array.isArray(pick(_defaultValues.current, path));
            return {
                name,
                ref(instance) {
                    if (instance == null) {
                        return;
                    }

                    const nv = `${name}/${instance.value}`;
                    const key = (instance.type === "checkbox" && isArray) || instance.type === "radio" ? nv : name;

                    inputsMap.ref().set(key, instance);

                    const value = getValue(pick(_values.current, path), options);
                    const state = (Array.isArray(value) && value.findIndex((v) => v === instance.value) > -1)
                        || (value === true)
                        || (key === `${instance.name}/${value}`);

                    const isRadioOrCheckbox = instance.type === "checkbox" || instance.type === "radio";
                    if (isRadioOrCheckbox && state !== instance.checked) {
                        instance.checked = state;
                    } else if (!isRadioOrCheckbox && typeof value === "string" && instance.value !== value){
                         instance.value = value;
                    }
                },
                onChange(e: React.ChangeEvent<I>) {
                    const newValue = getTargetValue(e.target, isArray, pick(_values.current, path), options);
                    changeValue(name, path, newValue, options);
                    // if (watchEverything.current) {
                    //     _setValues({current: update(_values.current, path, newValue)});
                    //     watchEverything.current = false;
                    // } else if (valuesWatched.has(name)) {
                    //     _values.current = update(_values.current, path, newValue);
                    //     valuesWatched.delete(name);
                    // } else {
                    //     _values.current = update(_values.current, path, newValue);
                    // }
                    // // todo add a check so not to have wasted re-renders
                    // if (!modifiedFields.has(name)) {
                    //     modifiedFields.add(name)
                    // } else if (newValue instanceof Array ){
                    //     // fixme should compare both arrays
                    // } else if (newValue === getValue(pick(_defaultValues.current, path), options)) {
                    //     modifiedFields.delete(name);
                    // }
                    // todo remove if like original
                }
            }
        },
        watch(names?) {
            if (names != null) {
                names.forEach((name) => valuesWatched.ref().add(name));
                return names.map((name) => pick(_values.current, name.split("/")) ?? pick(_defaultValues.current, name.split("/"))); // todo nested values
            }
            watchEverything.current = true;
            return {..._defaultValues.current, ..._values.current};
        },
        handleSubmit(handler: (data: T, e: React.FormEvent) => void): React.FormEventHandler {
            return preventDefault((e) => {
                handler({..._defaultValues.current, ..._values.current}, e); // fixme deepmerge
            });
        },
        reset(params?) {
            const newValues = Array.isArray(params)
                ? omit(_defaultValues.current, (key) => !params.includes(key))
                : params ?? _defaultValues.current;

            setValues(newValues);
            _defaultValues.current = newValues
            _values.current = newValues

            if (watchEverything.current || !valuesWatched.isEmpty()) {
                watchEverything.current = false;
                valuesWatched.clear();
            }
        },
        state(): FormState<T> {
            // todo add a check so not to have wasted re-renders
            return {
                hasChanged: !modifiedFields.isEmpty(),
                changedFields: modifiedFields.toArray(),
            }
        },
        setValue(name: keyof T | string, value) {
            const path = (name as string).split("/");
            changeValue(name as keyof T, path, value, {});
        }
    };
}


function getValue(value: unknown, options: RegisterOptions<any>) {
    const mapper = options?.stringify ?? identity;
    return Array.isArray(value) ? value.map(mapper) : mapper(value);
}

function getTargetValue(target: HTMLInputElement, isArray: boolean, prevValue: unknown, options: RegisterOptions<any>) {
    const value = target.value;
    if (target.type === "checkbox" && isArray && target.checked) {
        const newValue = options?.valueAs?.(value) ?? value;
        return Array.isArray(prevValue) ? [...prevValue, newValue] : [newValue];
    } else if (target.type === "checkbox" && isArray) {
        return Array.isArray(prevValue) ? prevValue.map(options?.stringify ?? identity).filter((v) => v !== value) : [];
    } else if (target.type === "checkbox") {
        return target.checked;
    }
    return options?.valueAs?.(value) ?? value;
}