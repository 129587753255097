import * as React from "react";
import {Dialog} from "../../common/Dialog";
import {useLocation, useNavigate} from "react-router-dom";
import {LocationCore} from "../../../models/location/location";
import {constant, doNothing} from "../../../utils/functions";
import {Company} from "../../../models/company/companyModel";
import {consumeJSONList} from "../../../utils/json";
import {Button} from "../../common/Button";
import {findFactoriesAndWarehouses, updateCompany} from "../api";

import "./styles.scss";

type RouteLocationState = {
    company: Company,
    currentDefaultWarehouse: LocationCore,
};

export function EditDefaultWarehouse() {

    const location = useLocation();

    const { company, currentDefaultWarehouse }: RouteLocationState = location.state;

    const navigate = useNavigate();

    const [locations, setLocations] = React.useState<LocationCore[]>([]);

    const [selected, setSelected] = React.useState<string>(currentDefaultWarehouse?.guid);

    // const goBack = constant(navigate, origin, { state: {reFetch: false}});


    // navigate(-1, {replace: true});

    React.useEffect(() => {
        if (company == null) {
            return;
        }
        findFactoriesAndWarehouses(company)
            .then(({list}) => {
                //setLocations(list);
                setLocations(list.sort((a) => a.guid === selected ? -1 : 0));
            })

    }, [company]);

    async function save() {
        const updatedCompany = await updateCompany(company, {defaultWarehouse: selected as any});
        console.log(updatedCompany);
        navigate(-1);
    }

    return (
        <Dialog tabIndex={-1} onClose={doNothing} className={"edit-default-warehouse-dialog"}>
            <header>
                <hgroup>
                    {company.name}
                    <h3>Default Warehouse </h3>
                </hgroup>
            </header>
            <section>
                <ul>
                    {locations.map((l) => {
                        return (
                            <li key={l.guid}>
                                <label className={"radio-input"}>
                                    <input
                                        type={"radio"}
                                        checked={selected === l.guid}
                                        onChange={constant(setSelected, l.guid)}
                                    />
                                    <div className={"default-warehouse-info"}>
                                        <h6>
                                            {l?.description ?? (<span className={"italic"}>No description</span>)}
                                        </h6>
                                        <small>{l?.address?.fullAddress ?? "-"}</small>
                                    </div>
                                </label>
                            </li>
                        );
                    })}
                </ul>
            </section>
            <footer>
                <Button onClick={constant(navigate, -1, {replace: true})}>Cancel</Button>
                <Button disabled={currentDefaultWarehouse?.guid === selected} variant={"primary"} onClick={save}>
                    Save
                </Button>
            </footer>
        </Dialog>
    );
}