import * as React from "react";
import {Td} from "../../../../../../common/DataTable";
import {fuzzyDateFormat} from "../../../../../../../utils/datetime";

import "./styles.scss";

type CreatedAtCellViewProps = {
    date: Date;
};

export function CreatedAtCellView({date}: CreatedAtCellViewProps) {
    return (
        <Td ordinal={"last"} className={"created-at-cell-view"}>
            <span> {fuzzyDateFormat(date)} </span>
        </Td>
    )
}