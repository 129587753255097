import * as React from "react";
import {usePromise} from "../hooks/usePromise";
import {DataState, DatastoreContext} from "./context";
import {useMap} from "../hooks/useMap";


type UseDataProps<T, U> = {
    loader: () => (T | PromiseLike<T>),
    keys: string[],
    map?(data: T): U,
};


type UseDataType<T, E = unknown> = {
    state: "loading" | "successful" | "error",
    data: T,
    error: E
};

export function useData<T, U = T>(props: UseDataProps<T, U>): UseDataType<U> {

    const store = React.useContext(DatastoreContext);

    const [useDataState, setUseDataState] = React.useState<DataState<U>>({
        state: "loading",
        data: undefined,
        error: undefined,
    });

    const {state, data} = usePromise(async (): Promise<DataState<U>> => {
        if (store != null) {
            if (store.datastore.has(props.keys[0])) {
                return store.datastore.get(props.keys[0]) as DataState<U>;
            }

            store.datastore.set(props.keys[0], {
                state: "loading",
                data: undefined,
                error: undefined,
            });
        }

        const loadedData = (await props.loader());

        const newDataState: DataState<U> = {
            state: "successful",
            data: loadedData as U,
            error: undefined,
        }

        if (store != null) {
            store.datastore.set(props.keys[0], newDataState);
            store.stream.push([props.keys[0], newDataState]);
        }
        return {
            state: "successful",
            data: props.map ? props.map(loadedData) : loadedData as U,
            error: undefined,
        };
    }, []);


    React.useEffect(() => {
        return store != null ? store.stream.observe(([key, newDataState]) => {
            if (!props.keys.includes(key)) {
                return;
            }
            setUseDataState({
                ...newDataState,
                data: props.map ? props.map(newDataState.data as T) : newDataState.data
            } as DataState<U>);
        }) : undefined;
    }, []);


    React.useEffect(() => {
        if (state === "fulfilled") {
            setUseDataState(data);
        }
    }, [state]);

    return {
        ...useDataState,
    };
}

type UseDataStoreProps = {
    keys?: string[] | "*",
}

type UseDataStoreType<T> = {
    store: Map<string, T>;
    set(keys: string[], data: T): void;
}

export function useDatastore<T = unknown>(props?: UseDataStoreProps): UseDataStoreType<T> {

    const {keys} = props ?? {};
    const {datastore, stream} = React.useContext(DatastoreContext);

    const store = useMap<string, T>();

    React.useEffect(() => {
        return keys != null ?  stream.observe(([key, {state, data}]) => {
            if ((keys === "*" || keys.includes(key)) && state === "successful") {
                store.set(key, data as T);
            }
        }) : undefined;
    }, []);

    return {
        store: store.ref(),
        set(keys: string[], data: T) {
            const newDataState: DataState<unknown> = {state: "successful", data, error: undefined};
            keys.forEach((key) => {
                datastore.set(key, newDataState);
                stream.push([key, newDataState]);
            });
        }
    }
}
