import * as React from "react";
import {useIntersectionObserver} from "./hooks";

type InteractionObserverTargetProps = React.HTMLProps<HTMLElement> & {
    asHtml: keyof JSX.IntrinsicElements
    onIntersection: IntersectionObserverCallback;
};

export function IntersectionObserverTarget({ asHtml, onIntersection, ...props}: InteractionObserverTargetProps) {
    //const ref = useIntersectionObserver(onIntersection);


    return React.createElement(
        asHtml,
        {
           // ref,
            ...props,
        }
    );
}