import {ThemeProvider} from "@danfoss/etui-system";
import {defaultTheme} from "@danfoss/etui-themes";
import * as React from "react";
import {useNavigate} from "react-router-dom";
import {ComponentModel} from "../../../models/component/componentModel";
import {constant} from "../../../utils/functions";
import {Button} from "../../common/Button";
import {Icon} from "../../common/Icon";
import {Skeleton} from "../../common/Skeleton";
import {TablePagination} from "./components/TablePagination";
import {ComponentModelsDataTableView, useComponentModelChangesManager,} from "./TableView";
import {useComponentModels} from "./hooks";
import {DatastoreProvider} from "../../../DataLoader";
import {deleteComponentModel} from "./api";

import "./styles.scss";


export function ManageComponentModels() {

    const navigate = useNavigate();

    const { state, dispatch, jumpToDifferentPage, filterColumnProps, clear } = useComponentModels();

    const { changes, register, save, discard, selected } = useComponentModelChangesManager({
        onUpdate(updatedComponentModels: ComponentModel[]) {
            dispatch({type: "merge_component_models_list", updatedComponentModels });
        },
    });

    async function deleteComponentModels() {
        const deleteRequest = selected.toArray()
            .map((guid) => {
                return deleteComponentModel({guid});
            });
        try {
            await Promise.all(deleteRequest);
            selected.clear();
        } finally {
            clear();
            jumpToDifferentPage(1);
        }
    }

    React.useEffect(() => {
        jumpToDifferentPage(1);
    }, []);

    const isAnyFilterSet = state.filter.names.selected.length > 0
        || state.filter.companiesGuids.selected.length > 0
        || state.filter.componentTypesGuids.selected.length > 0
        || state.filter.componentCategoriesGuids.selected.length > 0;

    return (
        <ThemeProvider theme={defaultTheme}>
            <main className="component-models">
                <header>
                    <hgroup>
                        <h2>{state.loading ? <Skeleton type={"text"} style={{width: "75px"}}/> : state.total}</h2>
                        <p>
                            {state.loading
                                ? <Skeleton type={"text"} style={{width: "150px"}}/>
                                : state.total === 1 ?  "Component model" : "Component models"
                            }
                        </p>
                    </hgroup>
                    <div className={"page-actions"}>
                        {isAnyFilterSet
                            ? <Button variant={"secondary"} onClick={clear}>Clear filters</Button>
                            : null
                        }
                        {!selected.isEmpty() || !changes.isEmpty()
                            ? <Button variant={"secondary"} onClick={discard}>Cancel</Button>
                            : null
                        }
                        {!selected.isEmpty()
                            ? <Button variant={"primary-negative"} onClick={deleteComponentModels}>Delete</Button>
                            : null
                        }
                        {!changes.isEmpty()
                            ? <Button variant={"primary-positive"} onClick={save}>Save</Button>
                            : null
                        }
                        {selected.isEmpty() && changes.isEmpty()
                             ? (
                                 <Button
                                     variant={"primary"}
                                     onClick={constant(navigate, "/createComponentModel")}

                                 >
                                     <Icon name={"add"}/> Create
                                 </Button>
                            ) : null
                        }
                    </div>
                </header>
                <section>
                    <DatastoreProvider>
                        <ComponentModelsDataTableView
                            loading={state.loading}
                            componentModels={state.componentModels}
                            filterColumnProps={filterColumnProps}
                            rowRegister={register}
                        />
                    </DatastoreProvider>
                </section>
                <footer>
                    <TablePagination
                        currentPage={Math.floor(state.offset /  state.limit) + 1}
                        totalPages={Math.ceil(state.total / state.limit)}
                        pagesShown={21}
                        onClick={jumpToDifferentPage}
                    />
                </footer>
            </main>
        </ThemeProvider>
    );
}

export {ComponentModelsFilter} from "./types";
export {findComponentModels} from "./api";
