import {ComponentTypesFilter} from "./types";
import {JSONEntitiesCollection, JSONPage} from "../../../models/utils/jsonList";
import {ComponentType} from "../../../models/component/componentType";
import {assertOk, toRequestParamsString} from "../../../utils/requests";
import {Pageable} from "../../../models/types";

type FindComponentTypeProps = Pageable<{ criteria: ComponentTypesFilter }>;

export async function findComponentTypes(props?: FindComponentTypeProps): Promise<JSONPage<ComponentType>> {
    const {criteria, ...pagination} = props ?? {};
    const response = await fetch(`/componentTypes?${toRequestParamsString({...criteria, ...pagination})}`);
    assertOk(response);
    return response.json();
}