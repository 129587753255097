import * as React from "react";
import {UseFormType} from "../types";
import {FormContextType} from "./context";


type FormContextProps = React.PropsWithChildren<UseFormType<any>>;

export function FormContext({children, ...methods}: FormContextProps) {
    return (
        <FormContextType.Provider value={methods}>
            {children}
        </FormContextType.Provider>
    );
}
