import * as React from "react";

export function Field({children}: React.HTMLProps<HTMLElement>) {

    return <>{children}</>;
}


/*

<Select>
    <Field element={<CustomFieldBehaviour>}/>
    <Menu>
        <Option/>
        <Option/>
        <Option/>
        <Option/>
        <Option/>
        <Option/>
    </Menu>
</Select>

 */