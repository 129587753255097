import * as React from "react";
import {Td, Tr} from "../DataTable";

import "./styles.scss";
import {range} from "../../../utils/numbers";

type SkeletonProps = React.HTMLAttributes<HTMLElement> & {
    type: "text" | "table-row" | "list" | "list-element";
    size?: number;
    animationDelay?: number;
};

export function Skeleton({ type, size, animationDelay, ...props}: SkeletonProps) {
    switch (type) {
        case "text": {
            return (
                <span className={"text-skeleton skeleton-bg"} {...props} />
            );
        }
        case "table-row": {
            return (
                <Tr
                    className={"table-row-skeleton"}
                    style={{
                        "--skeleton-bg-animation-delay" : `${animationDelay}s`,
                        "--skeleton-bg-animation-duration": "4s",
                    } as any}
                >
                    {range(size).map((i) => (
                        <Td key={i}>
                            <Skeleton type={"text"}/>
                        </Td>
                    ))}
                </Tr>
            );
        }
        case "list-element": {
            return (
                <li {...props}>
                    <Skeleton type={"text"}/>
                </li>
            );
        }
        case "list": {
            return (
                <ul>
                    {range(size).map((i) => (
                        <Skeleton  key={i} type={"list-element"}/>
                    ))}
                </ul>
            );
        }
    }
    return null;
}
