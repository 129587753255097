import * as React from "react";
import {ComponentModel} from "../../../../../models/component/componentModel";

const ComponentModelProviderContext = React.createContext<ComponentModel>(undefined);

export function useComponentModel() {
    return React.useContext(ComponentModelProviderContext);
}

interface ComponentModelProviderProps {
    componentModel: ComponentModel;
}

export function ComponentModelProvider(
    {
        children,
        componentModel,
    }: React.PropsWithChildren<ComponentModelProviderProps>,
) {
    return (
        <ComponentModelProviderContext.Provider value={componentModel}>
            {children}
        </ComponentModelProviderContext.Provider>
    );
}
