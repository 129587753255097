import * as React from "react";
import {usePageNavigation} from "./hooks";
import {SearchParamsPagination} from "./SearchParamsPagination";
import {useForm, FormContext} from "../../../Form";
import {Button} from "../../common/Button";
import {updateCompany} from "../api";
import {CompaniesTableView} from "./TableView";
import {isEmptyObject, omit} from "../../../utils/objects";
import {FilterBar} from "./TableView/FilterBar";

import "./styles.scss";


export function CompaniesPageView() {
    const {data, state} = usePageNavigation();

    const initialValue = React.useMemo(() => {
        return state === "fulfilled"
            ? Object.fromEntries(data.list.map((c) => [c.guid, {
                name: c.name,
                defaultWarehouse: c.defaultWarehouse?.guid,
                pingDevices: c.pingDevices,
                lockEnabled: c.lockEnabled,
                integrationFlowEnabled: c.integrationFlowEnabled,
                marketingConfigurationEnabled: c.marketingConfigurationEnabled,
                genericProductEnabled: c.genericProductEnabled,
            }]))
            : {};
    }, [data]);


    const methods = useForm({
        values: initialValue,
        formId: "companies-table-form-id"
    });

    const {changedFields, hasChanged} = methods.state();

    async function saveCompanyEdit(submittedFormData: Record<string, any>) {
        const requests = Object.entries(submittedFormData)
            .map(([guid, o]) => {
                return [guid, omit(o, (k) => !changedFields.includes(`${guid}/${String(k)}`))]
            })
            .filter(([, o]) => !isEmptyObject(o))
            .map(([guid, changes]) => updateCompany({guid}, changes));

        const result = await Promise.all( requests);
    }

    function reset() {
        methods.reset();
    }

    const {stickyRef, onContainerScroll} = usePositionSticky({});

    return (
        <main className={"companies"}>
            <form id={methods.formId} onSubmit={methods.handleSubmit(saveCompanyEdit)}/>
            <header>
                <h3>Companies</h3>
                {/* Selected filter view */}
                <div className={"actions"}>
                    {hasChanged && (
                        <>
                            <Button onClick={reset} variant={"secondary"}>Discard</Button>
                            <Button form={methods.formId} variant={"primary"}>Save</Button>
                        </>

                    )}
                </div>
            </header>
            <FilterBar className={"filter-bar"}/>
            <section onScroll={onContainerScroll}>
                <FormContext {...methods}>
                    <CompaniesTableView companies={data?.list ?? []} loading={state === "pending"}/>
                </FormContext>
                <footer ref={stickyRef}>
                    {state === "fulfilled" &&(
                        <div>
                            <span className={"result-count"}>{data.total} {data.total === 1 ? "row" : "rows"}</span>
                            <SearchParamsPagination totalNumberOfRows={data?.total ?? 0} width={9}/>
                        </div>
                    )}
                </footer>
            </section>
        </main>
   );
}


type UsePositionStickyProps = {};

function usePositionSticky({}: UsePositionStickyProps) {
    const stickyRef = React.useRef<HTMLElement>();

    function onContainerScroll(e: React.SyntheticEvent<HTMLElement>) {
        const isAtBottom = e.currentTarget.scrollHeight - e.currentTarget.clientHeight - e.currentTarget.scrollTop < 1;
        stickyRef.current.dataset.stuck = !isAtBottom ? "true" : "false";
    }

    return {stickyRef, onContainerScroll};
}