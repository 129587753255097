import * as React from "react";
import {createSlots} from "../../../../utils/components";
import {Thumbnail} from "../../Thumbnail";
import {Input} from "../../form/Input";

import "./styles.scss";

type ChipProps = React.HTMLAttributes<HTMLElement>;

export function isChip(element: unknown): element is React.ReactElement<ChipProps> {
    return React.isValidElement<ChipProps>(element) && element.type === Chip;
}

export function Chip({children, ...props}: ChipProps) {

    const slots = createSlots(children, {
        thumbnail: Thumbnail,
        input: Input,
        rest: "*",
    });

    if (slots.input != null) {
        return (
            <label {...props} className={`chip ${props.className ?? ""}`}>
                {slots.thumbnail != null && (
                    <span className={`chip-thumbnail ${slots.thumbnail.props.className ?? ""}`}>
                        {slots.thumbnail.props.children}
                    </span>
                )}
                {slots.thumbnail != null ? <span className={"chip-content"}>{slots.rest}</span> : children}
            </label>
        );
    }


    return (
        <span {...props} className={`chip ${props.className ?? ""}`}>
            {slots.thumbnail != null && (
                <span className={`chip-thumbnail ${slots.thumbnail.props.className ?? ""}`}>
                    {slots.thumbnail.props.children}
                </span>
            )}
            {slots.thumbnail != null ? <span className={"chip-content"}>{slots.rest}</span> : children}
        </span>
    );
}
