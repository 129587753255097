import * as React from "react";
import {SetURLSearchParams, useSearchParams} from "react-router-dom";


type QueryParamsContextType = {
    params: object;
    urlSearchParams: URLSearchParams;
    setUrlSearchParams: SetURLSearchParams;
};


export const QueryParamsContext = React.createContext<QueryParamsContextType>(null);

export function QueryParamsProvider({children}: React.PropsWithChildren<unknown>) {

    const [urlSearchParams, setUrlSearchParams] = useSearchParams();

    const params: object = React.useMemo(() => {
        return Array.from(urlSearchParams).reduce((obj, [key, value]) => {
            if (key in obj && obj[key] instanceof Array) {
                return {
                    ...obj,
                    [key]: [...obj[key], value],
                }
            } else if (key in obj) {
                return {
                    ...obj,
                    [key]: [obj[key], value],
                }
            } else {
                return {
                    ...obj,
                    [key]: value,
                }
            }
        }, {});
    }, [urlSearchParams]);


    return (
        <QueryParamsContext.Provider value={{params, urlSearchParams, setUrlSearchParams}}>
            {children}
        </QueryParamsContext.Provider>
    );
}