export const MILLISECONDS_IN_A_SECOND = 1000;
export const MILLISECONDS_IN_A_MINUTES = MILLISECONDS_IN_A_SECOND * 60;
export const MILLISECONDS_IN_A_HOUR = MILLISECONDS_IN_A_MINUTES * 60;
export const MILLISECONDS_IN_A_DAY = MILLISECONDS_IN_A_HOUR * 24;
export const MILLISECONDS_IN_A_WEEK = MILLISECONDS_IN_A_DAY * 7;
export const MILLISECONDS_IN_A_MONTH = Math.floor(MILLISECONDS_IN_A_DAY * 30.4);
export const MILLISECONDS_IN_A_YEAR = MILLISECONDS_IN_A_DAY * 365;

export function format(x: Date): string {
    return x.toDateString();
}

export function fuzzyDateString(difference, ref, scale) {
    const fuzzy = Math.floor(difference / ref);
    return `${fuzzy} ${scale}${fuzzy !== 1 ? "s" : ""} ago`;
}

export function fuzzyDateFormat(date: Date) {
    const now = Date.now();
    const dif = now - date.getTime();

    if (dif >= MILLISECONDS_IN_A_YEAR) {
        return fuzzyDateString(dif, MILLISECONDS_IN_A_YEAR, "year");
    } else if (dif >= MILLISECONDS_IN_A_MONTH) {
        return fuzzyDateString(dif, MILLISECONDS_IN_A_MONTH, "month");
    } else if (dif >= MILLISECONDS_IN_A_WEEK) {
        return fuzzyDateString(dif, MILLISECONDS_IN_A_WEEK, "week");
    } else if (dif >= MILLISECONDS_IN_A_DAY) {
        return fuzzyDateString(dif, MILLISECONDS_IN_A_DAY, "day");
    } else if (dif >= MILLISECONDS_IN_A_HOUR) {
        return fuzzyDateString(dif, MILLISECONDS_IN_A_HOUR, "hour");
    } else if (dif >= MILLISECONDS_IN_A_MINUTES)
        return fuzzyDateString(dif, MILLISECONDS_IN_A_MINUTES, "minute");
    else if (dif >= MILLISECONDS_IN_A_SECOND) {
        return fuzzyDateString(dif, MILLISECONDS_IN_A_SECOND, "second");
    } else {
        return "now";
    }
}
