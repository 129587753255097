export function toRequestParamsString<T extends {}>(params: T): string {
    if (params == null) {
        return "";
    }
    const searchParams = new URLSearchParams();
    Object.entries(params).forEach(([k, v]) => addToSearchParams(searchParams, k, v));
    return searchParams.toString();
}


function addToSearchParams(urlSearchParams: URLSearchParams, key: string, value: unknown) {
    if (value == null) {
        return;
    } if (value instanceof Array) {
        value.forEach((element) => addToSearchParams(urlSearchParams, key, element));
    } else if (value instanceof Object) {
        Object.entries(value).forEach(([k, v]) => addToSearchParams(urlSearchParams, `${key}.${k}`, v));
    } else {
        urlSearchParams.append(key, String(value));
    }
}

export function assertOk(response: Response): void {
    if (response.ok) {
        return;
    }
    throw response;
}
