import * as React from "react";
import { OptionControllerContext } from "./context";
import {UseOptionControllerType} from "./types";

export function OptionControllerProvider({children, ...controls}: React.PropsWithChildren<UseOptionControllerType>) {
    return (
        <OptionControllerContext.Provider value={controls}>
            {children}
        </OptionControllerContext.Provider>
    );
}